import ColorexColor from './colors/ColorexColor';
import HTMLGenerator from '../../components/Sketch/utils/HTMLGenerator';

class ColorexColorCircle {
	private readonly CSS_ELEMENT_CLASS_NAME = 'colorex-color-box';
	private readonly CSS_CIRCLE_ELEMENT_CLASS_NAME = 'colorex-color-circle';
	private readonly CSS_CIRCLE_STRIKETHROUGH_CLASS_NAME = 'colorex-circle-strikethrough';
	private readonly boxElement: HTMLElement;
	private readonly circleElement: HTMLElement;
	private readonly strikethrough: HTMLElement;

	private color: ColorexColor;

	constructor() {
		this.boxElement = document.createElement('div');
		this.boxElement.className = this.CSS_ELEMENT_CLASS_NAME;
		this.circleElement = document.createElement('div');
		this.circleElement.className = this.CSS_CIRCLE_ELEMENT_CLASS_NAME;
		this.strikethrough = HTMLGenerator.getDiv();
		this.strikethrough.className = this.CSS_CIRCLE_STRIKETHROUGH_CLASS_NAME;
		this.boxElement.append(this.circleElement);
	}

	public getCircleElement = (): HTMLElement => this.circleElement;

	public setColor = (color: ColorexColor) => {
		this.color = color;
		// this.circleElement.style.background = this.convertColorToHex(color);
		this.circleElement.style.background = color.toString();
		if (ColorexColor.Transparent === color) {
			this.showStrikethrough();
		} else {
			this.hideStrikethrough();
		}
	};

	public addClickEvent = (ev: (color: ColorexColor) => void) => {
		this.boxElement.addEventListener('click', ev.bind(this, this.color));
	};

	public remove = () => {
		this.boxElement.remove();
	};

	public showStrikethrough = () => {
		this.circleElement.append(this.strikethrough);
	};

	public hideStrikethrough = () => {
		this.strikethrough.remove();
	};

	public getColor = () => this.color;
	public getElement = (): HTMLElement => this.boxElement;

	/**
	 * Преобразует цвет из числа в шестнадцатеричной системе счисления в HEX,
	 * для использования в CSS
	 * @param color - число в шестнадцатеричной системе счисления
	 */
	private convertColorToHex = (color: number): string => `#${color.toString(16).padStart(6, '0')}`;
}

export default ColorexColorCircle;
