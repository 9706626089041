import IBaseUseCases from './IBaseUseCases';
import { notificationError } from '../../../Notifications/callNotifcation';
import SketchComponentType from '../../components/SketchComponentType';
import TextComponent from '../../components/text/TextComponent';
import TableComponent from '../../components/table/TableComponent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import Dependent from '../../utils/dependent/Dependent';
import IComponent from '../../components/IComponent';
import PasteCoordinator from '../../mechanics/PasteCoordinator';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import SketchStructureStabilizer from '../../mechanics/mutation-observer/SketchStructureStabilizer';
import SpatialAreaTree from '../../mechanics/spatial-quadrants/spatial-tree/SpatialAreaTree';
import IDescartesPosition from '../../utils/IDescartesPosition';
import { FontFamily, FontSize, TextAlign } from '../../mechanics/mext/editor/types';
import { TokenFormat } from '../../mechanics/mext/parser';
import IManipulatorInterface from '../../interface/IManipulatorInterface';
import IGraphic from '../../graphic/IGraphic';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';
import FigureComponent from '../../components/figure/FigureComponent';
import BorderStyle from '../../graphic/figure/BorderStyle';
import PictureComponent from '../../components/picture/PictureComponent';
import Editor from '../../mechanics/mext/editor';
import IComponentCloner from '../../mechanics/component-cloner/IComponentCloner';
import ActionStore from '../../mutations/ActionStore';
import IComponentGrouper from '../../mechanics/component-grouper/IComponentGrouper';
import ComponentInjector from '../../component-injector/ComponentInjector';
import LineHeight from '../../interface/bars/properties-bar/panel-text/line-height/LineHeight';
import IComponentAlignment from '../../mechanics/component-alignment/IComponentAlignment';
import TableGridMutator from '../../graphic/table/TableGridMutator';
import renderStep from '../../utils/RenderStepGenerator';
import FigureGraphic from '../../graphic/figure/FigureGraphic';
import ICornerRadius from '../../graphic/figure/ICornerRadius';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import GraphicType from '../../graphic/GraphicType';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import LineStyle from '../../graphic/line/LineStyle';
import LineGraphic from '../../graphic/line/LineGraphic';
import LineComponent from '../../components/line/LineComponent';
import { SearchPositionTableCell } from '../../components/table/SearchPositionTableCell';
import LineOrientationType from '../../components/LineOrientationType';

export interface IBaseUseCasesDependencies {
	actionStore: ActionStore,
	spatialTree: SpatialAreaTree,
	componentCloner: IComponentCloner,
	pasteCoordinator: PasteCoordinator,
	tableGridMutator: TableGridMutator,
	componentGrouper: IComponentGrouper,
	mouseObserver: MousePositionObserver,
	componentInjector: ComponentInjector,
	componentAlignment: IComponentAlignment,
	sketchStabilizer: SketchStructureStabilizer,
	manipulatorInterface: IManipulatorInterface,
	componentUIObserver: ComponentUIObserver,
	componentTree: IComponentTree & ILayeredComponentTree & IComponentTreeMutator,
}

/**
 * Предоставляет базовые пользовательские сценарии использования конструкторов.
 */
abstract class BaseUseCases<Dependencies extends IBaseUseCasesDependencies>
	extends Dependent<Dependencies>
	implements IBaseUseCases {
	private readonly renderStepGenerator: Generator;

	private readonly postApplyToGraphicListeners: ((component: IComponent) => void)[];

	constructor() {
		super();
		this.renderStepGenerator = renderStep();
		this.postApplyToGraphicListeners = [];
	}

	/** @inheritDoc */
	public copy = (): void => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			notificationError('Копирование компонентов', 'Отсутствуют компоненты в фокусе.');
			return;
		}

		const component = focusComponents[0];
		if (!component.isEnableEditMode()) {
			this.dependencies.componentCloner.copy(focusComponents);
		} else {
			this.copyTextContentFromComponent(component);
		}
	};

	/** @inheritDoc */
	public pasteFromKeyboardHotkey = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents !== null
			&& focusComponents !== undefined
			&& focusComponents[0].isEnableEditMode()) {
			this.dependencies.pasteCoordinator.pasteInComponents(focusComponents);
		} else {
			this.dependencies.pasteCoordinator.pasteFromBuffer();
		}
		setTimeout(this.dependencies.spatialTree.sync.bind(this), 100);
	};

	public removeFocusComponentsFromHotkey = () => {
		const isInterfaceActive = this.dependencies.manipulatorInterface.haveActiveElement();
		if (isInterfaceActive || this.dependencies.componentTree.hasEditableComponent()) {
			return;
		}

		this.removeFocusComponents();
	};

	public disableInjectingProcess = () => {
		const isInjecting = this.dependencies.componentInjector.isInjecting();
		if (isInjecting) {
			this.dependencies.componentInjector.stopInjecting();
		}
		this.dependencies.manipulatorInterface.disableInjectingElements();
	};

	public disableComponentsFocus = () => {
		const components = this.dependencies.componentTree.getComponents();
		if (components === null) {
			return;
		}

		components.forEach((component) => {
			component.disableEditMode();
			component.disableFocus();
		});

		this.dependencies.componentUIObserver.sync();
	};

	public stretchFocusComponentToWidth = () => {
		const focusComponent = this.dependencies.componentTree.getFocusComponents();
		if (focusComponent === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentAlignment.alignToWidth(...focusComponent);

			focusComponent.forEach(component => {
				if (component.type === SketchComponentType.TEXT) {
					this.syncTextComponentsFrameSize();
				}
				if (component.type === SketchComponentType.PICTURE) {
					this.syncPictureComponentsFrameSize();
				}
			});
		});

		this.dependencies.manipulatorInterface.sync();
		this.dependencies.spatialTree.sync();
	};

	/** @inheritDoc */
	public cloneWithAlt = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.componentCloner.pasteWithAlt(focusComponents);
		this.dependencies.sketchStabilizer.stopUserAction();
		this.dependencies.spatialTree.sync();
	};

	/** @inheritDoc */
	public pasteAtPosition = (position: IDescartesPosition) => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.pasteCoordinator.pasteAtPosition(focusComponents, position);
		this.dependencies.sketchStabilizer.stopUserAction();
		setTimeout(this.dependencies.spatialTree.sync.bind(this), 100);
	};

	/** @inheritDoc */
	public removeFocusComponents = () => {
		const isHaveActiveElement = this.dependencies.manipulatorInterface.haveActiveElement();
		if (isHaveActiveElement) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.mutateByRemoveFocusComponents();
		});

		this.dependencies.spatialTree.sync();
		this.dependencies.componentUIObserver.sync();
		this.dependencies.manipulatorInterface.sync();
	};

	/** @inheritDoc */
	public undo = () => {
		this.dependencies.actionStore.undo();
		this.dependencies.spatialTree.sync();
		this.dependencies.manipulatorInterface.sync();
		this.dependencies.componentUIObserver.sync();
	};

	/** @inheritDoc */
	public redo = () => {
		this.dependencies.actionStore.redo();
		this.dependencies.spatialTree.sync();
		this.dependencies.manipulatorInterface.sync();
		this.dependencies.componentUIObserver.sync();
	};

	/**
	 * Перемещает все компоненты в фокусе на слой ниже.
	 */
	public moveBackFocusComponents = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveBackComponents(...focusComponents);
		});

		this.dependencies.spatialTree.sync();
	};

	public moveForwardFocusComponents = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveForwardComponents(...focusComponents);
		});

		this.dependencies.spatialTree.sync();
	};

	public moveToForegroundFocusComponents = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveComponentToForeground(...focusComponents);
		});

		this.dependencies.spatialTree.sync();
	};

	public moveToBackgroundFocusComponents = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveComponentToBackground(...focusComponents);
		});

		this.dependencies.spatialTree.sync();
	};

	public moveBackGraphics = (...graphics: IGraphic[]) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveBackGraphics(...graphics);
		});
		this.dependencies.spatialTree.sync();
	};

	public moveForwardGraphics = (...graphics: IGraphic[]) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveForwardGraphics(...graphics);
		});
		this.dependencies.spatialTree.sync();
	};

	public moveToForegroundGraphics = (...graphics: IGraphic[]) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveGraphicToForeground(...graphics);
		});
		this.dependencies.spatialTree.sync();
	};

	public moveToBackgroundGraphics = (...graphics: IGraphic[]) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.moveGraphicToBackground(...graphics);
		});
		this.dependencies.spatialTree.sync();
	};

	public groupFocusComponents = () => {
		let focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		focusComponents = focusComponents.filter(
			component => component.type !== SketchComponentType.PAGES_CONTAINER,
		);
		if (focusComponents.length === 0) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			const groupComponent = this.dependencies.componentGrouper.groupComponents(focusComponents!);
			const rootComponent = this.dependencies.componentTree.getRootComponent();

			rootComponent.recursiveSyncGraphicsOffset();
			this.dependencies.componentTree.enableFocusOnly(groupComponent);
		});

		this.dependencies.spatialTree.sync();
		this.dependencies.componentUIObserver.sync();
	};

	public ungroupFocusComponents = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentGrouper.ungroupComponents(focusComponents);
		});

		this.dependencies.spatialTree.sync();
		this.dependencies.componentUIObserver.sync();
	};

	public createEmptyTable = (rowCount: number, columnCount: number) => {
		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.componentInjector.injectEmptyTable(rowCount, columnCount);
		this.dependencies.componentInjector
			.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
	};

	public setFocusFigureBackground = (color: string) => {
		this.applyForFocusFigureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			background: color,
		})));
	};

	public setFocusFigureBorderColor = (color: string) => {
		this.applyForFocusFigureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			borderColor: color,
		})));
	};

	public setFocusFigureBorderWidth = (width: number) => {
		this.applyForFocusFigureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			borderWidth: width,
		})));
	};

	public setFocusObjectCornerRadius = (cornerRadius: number) => {
		this.applyForFocusFigureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			radius: {
				topLeft: cornerRadius,
				topRight: cornerRadius,
				bottomLeft: cornerRadius,
				bottomRight: cornerRadius,
			},
		})));

		this.applyForFocusPictureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			radius: {
				topLeft: cornerRadius,
				topRight: cornerRadius,
				bottomLeft: cornerRadius,
				bottomRight: cornerRadius,
			},
		})));
	};

	public setFocusFigureBorderStyle = (style: BorderStyle) => {
		this.applyForFocusFigureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			borderStyle: style,
		})));
	};

	public setFocusFigureBorderRadius = (radius: ICornerRadius) => {
		this.applyForFocusFigureGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			radius,
		})));
	};

	public setFocusLineStyle = (style: LineStyle) => {
		this.applyForFocusLineGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			style,
		})));
	};

	public setFocusLineBackground = (color: string) => {
		this.applyForFocusLineGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			background: color,
		})));
	};

	public setFocusLineWidth = (thickness: number) => {
		this.applyForFocusLineGraphic(graphic => graphic.setTexture(prev => ({
			...prev,
			thickness,
		})));
		this.applyForFocusLineGraphic(graphic => {
			if (graphic.getTexture().orientation === LineOrientationType.HORIZONTAL) {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					height: thickness,
				}));
			} else {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					width: thickness,
				}));
			}
		});
		this.dependencies.componentUIObserver.sync();
	};

	public setFocusPictureBorderRadius = (radius: ICornerRadius) => {
		const components = this.dependencies
			.componentTree.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (components === null) {
			return;
		}
		const graphics: PictureGraphic[] = components.map(component => component.getGraphics()).flat();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			graphics.forEach(picture => {
				picture.setTexture(prev => ({
					...prev,
					radius,
				}));
			});
		});
	};

	public resetFocusPictureSize = () => {
		const pictures = this.dependencies
			.componentTree.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (pictures === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			pictures.forEach(picture => {
				picture.resetPictureSize();
			});
		});
	};

	public applyEditablePictureChange = () => {
		const pictureComponents = this.dependencies.componentTree
			.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (pictureComponents === null) {
			return;
		}

		pictureComponents.forEach(component => {
			component.disableEditMode();
			component.disableFocus();
		});

		this.dependencies.manipulatorInterface.sync();
	};

	public cancelEditablePictureChange = () => {
		const pictures = this.dependencies
			.componentTree.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (pictures === null) {
			return;
		}

		pictures.forEach(picture => {
			picture.cancelChanges();
			picture.disableEditMode();
			picture.disableFocus();
		});

		this.dependencies.spatialTree.sync();
		this.dependencies.manipulatorInterface.sync();
	};

	public loadPictureToFocusComponentWithAdaptFrame = () => {
		const pictureComponents = this.dependencies
			.componentTree.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (pictureComponents === null) {
			return;
		}

		pictureComponents.forEach(component => {
			component.enableAdaptPictureAfterSetup();
			component.runLoadPicture();
		});
	};

	public loadPictureComponentWithAdaptFrame = (...graphic: PictureGraphic[]): void => {
		let isHoverComponent = false;

		graphic.forEach(graphic => {
			if (graphic.type === GraphicType.PICTURE) {
				if (graphic.isEnableHover() && !graphic.isEnableFocus()) {
					graphic.runLoadPicture();
					isHoverComponent = true;
				}
			}
		});

		if (!isHoverComponent) {
			this.loadPictureToFocusComponentWithAdaptFrame();
		}
	};

	public setFocusTextColor = (color: string) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.applyToFocusGraphics((graphic, editor) => {
				if (graphic.type === GraphicType.TABLE) {
					editor.setColorWithoutPostEvents(color);
				} else {
					editor.setColor(color);
				}
			});
		});
	};

	public setFocusTextLineHeight = (height: LineHeight) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.applyToFocusGraphics((graphic, editor) => {
				if (graphic.type === GraphicType.TABLE) {
					editor.setLineHeightWithoutPostEvents(height);
				} else {
					editor.setLineHeight(height);
				}
			});
		});
	};

	/**
	 * Копирует в буфер обмена текст из переданного компонента.
	 * @param component Источник текста.
	 */
	public copyTextContentFromComponent = (component: IComponent) => {
		if (component.type === SketchComponentType.TEXT) {
			const editors = (component as TextComponent).getEditors();
			if (editors.length > 1 || !editors[0].isActiveEditable()) {
				notificationError('Копирование текста', 'Компонент в фокусе не в режиме редактирования');
			}
			editors[0].copy();
			return;
		}
		if (component.type === SketchComponentType.TABLE) {
			const focusCells = (component as TableComponent).getFocusCellContexts();
			if (focusCells === null) {
				return;
			}
			const editors = focusCells.map(cell => cell.getEditor());
			const editableEditor = editors.find(editor => editor.isActiveEditable());
			if (editableEditor === undefined) {
				notificationError('Копирование текста', 'Нет ячейки в режиме редактирования');
				throw new ManipulatorError('not have editable cell');
			}
			editableEditor.copy();
		}
	};

	public runInjectText = (): boolean => {
		if (this.dependencies.componentTree.hasEditableComponent()) {
			return false;
		}
		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.componentInjector.runInjectText();
		this.dependencies.componentInjector
			.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
		return true;
	};

	public runInjectPicture = () => {
		if (this.dependencies.componentTree.hasEditableComponent()) {
			return;
		}
		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.componentInjector.runInjectPicture();
		this.dependencies.componentInjector
			.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
	};

	public runInjectFigure = () => {
		if (this.dependencies.componentTree.hasEditableComponent()) {
			return;
		}
		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.componentInjector.runInjectFigure();
		this.dependencies.componentInjector
			.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
	};

	public runInjectLine = () => {
		if (this.dependencies.componentTree.hasEditableComponent()) {
			return;
		}
		this.dependencies.sketchStabilizer.startUserAction();
		this.dependencies.componentInjector.runInjectLine();
		this.dependencies.componentInjector
			.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
	};

	public setFocusComponentsBorderRadius = (radiusValues: ICornerRadius): void => {
		this.setFocusFigureBorderRadius(radiusValues);
		this.setFocusPictureBorderRadius(radiusValues);
	};
	
	public moveFocusTableCellUp = (): void => {
		const focusTable = this.dependencies
			.componentTree.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);

		if (focusTable === null) {
			return;
		}

		focusTable.forEach((table) => {
			if (table.getFocusCellContexts() !== null) {
				table.moveFocusTableCell(SearchPositionTableCell.CELL_ABOVE);
			}
		});
	};

	public moveFocusTableCellDown = (): void => {
		const focusTable = this.dependencies
			.componentTree.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);

		if (focusTable === null) {
			return;
		}

		focusTable.forEach((table) => {
			if (table.getFocusCellContexts() !== null) {
				table.moveFocusTableCell(SearchPositionTableCell.CELL_BELOW);
			}
		});
	};

	public moveFocusTableCellLeft = (): void => {
		const focusTable = this.dependencies
			.componentTree.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);

		if (focusTable === null) {
			return;
		}

		focusTable.forEach((table) => {
			if (table.getFocusCellContexts() !== null) {
				table.moveFocusTableCell(SearchPositionTableCell.CELL_LEFT);
			}
		});
	};

	public moveFocusTableCellRight = (): void => {
		const focusTable = this.dependencies
			.componentTree.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);

		if (focusTable === null) {
			return;
		}

		focusTable.forEach((table) => {
			if (table.getFocusCellContexts() !== null) {
				table.moveFocusTableCell(SearchPositionTableCell.CELL_RIGHT);
			}
		});
	};

	public setFocusTextAlign = (align: TextAlign) => {
		if (this.dependencies.actionStore === null || this.dependencies.manipulatorInterface === null) {
			throw new ManipulatorError('Alignment cannot be set');
		}

		/* В таблице при фокусе на нескольких ячейках - текст должен форматироваться сразу во всех этих ячейках, а
		логику с построчным выравниванием оставляем для текстового компонента.
		Для этого надо понять, что у нас в фокусе */
		const focusComponents = this.dependencies.componentTree.getFocusComponents();

		if (focusComponents === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			/* В таблице может быть редактор в режиме редактирования и при этом еще несколько ячеек (читай редакторов) в
		фокусе, поэтому отделяем в отдельное условие одиночные компоненты в фокусе, не являющиеся таблицей */
			if (focusComponents.length === 1 && focusComponents[0].type !== SketchComponentType.TABLE) {
				const editableEditor = this.getEditableTextEditor();
				if (editableEditor) {
					const tokens = editableEditor.getTokensCurrentFocusRow();
					if (tokens) editableEditor.setAlignAtEditableEditor(align, tokens);
				} else {
					this.applyToFocusGraphics((graphic, editor) => {
						if (graphic.type === GraphicType.TABLE) {
							editor.setAlignWithoutPostEvents(align);
						} else {
							editor.setAlign(align);
						}
					});
				}
			} else {
				this.applyToFocusGraphics((graphic, editor) => {
					if (graphic.type === GraphicType.TABLE) {
						editor.setAlignWithoutPostEvents(align);
					} else {
						editor.setAlign(align);
					}
				});
			}
		});

		this.dependencies.manipulatorInterface.sync();
	};

	public setFocusTextFontFamily = (fontFamily: FontFamily) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.applyToFocusGraphics((graphic, editor) => {
				if (graphic.type === GraphicType.TABLE) {
					editor.setFontFamilyWithoutPostEvents(fontFamily);
				} else {
					editor.setFontFamily(fontFamily);
				}
			});
		});
	};

	public changeFocusTextBold() {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.applyToFocusGraphics((graphic, editor) => {
				if (graphic.type === GraphicType.TABLE) {
					editor.toggleFormatWithoutPostEvents(TokenFormat.Bold);
				} else {
					editor.toggleFormat(TokenFormat.Bold);
				}
			});
		});
		this.dependencies.manipulatorInterface.sync();
	}

	public changeFocusTextItalic() {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.applyToFocusGraphics((graphic, editor) => {
				if (graphic.type === GraphicType.TABLE) {
					editor.toggleFormatWithoutPostEvents(TokenFormat.Italic);
				} else {
					editor.toggleFormat(TokenFormat.Italic);
				}
			});
		});

		this.dependencies.manipulatorInterface.sync();
	}

	public setFocusTextFontSize = (fontSize: FontSize) => {
		this.dependencies.sketchStabilizer.startUserAction();
		this.applyToFocusGraphics((graphic, editor) => {
			if (graphic.type === GraphicType.TABLE) {
				editor.setFontSizeWithoutPostEvents(fontSize);
			} else {
				editor.setFontSize(fontSize);
			}
		});
		this.syncTextComponentsFrameSize();
		setTimeout(this.dependencies.sketchStabilizer.stopUserAction.bind(this), 0);
	};

	public setFocusTableCellFill = (color: string) => {
		const tables = this.dependencies
			.componentTree.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			tables.forEach(table => {
				const focusCellContexts = table.getFocusCellContexts();
				if (focusCellContexts === null) {
					const cellContexts = table.getCellContexts();
					cellContexts.forEach(context => {
						context.setBackground(color);
					});
					return;
				}

				focusCellContexts.forEach(context => {
					context.setBackground(color);
				});
			});
		});
	};

	public setFocusTableBorderColor = (color: string) => {
		const tables = this.dependencies
			.componentTree.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables === null) {
			return;
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			tables.forEach(table => {
				table.mutateBorderColor(color);
				table.applyMutations();
			});
		});
	};

	public addColumnAfter = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.addColumnAfter(component);
		});
	};

	public addColumnBefore = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.addColumnBefore(component);
		});
	};

	public addRowUnder = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.addRowUnder(component);
		});
	};

	public addRowOver = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.addRowOver(component);
		});
	};

	public deleteFocusRows = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.deleteFocusRows(component);
		});
		this.dependencies.componentUIObserver.sync();
	};

	public deleteFocusColumns = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.deleteFocusColumns(component);
		});
	};

	public splitFocusCells = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.splitFocusCells(component);
		});
	};

	public mergeFocusCell = (component: TableComponent) => {
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.tableGridMutator.mergeFocusCell(component);
		});
	};

	public getColorsBorderFigureFocusComponents = (): string[] => {
		const figureComponents = this.dependencies.componentTree
			.getUniformFocusComponents<FigureComponent>(SketchComponentType.FIGURE);

		const resultColors: string[] = [];

		if (figureComponents !== null) {
			figureComponents.forEach(figureComponent => {
				const graphic = figureComponent.getFirstGraphic();
				if (graphic !== null) {
					const figureTexture = graphic.getTexture();
					resultColors.push(figureTexture.borderColor);
				}
			});
		}

		return resultColors;
	};

	public getColorsBackgroundFigureFocusComponents = (): string[] => {
		const figureComponents = this.dependencies.componentTree
			.getUniformFocusComponents<FigureComponent>(SketchComponentType.FIGURE);

		const resultColors: string[] = [];

		if (figureComponents !== null) {
			figureComponents.forEach(figureComponent => {
				const graphic = figureComponent.getFirstGraphic();
				if (graphic !== null) {
					const figureTexture = graphic.getTexture();
					resultColors.push(figureTexture.background);
				}
			});
		}

		return resultColors;
	};

	public getColorsLineFocusComponents = (): string[] => {
		const lineComponents = this.dependencies.componentTree
			.getUniformFocusComponents<LineComponent>(SketchComponentType.LINE);

		const resultColors: string[] = [];

		if (lineComponents !== null) {
			lineComponents.forEach(figureComponent => {
				const graphic = figureComponent.getFirstGraphic();
				if (graphic !== null) {
					const texture = graphic.getTexture();
					resultColors.push(texture.background);
				}
			});
		}

		return resultColors;
	};

	public getColorsFillTableFocusComponents = (): string[] => {
		const components = this.dependencies.componentTree
			.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);

		const resultColors: string[] = [];

		if (components !== null) {
			components.forEach(component => {
				resultColors.push(...component.getColorsContextTable());
			});
		}

		return resultColors;
	};

	public getColorsBorderTableFocusComponents = (): string[] => {
		const components = this.dependencies.componentTree
			.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);

		const resultColors: string[] = [];

		if (components !== null) {
			components.forEach(component => {
				resultColors.push(component.getTexture().borderColor);
			});
		}

		return resultColors;
	};

	protected addPostApplyToGraphicListener = (listener: (component: IComponent) => void) => {
		this.postApplyToGraphicListeners.push(listener);
	};

	protected stretchToWidthForTables = (): void => {
		const tableComponents = this.dependencies
			.componentTree.getUniformComponents<TableComponent>(SketchComponentType.TABLE);
		if (tableComponents === null) {
			return;
		}

		tableComponents.forEach(component => {
			this.dependencies.componentAlignment.alignToWidth(component);
		});
	};

	/** Применить `callback` к каждому текстовому редактору графики в фокусе. */
	protected applyToFocusGraphics = (callback: (graphic: IGraphic, editor: Editor) => void) => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		focusComponents.forEach(component => {
			if (component.type === SketchComponentType.TEXT) {
				const textComponent = component as TextComponent;
				const textGraphics = textComponent.getGraphics();
				textGraphics.forEach(graphic => {
					const textEditor = graphic.getEditor();
					callback(graphic, textEditor);
				});
			}

			if (component.type === SketchComponentType.TABLE) {
				const tableComponent = component as TableComponent;
				const tableGraphics = tableComponent.getGraphics();

				/* Обрабатываю случай, когда в фокусе 1 таблица (если в фокусе несколько таблиц, то на момент
				создания данного метода нет возможности выделить ячейки при фокусе на нескольких таблицах) и выделена
				хотя бы одна ячейка - в этом случае мы должны применять форматирование только к выделенным ячейкам. */
				if (focusComponents.length === 1 && (focusComponents[0] as TableComponent).getFocusCellContexts()) {
					const focusCells = (focusComponents[0] as TableComponent).getFocusCellContexts();
					if (focusCells === null) {
						throw new ManipulatorError('focus cells was null');
					}
					for (const cell of focusCells) {
						callback(tableGraphics[0], cell.getEditor());
					}
				} else {
					const cellEditors = tableComponent.getEditors();
					for (const cell of cellEditors) {
						callback(tableGraphics[0], cell);
					}
				}
				tableComponent.applyMutations();
			}
			this.callPostApplyToGraphicExternalListeners(component);
		});
	};

	/**
	 * Синхронизирует размеры текстовых фреймов в фокусе с реальными размерами текста.
	 */
	protected syncTextComponentsFrameSize = () => {
		const focusComponent = this.dependencies.componentTree.getFocusComponents();
		if (focusComponent === null) {
			return;
		}

		focusComponent.forEach(component => {
			if (component.type !== SketchComponentType.TEXT) {
				return;
			}

			this.syncTextComponentFrameSize(component as TextComponent);
		});
	};

	/**
	 * Обновляет положение картинки относительно фрейма.
	 */
	protected syncPictureComponentsFrameSize = () => {
		const pictures = this.dependencies.componentTree
			.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		pictures?.forEach(component => this.syncPageComponentFrameSize(component));
	};

	protected syncTextComponentFrameSize = (component: TextComponent) => {
		component.disableAutoWidth();
		component.syncFrameSize();
	};

	protected syncPageComponentFrameSize = (component: PictureComponent) => {
		component.resetPictureSize();
	};

	/**
	 * Проверяет есть ли среди компонентов в фокусе текстовый редактор в режиме редактирования.
	 * return Editor если есть редактор в режиме редактирования.
	 * return null если реакторов в режиме редактирования нет.
	 */
	protected getEditableTextEditor = (): Editor | null => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (!focusComponents) return null;
		let result: Editor | null = null;
		focusComponents.forEach((component) => {
			if (component.type === SketchComponentType.TEXT) {
				(component as TextComponent).getEditors()
					.forEach((editor) => {
						if (editor.isActiveEditable()) result = editor;
					});
			} else if (component.type === SketchComponentType.TABLE) {
				(component as TableComponent).getFocusCellContexts()
					?.forEach((cell) => {
						if (cell.getEditor()
							.isActiveEditable()) {
							result = cell.getEditor();
						}
					});
			}
		});
		return result;
	};

	protected applyForFocusPictureGraphic = (callback: (graphic: PictureGraphic) => void) => {
		const pictureComponents = this.dependencies
			.componentTree.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (pictureComponents === null) {
			return;
		}
		const graphics = pictureComponents.map(component => component.getGraphics())
			.flat();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			graphics.forEach(graphic => callback(graphic));
		});
	};

	protected applyForFocusFigureGraphic = (callback: (graphic: FigureGraphic) => void) => {
		const figureComponents = this.dependencies
			.componentTree.getUniformFocusComponents<FigureComponent>(SketchComponentType.FIGURE);
		if (figureComponents === null) {
			return;
		}
		const graphics = figureComponents.map(component => component.getGraphics())
			.flat();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			graphics.forEach(graphic => callback(graphic));
		});
	};

	protected applyForFocusLineGraphic = (callback: (graphic: LineGraphic) => void) => {
		const lineComponents = this.dependencies
			.componentTree.getUniformFocusComponents<LineComponent>(SketchComponentType.LINE);
		if (lineComponents === null) {
			return;
		}
		const graphics = lineComponents.map(component => component.getGraphics())
			.flat();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			graphics.forEach(graphic => callback(graphic));
		});
	};

	public abstract splitTable: (component: TableComponent, activeCellArea: SpatialTableCellArea) => void;
	public abstract replaceTablesFromXLSX: () => void;
	public abstract createTableFromXLSX: () => void;

	private callPostApplyToGraphicExternalListeners = (component: IComponent) => {
		this.postApplyToGraphicListeners.forEach(listener => listener(component));
	};
}

export default BaseUseCases;
