import React, { useEffect, useState } from 'react';
import { IAccount } from '../../../../../entities/user/account/api/types';
import { UserStatusBar } from '../../../../../features/user/user-status';
import css from './user-card.module.scss';
import { copyToClipboard } from '../../../../../helpers/copyText';
import { UserCardRoles } from './roles/roles';
import { UserCardMenu } from './menu/menu';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { useChangeStatusAbility } from '../../libs/useChangeStatusAbility';

interface UserCardProps {
	user: IAccount,
	statusRights: checkUsersRightsInterface,
}

export const UserCard = ({
	user,
	statusRights,
}: UserCardProps) => {
	const {
		email,
		status,
		name,
		phone,
		photo,
		roles,
	} = user;

	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [avatarPreview, setAvatarPreview] = useState<string | null>(null);

	useEffect(() => {
		fetch(`/api/file/user/${photo}`)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json(); // Преобразуем ответ в JSON
			})
			.then(data => {
				setAvatarPreview(data);
			})
			.catch(error => {
				console.error('There was a problem with the fetch operation:', error);
			});
	}, []);

	const isOpenable = useChangeStatusAbility(status, email);

	const handleMenu = (value: boolean) => {
		if (isOpenable) setIsMenuVisible(value);
	};

	const onCopyEmail = () => copyToClipboard(email);

	return (
		<div className={css.wrapper}>
			<div className={css.status_bar}>
				<UserStatusBar userStatus={status} email={email} openable={isOpenable} />
				<UserCardMenu
					email={email}
					statusRights={statusRights}
					setActive={handleMenu}
					isActive={isMenuVisible}
				/>
			</div>
			<div className={css.main}>
				<div className={css.name_info}>
					{/* {photo.length && <img src={`/api/file/user/${photo}`} alt="user" />} */}
					{avatarPreview
						? <img src={avatarPreview} alt="user" />
						: (
							<svg
								className={css.picture}
								width="53"
								height="40"
								viewBox="0 0 53 40"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M34.2908 3.62192C32.9935 2.59546 31.0705 1.927 28.7567 1.927C28.0333 1.927
									27.2702 1.99353 26.4766 2.12976C24.578 2.45924 22.9907 3.49203 21.3211
									4.66421C19.6575 5.82055 17.9268 7.10045 15.7016 7.63585C14.9232 7.8196 14.151
									7.92415 13.4001 7.92415C10.8483 7.92098 8.54674 7.02759 6.86792 6.15637C6.55963
									5.99797 6.27575 5.83639 6.01019 5.68116C6.74277 9.23573 9.66698 12.1218 12.9025
									12.4767C9.27322 13.1578 6.73972 10.9782 5.3173 9.00129C4.45041 11.7322 6.16586
									14.8432 7.6066 16.0946C9.04123 17.3301 10.4637 18.6417 12.9269 19.0029C12.9269
									19.0029 12.2982 22.0854 4.00781 25.1077C4.84417 26.5144 8.03394 27.5725 9.74023
									27.5725C10.4911 27.5757 10.9948 27.7499 13.2444 27.205L13.3451 33.6045L20.5977
									29.6476C21.5592 33.2972 22.9633 35.2487 23.5951 36.1896L26.7666 29.8567L25.7593
									25.6305C25.57 24.8099 25.7318 23.9546 26.2019 23.2798C26.6811 22.5955 27.4167
									22.1773 28.25 22.1076H28.2592H28.2684L29.0559 22.0379C29.5656 15.8792 30.9209
									12.2517 32.5723 10.0848C33.7505 8.52291 35.2156 8.16492 36.3817 7.86396C36.3572
									6.32744 35.5697 4.6452 34.2908 3.62192Z"
									fill="#FFAE00"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M42.0987 12.1738C40.8136 10.4029 39.2081 9.60137 37.517 9.6077C36.6135 9.62988
									35.2705 9.76294 33.9243 11.4864C32.6576 13.1274 30.9024 16.2828 30.4141
									22.1627C36.5158 24.0097 37.1355 29.5285 37.2179 29.9023C38.1 29.3226 39.3882
									28.3532 40.5603 27.1398C42.5199 25.1122 44.2812 22.2166 44.272 18.9852C44.2751
									16.4507 43.3777 13.9353 42.0987 12.1738Z"
									fill="#353535"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M47.1846 22.4997C46.5162 21.3782 45.2799 21.8597 44.352 22.2874C43.4241 22.7151
									41.7788 26.935 39.7703 29.3395C37.7618 31.7441 37.2979 31.4241 36.3211
									27.7365C35.3443 24.0489 31.3793 23.7289 29.3189 23.3012C27.2616 22.8735 27.2097
									25.0626 27.4691 25.7565C27.7255 26.4503 27.2646 26.9857 27.6248 30.084C27.985
									33.1824 30.198 36.7085 30.9702 36.9207C31.7425 37.133 45.3837 35.4254 47.0839
									35.1054C48.7841 34.7854 51.049 34.0916 51.1497 32.5424C51.2504 30.9933 47.8531
									23.6212 47.1846 22.4997Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M52.1473 31.6236L48.5913 22.6675C48.3563 22.0751 47.932 21.5555 47.3978
									21.1975C47.0162 20.9441 46.5889 20.7825 46.1585 20.7192C46.1585 20.7192 47.3429
									16.2427 43.9699 11.1421C42.6391 9.13353 40.2399 7.74275 37.9903 7.51148C37.9689
									5.05307 37.0257 3.41517 35.3622 2.10993C33.6406 0.760336 31.2994 0 28.6377
									0C27.8013 0 26.9345 0.0760336 26.0431 0.231269C23.6501 0.652622 21.8034 1.90718
									20.1398 3.06986C18.4701 4.24521 16.9683 5.31601 15.3078 5.70569C14.7065 5.84825
									14.1052 5.91161 13.5069 5.91161C11.6053 5.91478 9.74634 5.23048 8.29339
									4.54301C5.41497 3.18707 4.21232 1.53968 4.21232 1.53968C3.6171 2.7087 3.611
									4.74576 3.90708 6.65294C3.38512 8.19262 2.96084 9.67844 2.96084 10.9773C2.95168
									14.1074 5.54317 18.2639 9.90201 19.8036C5.01816 22.9811 0 23.8619 0 23.8619C3.52553
									30.6891 10.7384 29.8273 11.2268 29.7735L10.8971 37.9629L19.4591 32.3776C20.6312
									36.3567 23.998 40 23.998 40L27.2122 32.555L27.8899 35.4063C28.0608 36.154 28.4912
									36.8161 29.0986 37.2691C29.7244 37.738 30.4813 37.9439 31.2292 37.8489L50.0534
									35.5299H50.0656L50.0778 35.5267C50.8989 35.4158 51.604 34.9311 52.013
									34.1993C52.4434 33.4294 52.4892 32.4917 52.1473 31.6236ZM45.6274 22.8829C45.6823
									22.8798 45.7129 22.8766 45.7129 22.8766C45.896 22.8608 46.0944 22.921 46.2623
									23.035C46.4332 23.1491 46.5737 23.3106 46.65 23.5007L48.9912 29.3997C47.4253
									28.728 45.2977 27.8125 43.3656 26.9824C43.8509 26.3171 44.3271 25.5821 44.7788
									24.7711C45.1115 24.1787 45.3954 23.5482 45.6274 22.8829ZM37.5172 9.75131C39.1838
									9.74497 40.768 10.5338 42.0347 12.2826C43.2954 14.0187 44.1806 16.4993 44.1775
									18.9957C44.1836 22.1828 42.4498 25.0372 40.5177 27.0363C39.3639 28.2338 38.1582
									29.124 37.2882 29.6943C37.2058 29.3268 36.5312 23.9537 30.5149 22.1321C30.9942
									16.3377 32.7279 13.2235 33.9764 11.6078C35.3011 9.90337 36.6258 9.77348 37.5172
									9.75131ZM25.6158 25.5346L26.6017 29.6975L23.5432 35.834C22.9236 34.9089 21.6721
									33.0873 20.7259 29.4915L13.4459 33.3566L13.3482 27.087C11.1321 27.6255 10.6346
									27.4545 9.89591 27.4513C8.21708 27.4513 5.0731 26.5832 4.24895 25.1956C12.4202
									22.2176 13.2749 18.8658 13.2749 18.8658C10.8483 18.5078 9.21217 17.3547 7.7989
									16.1381C6.37953 14.9057 4.65492 12.3966 5.5096 9.70695C6.91065 11.6585 10.2134
									14.0472 13.8305 12.4378C10.6407 12.0862 6.95339 9.38381 6.22997 5.87993C6.48942
									6.032 6.77024 6.1904 7.07548 6.34564C8.72989 7.20418 10.9948 8.0849 13.51
									8.08807C14.2517 8.08807 15.0117 7.98669 15.7779 7.80295C17.9695 7.27705 19.6728
									6.01616 21.315 4.87565C22.9602 3.72248 24.5261 2.70236 26.3942 2.37922C27.1756
									2.24299 27.9265 2.17963 28.6407 2.17963C30.9209 2.17963 32.8134 2.83542 34.0924
									3.8492C35.353 4.85665 36.0306 6.14605 36.0581 7.66355C34.9104 7.95818 33.5643
									8.67733 32.4013 10.217C30.7744 12.3523 29.3703 15.9259 28.8697 21.9959L28.0944
									22.0624H28.0852H28.0761C27.255 22.1321 26.5285 22.544 26.0584 23.2188C25.5883
									23.8809 25.4296 24.7268 25.6158 25.5346ZM27.7586 25.0277C27.7128 24.8281 27.7952
									24.6317 27.8532 24.4638C27.9387 24.2167 28.2561 23.9949 28.6011 23.9696C30.2311
									23.6084 31.7725 24.486 32.4807 25.0562C33.1858 25.6265 33.8665 26.5262 34.2908
									27.3943C34.3823 27.578 34.4647 27.7618 34.5472 27.9455L29.4374 32.8687L27.7586
									25.0277ZM50.1999 33.1031C50.1236 33.2425 49.9893 33.3376 49.8092 33.3629L31.0186
									35.6091C30.7866 35.6407 30.1578 35.8087 30.0571 34.9533L35.1546 29.5802C35.469
									30.7778 35.472 32.2731 35.3255 33.4738C35.3255 33.4738 38.8114 32.0482 42.3003
									28.2718C45.4046 29.6056 48.634 31.0661 49.8519 31.5888L50.206 32.4568C50.3037
									32.7008 50.2945 32.9352 50.1999 33.1031ZM27.8197 13.0366C27.8197 11.0154 26.9009
									9.67527 25.8447 9.73863C24.7795 9.802 23.7966 11.2751 23.6195 13.1697C23.4303
									15.1655 24.2148 17.0695 25.4937 17.0695C26.7849 17.0695 27.8197 15.1085 27.8197
									13.0366Z"
									fill="#0E0E0E"
								/>
							</svg>
						)}

					<div>{name}</div>
				</div>
				<div className={css.side_info}>
					<div className={css.phone}>{phone}</div>
					<div role="presentation" className={css.email} onClick={onCopyEmail}>{email}</div>
				</div>
			</div>
			<UserCardRoles statusRights={statusRights} email={email} roles={roles} />
		</div>
	);
};
