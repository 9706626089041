import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITemplateData } from '../../types';
import { ITagData } from '../../../modules/tag/api/types';
import { IPrivateTemplateState } from './types';

const initialState: IPrivateTemplateState = {
	search: '',
	tagFilter: [],
	privateTemplates: undefined,
	isSelectionAreaActive: false,
	privateTemplateSelectedList: [],
	isChoicePlaceToMoveModalActive: false,
	isConfirmPrivateDeleteModalActive: false,
};

export const privateTemplateSlice = createSlice({
	name: 'privateTemplate',
	initialState,
	reducers: {
		setPrivateTemplateSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
		setPrivateTemplates: (state, action: PayloadAction<ITemplateData[]>) => {
			state.privateTemplates = action.payload;
		},
		setPrivateTagFilter: (state, action: PayloadAction<ITagData[]>) => {
			state.tagFilter = action.payload;
		},

		setIsSelectionAreaActive: (state, action: PayloadAction<boolean>) => {
			state.isSelectionAreaActive = action.payload;
		},

		setPrivateTemplateSelectedList: (state, action: PayloadAction<string[]>) => {
			state.privateTemplateSelectedList = action.payload;
		},
		addPrivateTemplateSelectedList: (state, action: PayloadAction<string>) => {
			if (!state.privateTemplateSelectedList.includes(action.payload)) {
				state.privateTemplateSelectedList = [...state.privateTemplateSelectedList, action.payload];
			}
		},
		removePrivateTemplateSelectedList: (state, action: PayloadAction<string>) => {
			state.privateTemplateSelectedList = state.privateTemplateSelectedList.filter(
				elem => elem !== action.payload,
			);
		},

		setIsChoicePlaceToMoveModalActive: (state, action: PayloadAction<boolean>) => {
			state.isChoicePlaceToMoveModalActive = action.payload;
		},
		setIsConfirmPrivateDeleteModalActive: (state, action: PayloadAction<boolean>) => {
			state.isConfirmPrivateDeleteModalActive = action.payload;
		},
	},
});

export const {
	setPrivateTemplates,
	setPrivateTagFilter,
	setPrivateTemplateSearch,

	setIsSelectionAreaActive,

	setPrivateTemplateSelectedList,
	removePrivateTemplateSelectedList,
	addPrivateTemplateSelectedList,

	setIsConfirmPrivateDeleteModalActive,
	setIsChoicePlaceToMoveModalActive,
} = privateTemplateSlice.actions;

export default privateTemplateSlice.reducer;
