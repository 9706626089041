import ColorexColorCircle from './ColorexColorCircle';
import globalColorexColors from './colors/GlobalColorexColors';
import ColorexFrequentlyUsed from './ColorexFrequentlyUsed';
import ColorexColor from './colors/ColorexColor';

class ColorexColorsBlock {
	private readonly CSS_ELEMENT_CLASS_NAME = 'colorex-colors-block';
	private readonly CSS_ROW_CLASS_NAME = 'colorex-colors-row';

	private readonly frequentlyUsedBlock: ColorexFrequentlyUsed;
	private readonly element: HTMLElement;
	private readonly circles: ColorexColorCircle[] = [];

	private onSelectColorClick: ((color: ColorexColor) => void) | null;
	private beforeSelectColorEvent: ((color: ColorexColor) => void) | null;

	constructor(frequentlyUsedBlock: ColorexFrequentlyUsed) {
		this.element = document.createElement('div');
		this.element.className = this.CSS_ELEMENT_CLASS_NAME;

		this.onSelectColorClick = null;

		globalColorexColors.forEach(group => {
			const row = document.createElement('div');
			row.classList.add(this.CSS_ROW_CLASS_NAME);

			group.colors.forEach(color => {
				const circle = new ColorexColorCircle();
				circle.setColor(color);
				circle.addClickEvent(this.onCircleClick.bind(this, color));
				this.circles.push(circle);
				row.append(circle.getElement());
			});

			this.element.append(row);
		});

		this.frequentlyUsedBlock = frequentlyUsedBlock;
	}

	public getCircles = (): ColorexColorCircle[] => this.circles;

	public setColorsUsed = (colors: string[] | null): void => {
		if (colors !== null) {
			this.circles.forEach(circle => {
				if (colors.includes(circle.getColor())) {
					circle.getCircleElement().style.outline = 'solid';
					circle.getCircleElement().style.outlineColor = '#0075ff';
				} else {
					circle.getCircleElement().style.outline = 'none';
				}
			});
		}
	};

	public addBeforeSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.beforeSelectColorEvent = ev;
	};

	public addSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.onSelectColorClick = ev;
	};

	public getElement = (): HTMLElement => this.element;

	private onCircleClick = (color: ColorexColor) => {
		if (this.beforeSelectColorEvent !== null) {
			this.beforeSelectColorEvent(color);
		}
		if (this.onSelectColorClick === null) {
			return;
		}
		this.onSelectColorClick(color);

		this.circles.forEach(circle => {
			if (color === circle.getColor()) {
				circle.getCircleElement().style.outline = 'solid';
				circle.getCircleElement().style.outlineColor = '#0075ff';
			} else {
				circle.getCircleElement().style.outline = 'none';
			}
		});
		this.frequentlyUsedBlock.getColorCircles().forEach(circle => {
			if (color === circle.getColor()) {
				circle.getCircleElement().style.outline = 'solid';
				circle.getCircleElement().style.outlineColor = '#0075ff';
			} else {
				circle.getCircleElement().style.outline = 'none';
			}
		});
	};
}

export default ColorexColorsBlock;
