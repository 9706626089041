import ComponentGrouper, { IComponentGrouperDependencies } from '../ComponentGrouper';
import IComponent from '../../../components/IComponent';
import GroupComponent from '../../../components/group/GroupComponent';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IComponentUniter from '../../../components/IComponentUniter';
import IPagesComponentTree from '../../../component-tree/IPagesComponentTree';
import GroupGraphic from '../../../graphic/group/GroupGraphic';
import IGraphic from '../../../graphic/IGraphic';
import { LayerSequences } from '../../../Types';
import IPostGroupingLayers from '../group/IPostGroupingLayers';
import IPostUngroupLayers from '../ungroup/IPostUngroupLayers';

interface IMultiPageComponentGrouperDependencies extends IComponentGrouperDependencies<IPagesComponentTree>{

}

class MultiPageComponentGrouper extends ComponentGrouper<IMultiPageComponentGrouperDependencies> {
	public groupComponents = (components: IComponent[]): GroupComponent => {
		if (components.length === 1) {
			throw new ManipulatorError('not found components for grouping');
		}

		const isAllSingleComponent = this.isAllSingleComponent(components);
		if (isAllSingleComponent) {
			return this.groupSingleComponents(components);
		}

		return this.groupVariousComponents(components);
	};

	public ungroupComponents = (components: IComponent[]) => {
		console.log('ungroupComponents');
		console.log(components);
		components
			.filter((component) => component.isUniter)
			.forEach((component) => this.ungroupGroup(component as IComponentUniter));

		const rootComponent = this.dependencies.componentTree.getRootComponent();
		rootComponent.recursiveSyncGraphicsOffset();
	};

	groupSingleComponents = (components: IComponent[]): GroupComponent => {
		let groupComponent: GroupComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			const rootComponent = this.dependencies.componentTree.getRootComponent();

			// Все графики компонентов, переданных для объединения в группу
			const componentGraphics = components.map(component => component.getGraphics()).flat();
			// const graphicIds = componentGraphics.map(graphic => graphic.getID());

			// Последовательность слоев до объединения
			const globalLayers = this.dependencies.componentTree.getLayerSequences();

			// Отражение для хранения последовательности слоев на каждой странице,
			// которое должно быть применено после вставки всей графики
			const pagesSequence = this.getLayerSequenceMap(componentGraphics, globalLayers);
			// Создание массива ID графики для сохранения последовательности слоев
			const graphicSequence = pagesSequence.map(page => page.map(graphic => graphic.getID())).flat();

			// Упорядоченная по возрастанию коллекция страниц, на которых есть графики для объединения
			const graphicPageNumbers = new Set<number>();
			componentGraphics.forEach(graphic => {
				const pageNumber = this.dependencies.componentTree.getGraphicPageNumber(graphic);
				graphicPageNumbers.add(pageNumber);
			});

			// Количество график у группы (например один из объединяемых компонентов может быть на несколько страниц)
			const groupGraphicCount = graphicPageNumbers.size;

			// Номер страницы, с которой должен начинаться компонент группы
			const startPageNumber = Math.min(...Array.from(graphicPageNumbers));

			const groupStructure = this.getDefaultGroupStructure(startPageNumber);
			const groupGraphicStructures = this.getDefaultGroupGraphicStructures(groupGraphicCount);
			const groupGraphics = this.getGroupGraphics(groupComponent, groupGraphicStructures, tools);

			this.correctPostGroupingComponentOffsets(components);

			// Получить последовательность слоев для корректировки их после объединения
			const postGroupingLayers = this.getPostGroupingLayers(
				groupGraphics,
				componentGraphics,
				globalLayers,
				pagesSequence,
			);

			// Удалить объединяемые компоненты из дерева
			components.forEach(component => tools.mutator.mutateByRemoveComponent(component));
			// TODO удалить компоненты из действия отмены

			// Создать компонент группы
			groupComponent = tools.componentFactory.createComponent(groupStructure);
			groupComponent.setParentComponent(rootComponent);

			// Добавить графику группы в компонент группы
			groupGraphics.forEach(groupGraphic => groupComponent.appendGraphic(groupGraphic));

			// Добавить компонент группы в дерево
			tools.mutator.mutateByAppendComponent(rootComponent, groupComponent);

			// Добавить объединяемые компоненты в компонент группы
			components.forEach(component => tools.mutator.mutateByAppendComponent(groupComponent, component));

			// Синхронизировать слои после объединения компонентов
			this.syncPostGroupingLayers(postGroupingLayers, tools.mutator);

			groupComponent.setTexture((prev) => ({
				...prev,
				layerSequence: [...graphicSequence],
			}));

			// Синхронизировать размеры группового компонента с его содержимым
			this.dependencies.componentTree.syncUniterComponentsSizeFromComponent(groupComponent);
		});

		return groupComponent!;
	};

	getPostGroupingLayers = (
		groupGraphics: GroupGraphic[],
		groupingGraphics: IGraphic[],
		globalLayers: LayerSequences,
		groupingGraphicLayers: IGraphic[][],
	): IPostGroupingLayers => {
		const postGroupingLayers: IPostGroupingLayers = {
			layersFromPages: [],
		};

		let currentSequenceIndex = 0;
		globalLayers.forEach((_, currentPageIndex) => {
			if (groupingGraphicLayers[currentPageIndex] === undefined) {
				return;
			}

			const graphicIdentity = groupingGraphicLayers[currentPageIndex].map(graphic => graphic.getID());
			const globalPageLayers = globalLayers[currentPageIndex];
			const firstGroupingGraphicID = globalPageLayers.find(graphicID => graphicIdentity.includes(graphicID));
			if (firstGroupingGraphicID === undefined) {
				throw new ManipulatorError('first graphic not found', { globalLayers, globalPageLayers });
			}

			const firstGroupingGraphic = groupingGraphics.find(graphic => graphic.getID() === firstGroupingGraphicID);
			if (firstGroupingGraphic === undefined) {
				throw new ManipulatorError('first graphic not found');
			}

			let baseGraphic = this.dependencies.componentTree.getPrevLayerGraphic(firstGroupingGraphic);
			if (baseGraphic === null) {
				const pageNumber = this.dependencies.componentTree.getGraphicPageNumber(firstGroupingGraphic);
				const page = this.dependencies.componentTree.getPageFromNumber(pageNumber);
				if (page === null) {
					throw new ManipulatorError('page not found');
				}
				baseGraphic = page;
			}

			if (groupGraphics[currentSequenceIndex] === undefined) {
				throw new ManipulatorError('group graphics not found', { groupGraphics, currentSequenceIndex });
			}
			if (groupingGraphicLayers[currentPageIndex] === undefined) {
				throw new ManipulatorError(
					'grouping graphics not found',
					{ groupingGraphicLayers, currentPageIndex },
				);
			}

			postGroupingLayers.layersFromPages[currentSequenceIndex] = {
				baseGraphic,
				groupGraphic: groupGraphics[currentSequenceIndex],
				groupingGraphics: groupingGraphicLayers[currentPageIndex],
			};

			currentSequenceIndex++;
		});

		return postGroupingLayers;
	};

	getPostUngroupingLayers = (
		componentGraphics: IGraphic[],
		globalLayers: string[][],
		graphicInGroupLayers: IGraphic[][],
	): IPostUngroupLayers => {
		const postUngroupLayers: IPostUngroupLayers = {
			layersFromPages: [],
		};

		let currentSequenceIndex = 0;
		globalLayers.forEach((_, currentPageIndex) => {
			if (graphicInGroupLayers[currentPageIndex] === undefined) {
				return;
			}

			const graphicIdentity = graphicInGroupLayers[currentPageIndex].map(graphic => graphic.getID());
			const globalPageLayers = globalLayers[currentPageIndex];
			const firstGroupingGraphicID = globalPageLayers.find(graphicID => graphicIdentity.includes(graphicID));
			if (firstGroupingGraphicID === undefined) {
				throw new ManipulatorError('first graphic not found', { globalLayers, globalPageLayers });
			}

			const firstGroupingGraphic = componentGraphics.find(graphic => graphic.getID() === firstGroupingGraphicID);
			if (firstGroupingGraphic === undefined) {
				throw new ManipulatorError('first graphic not found');
			}

			let baseGraphic = this.dependencies.componentTree.getPrevLayerGraphic(firstGroupingGraphic);
			if (baseGraphic === null) {
				const pageNumber = this.dependencies.componentTree.getGraphicPageNumber(firstGroupingGraphic);
				const page = this.dependencies.componentTree.getPageFromNumber(pageNumber);
				if (page === null) {
					throw new ManipulatorError('page not found');
				}
				baseGraphic = page;
			}

			if (graphicInGroupLayers[currentPageIndex] === undefined) {
				throw new ManipulatorError(
					'grouping graphics not found',
					{ graphicInGroupLayers, currentPageIndex },
				);
			}

			postUngroupLayers.layersFromPages[currentSequenceIndex] = {
				baseGraphic,
				groupingGraphics: graphicInGroupLayers[currentPageIndex],
			};

			currentSequenceIndex++;
		});

		return postUngroupLayers;
	};
}

export default MultiPageComponentGrouper;
