import { useState } from 'react';
import { SelectableUserList } from './selectable-user-list/selectable-user-list';
import css from './select-user-modal.module.scss';
import { employeeAPI } from '../../../entities/employee/api/api';
import { NewModal } from '../../../components/ui/modals/new-modal';
import { ModalTitle } from '../../../shared/ui';
import SearchInput from '../../../components/ui/searchInput/SearchInput';
import { MainButton } from '../../../components/ui/main-button/main-button';
import { IAccount } from '../../../entities/user/account/api/types';

interface SelectWorkshopTemplateModalProps {
	isActive: boolean;
	isLoading: boolean;
	selectedUser: string;
	onSelect: (user: IAccount) => void,
	setActive: (active: boolean) => void,
	onSave: () => void,
}

export const SelectUserModal = ({
	onSave,
	isActive,
	onSelect,
	isLoading,
	setActive,
	selectedUser,
}: SelectWorkshopTemplateModalProps) => {
	const [search, setSearch] = useState('');

	const { data: users } = employeeAPI.useGetAllQuery({
		page: 1,
		limit: 127,
		search,
	});

	return (
		<NewModal active={isActive} closeFunc={setActive}>
			<ModalTitle>Выбрать пользователя</ModalTitle>
			<SearchInput placeholder="Поиск" searchValue={search} onChange={setSearch} />
			<div className={css.selected_users}>
				<SelectableUserList onSelect={onSelect} selectedUser={selectedUser} users={users?.accounts || []} />
			</div>
			<MainButton
				type="button"
				btnStyle="bright"
				onClick={onSave}
				disabled={isLoading || !selectedUser.length}
			>
				Сохранить
			</MainButton>
		</NewModal>
	);
};
