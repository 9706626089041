import Dependent from '../../utils/dependent/Dependent';
import { notificationError } from '../../../Notifications/callNotifcation';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';
import PictureImporter from '../import-picture/PictureImporter';
import PictureComponent from '../../components/picture/PictureComponent';

export interface IPictureReplacerDependencies {
    pictureImporter: PictureImporter,
    sketchStabilizer: SketchStructureStabilizer,
    componentFocusObserver: ComponentUIObserver,
}

class PictureReplacer extends Dependent<IPictureReplacerDependencies> {
	private readonly prevReplaceListeners: VoidFunction[];
	private readonly postReplaceListeners: VoidFunction[];

	constructor() {
		super();
		this.prevReplaceListeners = [];
		this.postReplaceListeners = [];

		this.addPrevReplaceListener(() => {
			this.dependencies.sketchStabilizer.startUserAction();
		});

		this.addPostReplaceListener(() => {
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 0);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction.bind(this), 0);
		});
	}

	/**
	 * Заменяет переданные картинки в приложение на основание переданного Blob-элемента.
	 * Метод конвертирует Blob в base64 и отправляет на сервер, после чего добавляет картинку
	 * с полученным id.
	 * @param pictureComponents Изображения, которые необходимо заменить.
	 * @param blob Блоб, который содержит изображение.
	 */
	public replacePicture = async (pictureComponents: PictureComponent[], blob: Blob) => {
		const id = await this.dependencies.pictureImporter.getId(blob);

		if (id === null) {
			notificationError('Замена картинки.', 'В буфере нет картинок.');
			return;
		}
        
		this.callPrevReplaceListeners();

		for (let i = 0; i < pictureComponents.length; i++) {
			const focusPicture = pictureComponents[i];
			if (focusPicture === undefined) {
				throw new ManipulatorError('picture is undefined');
			}

			const firstGraphic = focusPicture.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}

			firstGraphic.setTexture(prev => ({
				...prev,
				source: id,
			}));
			firstGraphic.adaptPictureToFrame();
		}
        
		this.callPostReplaceListeners();
	};

	private addPostReplaceListener(listener: () => void) {
		this.postReplaceListeners.push(listener);
	}

	private callPostReplaceListeners = () => {
		this.postReplaceListeners.forEach(listener => listener());
	};

	private addPrevReplaceListener(listener: () => void) {
		this.prevReplaceListeners.push(listener);
	}

	private callPrevReplaceListeners = () => {
		this.prevReplaceListeners.forEach(listener => listener());
	};
}

export default PictureReplacer;
