import InterfacePanel from './InterfacePanel';

class DropdownPanel extends InterfacePanel {
	private dropdownShowed: boolean;

	constructor() {
		super();
		this.dropdownShowed = false;
		this.addClickOutsideHandler();
	}

	public openWindowAtPosition = () => {
		if (this.rootElement.style.display === 'none') {
			this.showWindow();
		} else {
			this.hideWindow();
		}
	};

	public closeWindow = () => {
		this.hideWindow();
	};

	public isDropdownShow = (): boolean => this.dropdownShowed;

	private showWindow = () => {
		this.rootElement.style.display = 'block';
		this.addClickInsideHandler();
		this.setCoords();
		this.dropdownShowed = true;
	};

	private hideWindow = () => {
		this.rootElement.style.display = 'none';
		this.removeClickInsideHandler();
		this.dropdownShowed = false;
	};

	private setCoords = () => {
		// Сброс координат для корректного позиционирования
		this.rootElement.style.left = `${0}px`;
		this.rootElement.style.top = `${0}px`;

		const rect = this.rootElement.getBoundingClientRect();
		if (rect.right > window.innerWidth) {
			this.rootElement.style.left = 'auto';
		}
		if (rect.bottom > window.innerHeight) {
			this.rootElement.style.top = `${window.innerHeight - rect.height}px`;
		}
	};

	private isChildOfPanel = (element: HTMLElement | null): boolean => {
		while (element) {
			if (element === this.rootElement) {
				return true;
			}
			element = element.parentElement;
		}
		return false;
	};

	private addClickOutsideHandler() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	private handleClickOutside = (event: MouseEvent) => {
		if (!this.isChildOfPanel(event.target as HTMLElement)) {
			this.closeWindow();
		}
	};

	private addClickInsideHandler() {
		this.rootElement.addEventListener('mousedown', this.handleClickInside);
		this.rootElement.addEventListener('click', this.handleClickInside);
	}

	private removeClickInsideHandler() {
		this.rootElement.removeEventListener('mousedown', this.handleClickInside);
		this.rootElement.removeEventListener('click', this.handleClickInside);
	}

	private handleClickInside = (event: MouseEvent) => {
		event.stopPropagation();
	};
}

export default DropdownPanel;
