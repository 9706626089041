import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import {
	URI_PRIVATE_TEMPLATE,
	URI_PRIVATE_TEMPLATE_BIND_TAG,
	URI_PRIVATE_TEMPLATE_DUPLICATE,
	URI_PRIVATE_TEMPLATE_DUPLICATE_TEAM, URI_PRIVATE_TEMPLATE_GENERATE_PDF,
	URI_PRIVATE_TEMPLATE_WITHOUT_REGISTRATION,
} from './consts';
import {
	IBindTagToPrivateTemplateRequest,
	ICreatePrivateTemplateRequest,
	IDuplicateTeamTemplateRequest,
	IDuplicateTemplateRequest,
	IGetPrivateTemplateListRequest,
	IGetPrivateTemplateListResponse,
	IGetPrivateTemplateResponse,
} from './types';

export const templatePrivateAPI = createApi({
	reducerPath: 'templatePrivateAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['templates'],
	endpoints: builder => ({
		create: builder.mutation<{ id: string }, ICreatePrivateTemplateRequest>({
			query: ({ rootComponent }) => ({
				url: URI_PRIVATE_TEMPLATE,
				method: 'POST',
				body: { rootComponent },
			}),
			invalidatesTags: ['templates'],
		}),
		bind: builder.mutation<void, IBindTagToPrivateTemplateRequest>({
			query: ({
				tagId,
				templateId,
			}) => ({
				url: URI_PRIVATE_TEMPLATE_BIND_TAG(templateId, tagId),
				method: 'POST',
			}),
			invalidatesTags: ['templates'],
		}),
		unbind: builder.mutation<void, IBindTagToPrivateTemplateRequest>({
			query: ({
				tagId,
				templateId,
			}) => ({
				url: URI_PRIVATE_TEMPLATE_BIND_TAG(templateId, tagId),
				method: 'DELETE',
			}),
			invalidatesTags: ['templates'],
		}),
		duplicate: builder.mutation<void, IDuplicateTemplateRequest>({
			query: (data) => ({
				url: URI_PRIVATE_TEMPLATE_DUPLICATE,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['templates'],
		}),
		duplicateToTeam: builder.mutation<void, IDuplicateTeamTemplateRequest>({
			query: (data) => ({
				url: URI_PRIVATE_TEMPLATE_DUPLICATE_TEAM,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['templates'],
		}),
		get: builder.query<IGetPrivateTemplateResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_PRIVATE_TEMPLATE}/${id}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 0,
			providesTags: ['templates'],
		}),
		getAll: builder.query<IGetPrivateTemplateListResponse, IGetPrivateTemplateListRequest>({
			query: (data) => ({
				url: URI_PRIVATE_TEMPLATE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['templates'],
		}),
		delete: builder.mutation<void, { templates: string[] }>({
			query: ({ templates }) => ({
				url: URI_PRIVATE_TEMPLATE,
				method: 'DELETE',
				body: { templates },
			}),
			invalidatesTags: ['templates'],
		}),
		update: builder.mutation<void, { template: string, name: string }>({
			query: ({
				template,
				name,
			}) => ({
				url: `${URI_PRIVATE_TEMPLATE}/${template}`,
				method: 'PUT',
				body: { name },
			}),
			invalidatesTags: ['templates'],
		}),
		getWithoutRegistration: builder.query<IGetPrivateTemplateResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_PRIVATE_TEMPLATE_WITHOUT_REGISTRATION}/${id}`,
				method: 'GET',
			}),
			providesTags: ['templates'],
		}),
		generatePDF: builder.query<Uint8Array, { templateID: string }>({
			query: ({ templateID }) => ({
				url: `${URI_PRIVATE_TEMPLATE_GENERATE_PDF}/${templateID}`,
				method: 'GET',
				responseHandler: (response) => response.blob(), // Обрабатываем ответ как Blob
			}),
			providesTags: ['templates'],
		}),
	}),
});
