import GraphicFactory from './GraphicFactory';
import IComponent from '../../components/IComponent';
import GraphicType from '../../graphic/GraphicType';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import TableGraphic from '../../graphic/table/TableGraphic';
import IMutationPermission from '../../mechanics/IMutationPermission';
import TextGraphic from '../../graphic/text/TextGraphic';
import SketchComponentType from '../../components/SketchComponentType';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TextComponent from '../../components/text/TextComponent';
import FigureGraphic from '../../graphic/figure/FigureGraphic';
import IGraphic from '../../graphic/IGraphic';
import IManipulatorInterface from '../../interface/IManipulatorInterface';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';
import ModuleGraphic from '../../graphic/module/ModuleGraphic';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';
import PageGraphic from '../../graphic/page/PageGraphic';
import IMultiPageUseCases from '../../use-cases/IMultiPageUseCases';
import LineGraphic from '../../graphic/line/LineGraphic';

interface IDynamicGraphicFactoryDependencies {
	useCases: IMultiPageUseCases,
	componentTree: IPagesComponentTree,
	componentFocusObserver: ComponentUIObserver,
}

/**
 * Фабрика для сборки изменяемой графики.
 */
class DynamicGraphicFactory extends GraphicFactory<IDynamicGraphicFactoryDependencies> {
	private readonly MIN_TEXT_WIDTH = 80;
	private readonly DEFAULT_TEXT_WIDTH = 300;

	private readonly MIN_WIDTH_FOR_PICTURE_ACTION_LAYER_DISPLAY = 150;
	private readonly MIN_HEIGHT_FOR_PICTURE_ACTION_LAYER_DISPLAY = 50;

	private readonly manipulatorInterface: IManipulatorInterface;

	constructor(manipulatorInterface: IManipulatorInterface) {
		super();
		this.manipulatorInterface = manipulatorInterface;
	}

	public createGraphic = <GraphicT extends IGraphic>(
		type: GraphicType,
		parentComponent: IComponent,
	): GraphicT => {
		let graphic: IGraphic;

		switch (type) {
		case GraphicType.PAGE: {
			const pageGraphic = new PageGraphic();

			this.injectTopPageHeader(pageGraphic, this.dependencies.useCases);
			this.injectBottomPageHeader(pageGraphic, this.dependencies.useCases);

			const backgroundImageState = pageGraphic.getBackgroundImageState();
			backgroundImageState.addMutationListener(this.applyAllPagesChanges.bind(this, pageGraphic));

			graphic = pageGraphic;
			break;
		}
		case GraphicType.MODULE:
			graphic = new ModuleGraphic();
			break;
		case GraphicType.GROUP: {
			graphic = new GroupGraphic();
			break;
		}
		case GraphicType.PICTURE: {
			const pictureGraphic = new PictureGraphic();
			this.configurePictureGraphic(pictureGraphic);

			graphic = pictureGraphic;
			break;
		}
		case GraphicType.TABLE: {
			graphic = new TableGraphic();

			const mutationPermissions: IMutationPermission = {
				TOP: false,
				LEFT: true,
				RIGHT: true,
				BOTTOM: false,
				LEFT_TOP: false,
				RIGHT_TOP: false,
				LEFT_BOTTOM: false,
				RIGHT_BOTTOM: false,
			};
			graphic.setMutationPermissions(mutationPermissions);
			graphic.setMinWidth(80);
			// graphic.addChangeSelectedRangeEvent(this.manipulatorInterface.sync);

			break;
		}
		case GraphicType.TEXT: {
			const textGraphic = new TextGraphic();

			if (parentComponent.type !== SketchComponentType.TEXT) {
				throw new ManipulatorError('parent component is not text');
			}

			const textComponent = parentComponent as TextComponent;
			const editor = textGraphic.getEditor();
			const mutationPermissions: IMutationPermission = {
				TOP: false,
				LEFT: true,
				RIGHT: true,
				BOTTOM: false,
				LEFT_TOP: false,
				RIGHT_TOP: false,
				LEFT_BOTTOM: false,
				RIGHT_BOTTOM: false,
			};

			editor.addPostPasteEvent(this.checkTextComponentMaxWidth.bind(this, textComponent));

			editor.addPostChangeModelEvent(
				() => this.dependencies.componentTree.syncUniterComponentsSizeFromComponent(textComponent),
			);

			textGraphic.setMutationPermissions(mutationPermissions);
			textGraphic.addPostInputListener(textComponent.syncFrameSize);
			textGraphic.addPostInputListener(this.dependencies.componentFocusObserver.sync);
			textGraphic.addMutationListener(this.updateAutoWidthMode.bind(this, textComponent, textGraphic));
			textGraphic.addMutationListener(textComponent.syncFrameSize);
			textGraphic.addPostChangeFocusEvent(this.manipulatorInterface.sync);

			textGraphic.addChangeSelectedRangeEvent(this.manipulatorInterface.sync);

			graphic = textGraphic;
			break;
		}
		case GraphicType.FIGURE: {
			graphic = new FigureGraphic();
			break;
		}
		case GraphicType.LINE: {
			const line = new LineGraphic();
			line.addFrameConfigurationChangeListener(line.changeLongVertical);
			graphic = line;
			break;
		}
		default: {
			throw new ManipulatorError(`graphic type "${type}" not found`);
		}
		}

		graphic.setParentComponent(parentComponent);

		return graphic as GraphicT;
	};

	private configurePictureGraphic = (graphic: PictureGraphic) => {
		graphic.addMutationListener(this.togglePictureActionLayer.bind(this, graphic));
		graphic.addPostEnableHoverListener(this.togglePictureActionLayer.bind(this, graphic));
		graphic.addPostEnableFocusListener(this.togglePictureActionLayer.bind(this, graphic));
		graphic.addPostEnableHoverListener(this.dependencies.componentFocusObserver.sync);
	};

	private togglePictureActionLayer = (pictureGraphic: PictureGraphic) => {
		pictureGraphic.enablePictureActionLayer();
		const { width, height } = pictureGraphic.getFrameConfiguration();
		if (width > this.MIN_WIDTH_FOR_PICTURE_ACTION_LAYER_DISPLAY
			&& height > this.MIN_HEIGHT_FOR_PICTURE_ACTION_LAYER_DISPLAY) {
			pictureGraphic.disableCompactActionLayerMode();
			return;
		}
		pictureGraphic.enableCompactActionLayerMode();
	};

	/**
	 * Если текущая ширина элемента меньше порогового значения,
	 * то включает AutoWidth, иначе отключает.
	 * @param component
	 * @param graphic
	 */
	private updateAutoWidthMode = (component: TextComponent, graphic: TextGraphic) => {
		const currentWidth = graphic.getFrameConfiguration().width;

		if (currentWidth < this.MIN_TEXT_WIDTH) {
			component.enableAutoWidth();
		} else {
			component.disableAutoWidth();
		}

		graphic.setFrameConfiguration(prev => ({
			...prev,
			width: graphic.getRealWidth(),
		}));
	};

	/**
	 * Проверяет ширину графики и если ширина больше ширины страницы - отключит autoWidth.
	 */
	public checkTextComponentMaxWidth = (component: TextComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const pageNumber = this.dependencies.componentTree.getGraphicPageNumber(graphic);
			const page = this.dependencies.componentTree.getPageFromNumber(pageNumber);
			if (page === null) {
				throw new ManipulatorError('page not found');
			}
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === page) {
				requestAnimationFrame(this.resizeGraphicsForTextOverflow.bind(this, graphic, parentGraphic));
				requestAnimationFrame(this.resizeGraphicsAfterOverflow.bind(this, graphic));
			}
		});
	};

	/**
	 * Обеспечивает корректное отображение текста на странице,
	 * предотвращая его выход за границы доступного пространства
	 * и обеспечивая правильное визуальное отображение контента.
	 * @param graphic
	 * @param parentGraphic
	 */
	private resizeGraphicsForTextOverflow = (graphic: TextGraphic, parentGraphic: IGraphic) => {
		const focusPageWidth = parentGraphic.getFrameConfiguration().width;
		const { paddingRight } = parentGraphic.getTexture();

		const textComponent = graphic.getParentComponent() as TextComponent;
		const relativePosition = this.dependencies.componentTree.getRelativePagePosition(graphic);

		const graphicWidth = graphic.getRealWidth();
		const availableSpace = focusPageWidth - paddingRight;
		const textRightEdgeCoordinate = relativePosition.x + graphicWidth;

		if (textRightEdgeCoordinate > availableSpace) {
			textComponent.disableAutoWidth();
			graphic.disableAutoWidth();

			let availableTextWidth = availableSpace - relativePosition.x;

			if (availableTextWidth < this.MIN_TEXT_WIDTH) {
				availableTextWidth = this.DEFAULT_TEXT_WIDTH;
			}

			graphic.setFrameConfiguration((prev) => ({
				...prev,
				width: availableTextWidth,
			}));

			textComponent.syncFrameSize();
		}
	};

	/**
	 * Устанавливает стандартную ширину текста после вставки,
	 * если ширина меньше минимальной допустимой для вставки.
	 * @param graphic
	 */
	private resizeGraphicsAfterOverflow = (graphic: TextGraphic) => {
		const textComponent = graphic.getParentComponent() as TextComponent;
		const currentWidth = graphic.getFrameConfiguration().width;

		if (currentWidth < this.MIN_TEXT_WIDTH) {
			graphic.setFrameConfiguration((prev) => ({
				...prev,
				width: this.DEFAULT_TEXT_WIDTH,
			}));

			textComponent.syncFrameSize();
			this.dependencies.componentFocusObserver.sync();
		}
	};

	private applyAllPagesChanges = (targetPage: PageGraphic) => {
		const updatedTexture = targetPage.getTexture();
		const pages = this.dependencies.componentTree.getPages();

		pages.forEach(page => {
			if (page === targetPage) {
				return;
			}
			page.setTexture(_ => updatedTexture);
		});
	};
}

export default DynamicGraphicFactory;
