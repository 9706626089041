import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { setWorkshopDuplicateModalActive } from '../../../../../entities/templates/workshop/template/model/slice';
import { usePublishWorkshopTemplate } from '../api/usePublishWorkshopTemplate';
import { NewModal } from '../../../../../components/ui/modals/new-modal';
import InputArrowItem
	from '../../../../../components/pages/features/moduleWindow/createInputArrow/inputArrowItem/InputArrowItem';
import { tariffAPI } from '../../../../../entities/tariff/api/api';
import { tariffMapData } from '../../../../../entities/tariff/model/data';
import { MainButton } from '../../../../../components/ui/main-button/main-button';
import { ModalTitle } from '../../../../../shared/ui';
import css from './publish-workshop-template-modal.module.scss';
import { usePublishTariffSelected } from '../libs/usePublishTariffSelected';

export const PublishWorkshopTemplateModal = () => {
	const dispatch = useAppDispatch();

	const id = useAppSelector(state => state.workshopTemplate.activeWorkshopTemplate);
	const isActive = useAppSelector(state => state.workshopTemplate.isDuplicateModalActive);

	const handleModal = (value: boolean) => dispatch(setWorkshopDuplicateModalActive(value));

	const { data: bitmap } = tariffAPI.useGetBitmapQuery();

	const {
		isLoading,
		onDuplicate,
	} = usePublishWorkshopTemplate();

	const {
		selectTariff,
		removeTariff,
		cleanTariffs,
		selectedTariffs,
		currentPermission,
	} = usePublishTariffSelected();

	const duplicateTemplate = async () => {
		if (id) {
			await onDuplicate(id, currentPermission);
			cleanTariffs();
			handleModal(false);
		}
	};

	useEffect(() => {
		if (!isActive) cleanTariffs();
	}, [isActive]);

	return (
		<NewModal
			active={isActive}
			styles={css.wrapper}
			closeFunc={handleModal}
		>
			<ModalTitle>Выберите тарифы</ModalTitle>
			<div className={css.tariffs}>
				{
					bitmap?.tariffs.map(tariff => (
						<InputArrowItem
							key={tariff.tariff}
							id={tariff.tariff}
							isChoiced={selectedTariffs.includes(tariff)}
							onAddChoicedItem={() => selectTariff(tariff)}
							onRemoveChoicedItem={() => removeTariff(tariff)}
							name={tariffMapData.get(tariff.tariff) as string}
						/>
					))
				}
			</div>
			<MainButton
				type="button"
				btnStyle="bright"
				onClick={duplicateTemplate}
				disabled={isLoading || selectedTariffs.length === 0}
			>
				Опубликовать
			</MainButton>
		</NewModal>
	);
};
