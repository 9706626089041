import ElementContainer from '../../utils/ElementContainer';
import HTMLElementName from '../../utils/HTMLElementName';
import IBaseUseCases from '../../use-cases/base/IBaseUseCases';
import SvgCollection from '../../utils/SvgCollection';
import IGraphic from '../../graphic/IGraphic';
import HTMLGenerator from '../../utils/HTMLGenerator';
import Utils from '../../utils/impl/Utils';
import PictureGraphic from '../../graphic/picture/PictureGraphic';

interface ICreateButtonProps {
	icon: SvgCollection,
	text: string,
	buttonClassName: string,
	onClickListener: VoidFunction,
}

class PictureActionLayer extends ElementContainer<HTMLDivElement> {
	private readonly COMPACT_MODE_CLASS_NAME = 'compact-mode';
	private readonly LAYER_CLASS_NAME = 'picture-action-layer';
	private readonly REPLACE_CLASS_NAME = 'picture-action-layer_replace';

	private readonly replaceButton: HTMLElement;
	private readonly parentGraphic: IGraphic;

	constructor(containerElement: HTMLElement, useCases: IBaseUseCases, graphic: PictureGraphic) {
		super(HTMLElementName.DIV);
		this.parentGraphic = graphic;

		this.setRootElementClassName(this.LAYER_CLASS_NAME);
		containerElement.append(this.rootElement);

		this.replaceButton = this.createButton({
			icon: SvgCollection.IMG_LOAD,
			buttonClassName: this.REPLACE_CLASS_NAME,
			onClickListener: useCases.loadPictureComponentWithAdaptFrame.bind(this, graphic),
			text: 'Заменить',
		});

		this.rootElement.append(this.replaceButton);

		const position = this.parentGraphic.getGlobalPosition();
		const widthGraphic = this.parentGraphic.getRealWidth();
		const widthReplaceButton = this.replaceButton.offsetWidth;
		this.rootElement.style.left = `${position.x + widthGraphic - widthReplaceButton}px`;
		this.rootElement.style.top = `${position.y}px`;
	}

	public enableCompactMode = () => {
		this.rootElement.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.replaceButton.classList.add(this.COMPACT_MODE_CLASS_NAME);
	};

	public disableCompactMode = () => {
		this.rootElement.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.replaceButton.classList.remove(this.COMPACT_MODE_CLASS_NAME);
	};

	public enable = () => {
		this.rootElement.style.display = 'flex';
		const position = this.parentGraphic.getGlobalPosition();
		const widthGraphic = this.parentGraphic.getRealWidth();
		const widthReplaceButton = this.replaceButton.offsetWidth;

		this.rootElement.style.left = `${position.x + widthGraphic - widthReplaceButton}px`;
		this.rootElement.style.top = `${position.y}px`;
	};

	public disable = () => {
		this.rootElement.style.display = 'none';
	};

	public removeElement = (): void => {
		this.rootElement.remove();
	};

	private createButton({
		icon, buttonClassName, onClickListener, text,
	}: ICreateButtonProps): HTMLElement {
		const container = HTMLGenerator.getDiv();
		container.className = buttonClassName;
		Utils.DOM.injectSVG(container, icon);
		container.addEventListener('click', onClickListener);

		const textElement = HTMLGenerator.getSpan({ text });
		container.append(textElement);

		return container;
	}
}
export default PictureActionLayer;
