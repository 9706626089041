import OrganizerMutation from './OrganizerMutation';
import TableComponent from '../../../components/table/TableComponent';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import Utils from '../../../utils/impl/Utils';
import ITableRowProperty from '../../../components/table/ITableRowProperty';
import IGraphic from '../../../graphic/IGraphic';
import IMutablePagesComponentTree from '../../../component-tree/IMutablePagesComponentTree';
import IComponent from '../../../components/IComponent';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import IFrameConfiguration from '../../../frame/IFrameConfiguration';
import TableGraphic from '../../../graphic/table/TableGraphic';
import GraphicType from '../../../graphic/GraphicType';
import IMutationTools from '../../../component-tree/IMutationTools';
import IPageTexture from '../../../graphic/page/IPageTexture';
import Stack from '../../../structures/Stack';
import SketchComponentType from '../../../components/SketchComponentType';
import GroupComponent from '../../../components/group/GroupComponent';

/**
 * Мутация таблицы.
 * Перераспределяет строки между графикой таблицы относительно их физического расположения внутри страницы.
 */
class OrganizerTableMutation extends OrganizerMutation<TableComponent> {
	private requiredStartRows: number[] | null;

	constructor(
		graphic: IGraphic,
		moveOffset: number,
		component: TableComponent,
		componentTree: IMutablePagesComponentTree,
	) {
		super(graphic, moveOffset, component, componentTree);
		this.requiredStartRows = null;
	}

	public inspectPostMovePlace = (): void => {
		// nothing
	};

	public run = (): void => {
		const rowsProperties = this.component.getRowProperties();

		if (rowsProperties.every(prop => prop.height === 0)) {
			throw new ManipulatorError('row properties not found');
		}
		this.requiredStartRows = this.calculateRequiredStartRows(rowsProperties);

		this.correctGraphicCount(this.requiredStartRows.length);

		this.reorganizeRows();

		if (this.requiredStartRows.length > 1 && this.requiredStartRows[0] === 0 && this.requiredStartRows[1] === 0) {
			this.componentTree.executeMutations(tools => {
				if (this.requiredStartRows === null) {
					throw new ManipulatorError('required start row not found');
				}
				const firstGraphic = this.component.getFirstGraphic();
				if (firstGraphic === null) {
					throw new ManipulatorError('first graphic not found');
				}
				const currentOffset = this.component.getOffset();
				if (currentOffset === null) {
					throw new ManipulatorError('offset not found');
				}

				tools.mutator.mutateByRemoveGraphic(firstGraphic);

				this.requiredStartRows.splice(0, 1);
				this.reorganizeRows();

				tools.mutator.mutateByChangeOffset(this.component, currentOffset + 1);
			});
		}

		setTimeout(this.component.syncGraphicsHeight, 100);

		// если таблица в группе то изменяем ее слои
		const parentComponent = this.component.getParentComponent();
		if (parentComponent && parentComponent.type === SketchComponentType.GROUP) {
			const groupParentComponent = parentComponent as GroupComponent;

			const graphicIDs = this.component.getGraphics().map(g => g.getID());
			const groupTexture = groupParentComponent.getTexture();

			const existingIndex = groupTexture.layerSequence.findIndex(id => graphicIDs.includes(id));

			if (existingIndex !== -1) {
				const uniqueIDs = graphicIDs.filter(id => !groupTexture.layerSequence.includes(id));

				groupTexture.layerSequence.splice(existingIndex + 1, 0, ...uniqueIDs);
			}

			groupParentComponent.setTexture((prev) => ({
				...prev,
				layerSequence: groupTexture.layerSequence,
			}));
		}
	};

	/**
	 * Возвращает коллекцию номеров начальных строк таблицы для каждой графики на основании их физического
	 * расположения относительно страниц.
	 * @param rowsProperties Информация о высоте каждой строки таблицы.
	 */
	private calculateRequiredStartRows = (rowsProperties: ITableRowProperty[]): number[] => {
		const startRows: number[] = [0];

		const tableGraphics = this.component.getGraphics();
		if (tableGraphics.length === 0) {
			throw new ManipulatorError('table graphics nor found');
		}

		const currentGraphicIndex = 0;
		const currentGraphic = tableGraphics[currentGraphicIndex];
		let currentPageIndex = this.componentTree.getGraphicPageNumber(currentGraphic);

		let currentPageGraphic = this.componentTree.getPageFromNumber(currentPageIndex);
		if (currentPageGraphic === null) {
			throw new ManipulatorError('first page graphic not found');
		}
		let currentPageTexture = currentPageGraphic.getTexture();

		const currentAvailableSpaceY = currentPageGraphic.getAvailableSpaceY();
		const positionRelativePage = this.componentTree.getRelativePagePosition(currentGraphic);
		let currentAvailableSpace = currentAvailableSpaceY
			- (positionRelativePage.y - currentPageTexture.paddingBottom);

		let currentRowSpan = 1;

		rowsProperties.forEach((rowProperty, rowIndex) => {
			if (rowIndex === 0) {
				currentRowSpan = rowProperty.rowSpan;
			}

			if (currentRowSpan === 1) {
				currentAvailableSpace -= rowProperty.height;

				if (currentAvailableSpace < 0) {
					currentPageIndex++;
					currentPageGraphic = this.componentTree.getPageFromNumber(currentPageIndex);
					if (currentPageGraphic === null) {
						currentPageGraphic = this.componentTree.mutateAddPageToEnd();
					}
					currentPageTexture = currentPageGraphic.getTexture();
					currentAvailableSpace = currentPageGraphic.getAvailableSpaceY();
					currentAvailableSpace -= rowProperty.height + currentPageTexture.paddingBottom;

					startRows.push(rowIndex);
				}
				if (rowIndex + 1 < rowsProperties.length) {
					currentRowSpan = rowsProperties[rowIndex + 1].rowSpan;
				}
			} else if (currentRowSpan > 1) {
				currentRowSpan--;
			}
		});

		return startRows;
	};

	/**
	 * Корректирует необходимое количество графики таблицы.
	 * Удаляет и добавляет графику до необходимого числа `graphicCount`.
	 * @param graphicCount Необходимое количество графики.
	 */
	private correctGraphicCount = (graphicCount: number) => {
		const tableGraphics = this.component.getGraphics();

		this.componentTree.executeMutations(tools => {
			if (this.requiredStartRows === null) {
				throw new ManipulatorError('required start rows not initialized');
			}

			// Если текущее количество графики больше требуемого
			if (tableGraphics.length > graphicCount) {
				const deleteGraphics = tableGraphics.slice(graphicCount);
				deleteGraphics.forEach(graphic => tools.mutator.mutateByRemoveGraphic(graphic));
			}

			// Если текущего количества графики не хватает, чтобы вместить все ячейки
			if (tableGraphics.length < graphicCount) {
				const parentComponent = this.component.getParentComponent();
				if (parentComponent === null) {
					throw new ManipulatorError('parent component not found');
				}
				const tableComponentOffset = this.component.getOffset();
				if (tableComponentOffset === null) {
					throw new ManipulatorError('table component is null');
				}

				for (let i = tableGraphics.length; i < graphicCount; i++) {
					const startRow = this.requiredStartRows[i];
					if (startRow === undefined) {
						throw new ManipulatorError('start row not found');
					}

					// Найти страницу, на которую будет добавляться графика
					const graphics = this.component.getGraphics();
					const prevTableGraphic = graphics[graphics.length - 1];
					const prevGraphicConfiguration = prevTableGraphic.getFrameConfiguration();
					const lastGraphicPageNumber = this.componentTree.getGraphicPageNumber(prevTableGraphic);
					const page = this.componentTree.getPageFromNumber(lastGraphicPageNumber + 1);
					if (page === null) {
						throw new ManipulatorError('page not found');
					}

					const pageTexture = page.getTexture();

					// Найти графику, на которую будет добавляться графика таблицы
					const parentGraphic = this.getParentGraphicToExtendTable(this.component, i);
					if (parentGraphic === undefined) {
						throw new ManipulatorError('parent graphic not found');
					}

					const extendGraphicPosition = this.getExtendGraphicPosition(
						pageTexture.paddingTop,
						parentGraphic,
						prevTableGraphic,
					);
					const extendTableGraphic = this.getExtendGraphic(
						this.component,
						startRow,
						extendGraphicPosition,
						prevGraphicConfiguration,
						tools,
					);
					tools.mutator.mutateByAppendGraphic(this.component, extendTableGraphic);
				}
			}
		});

		// Включить фокус у вновь добавившейся графике, если он был включен изначально.
		if (this.component.isEnableFocus()) {
			this.component.enableFocus();
		}
	};

	/**
	 * Применяет `requiredStartRows` на таблицу `this.component`.
	 */
	private reorganizeRows = () => {
		if (this.requiredStartRows === null) {
			throw new ManipulatorError('not calculated required start rows');
		}

		const currentStartRows = this.component.getStartRows();

		setTimeout(this.component.mutateSyncRealRowHeight.bind(this), 0);
		setTimeout(this.component.syncGraphicsHeight.bind(this), 100);
		setTimeout(this.component.applyMutations.bind(this), 100);

		// Если изменений с момента последней реорганизации не произошло, тогда выходим из метода.
		if (Utils.Object.deepEqual(this.requiredStartRows, currentStartRows)) {
			return;
		}

		this.setTableStartRows(this.requiredStartRows);
		this.correctExtendTableGraphicPosition(this.component);
	};

	/**
	 * Сообщает всей графике начальные номера строк для отрисовки.
	 * @param startRowNumbers - номера начальных строк отрисовки для каждой графики.
	 */
	private setTableStartRows = (startRowNumbers: number[]) => {
		this.component.mutateStartRows(startRowNumbers);
		this.component.applyMutations();
		this.component.syncGraphicsHeight();
	};

	/**
	 * Возвращает созданную добавочную графику таблицы.
	 * @param tableComponent Компонент таблицы.
	 * @param startRow Номер строки, с которой следует начать производить вставку ячеек.
	 * @param extendGraphicPosition Позиция для создаваемой графики.
	 * @param prevGraphicConfiguration Конфигурация фрейма предыдущей графики перед создаваемой.
	 * @param tools Инструменты для мутации структуры дерева компонентов.
	 */
	private getExtendGraphic = (
		tableComponent: TableComponent,
		startRow: number,
		extendGraphicPosition: IDescartesPosition,
		prevGraphicConfiguration: IFrameConfiguration,
		tools: IMutationTools,
	): TableGraphic => {
		const graphic = tools.graphicFactory.createGraphic<TableGraphic>(GraphicType.TABLE, tableComponent);

		const { x, y } = extendGraphicPosition;
		const { width } = prevGraphicConfiguration;
		const borderColor = tableComponent.getBorderColor();
		const columnMultipliers = tableComponent.getColumnMultipliers();

		graphic.setColumnMultipliers(columnMultipliers);
		graphic.setStartRow(startRow);
		graphic.setBorderColor(borderColor);
		graphic.setFrameConfiguration(prev => ({
			...prev,
			x,
			y,
			width,
		}));

		return graphic;
	};

	/**
	 * Возвращает необходимую позицию для новой графики таблицы с учетом того, что любая графика таблицы,
	 * за исключением первой, должна располагаться на верхнем отступе страницы и иметь такую же
	 * координату по горизонтали, как у предыдущей графики.
	 * @param pagePaddingTop Внутренний верхний отступ страницы.
	 * @param parentGraphic Графика, в которую будет вставлена новая графика таблицы.
	 * @param prevGraphic Предыдущая графика таблицы перед вставляемой.
	 */
	private getExtendGraphicPosition = (
		pagePaddingTop: number,
		parentGraphic: IGraphic,
		prevGraphic: IGraphic,
	): IDescartesPosition => {
		const prevGraphicConfiguration = prevGraphic.getFrameConfiguration();

		if (parentGraphic.type === GraphicType.PAGE) {
			return {
				y: pagePaddingTop,
				x: prevGraphicConfiguration.x,
			};
		}

		let currentParentGraphic: IGraphic | null = parentGraphic;
		let currentConfiguration = currentParentGraphic.getFrameConfiguration();

		let topOffsetSum = 0;
		while (currentParentGraphic !== null) {
			topOffsetSum += currentConfiguration.y;

			currentParentGraphic = currentParentGraphic.getParentGraphic();
			if (currentParentGraphic !== null) {
				currentConfiguration = currentParentGraphic.getFrameConfiguration();
			}
		}

		const relativeParentPositionY = topOffsetSum - pagePaddingTop;

		return {
			y: relativeParentPositionY,
			x: prevGraphicConfiguration.x,
		};
	};

	/**
	 * Корректирует позицию дополнительных график таблицы, чтобы они были всегда привязаны
	 * к верхнему отступу листа, учитывая их вложенность в другие компоненты.
	 * @param tableComponent Компонент таблицы, содержащий обрабатываемую графику.
	 */
	private correctExtendTableGraphicPosition = (tableComponent: IComponent) => {
		const graphics = tableComponent.getGraphics();
		const firstGraphic = tableComponent.getFirstGraphic();
		if (firstGraphic === null) {
			throw new ManipulatorError('first graphic not found');
		}
		const relativeFirstGraphicPosition = this.componentTree.getRelativePagePosition(firstGraphic);
		const extendGraphics = graphics.slice(1);

		if (extendGraphics.length === 0) {
			return;
		}

		extendGraphics.forEach(graphic => {
			const relativePagePosition = this.componentTree.getRelativePagePosition(graphic);
			const pageIndex = this.componentTree.getGraphicPageNumber(graphic);
			const page = this.componentTree.getPageFromNumber(pageIndex);
			if (page === null) {
				throw new ManipulatorError('page not found');
			}
			const pageTexture = page.getTexture();

			if (relativePagePosition.y !== pageTexture.paddingTop) {
				this.correctVerticalExtendGraphic(graphic, pageTexture);
			}

			if (relativePagePosition.x !== relativeFirstGraphicPosition.x) {
				this.correctHorizontalExtendGraphic(graphic, relativeFirstGraphicPosition.x);
			}
		});
	};

	/**
	 * Корректирует вертикальную позицию графики таблицы по внутреннему отступу страницы.
	 * @param graphic Корректируемая графика таблицы.
	 * @param pageTexture Текстура страницы, от которой идет корректировка позиции.
	 */
	private correctVerticalExtendGraphic = (graphic: IGraphic, pageTexture: IPageTexture) => {
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}

		const parentRelativePagePosition = this.componentTree.getRelativePagePosition(parentGraphic);

		let offset = 0;
		if (parentRelativePagePosition.y > pageTexture.paddingTop) {
			offset = -(parentRelativePagePosition.y - pageTexture.paddingTop);
		} else {
			offset = Math.abs(parentRelativePagePosition.y) + pageTexture.paddingTop;
		}

		graphic.setFrameConfiguration(prev => ({
			...prev,
			y: offset,
		}));
	};

	/**
	 * Корректирует горизонтальною позицию графики таблицы по требуемой относительной страницы позиции.
	 * @param graphic Корректируемая графика таблицы.
	 * @param requiredPosition Требуемая глобальная горизонтальная позиция.
	 */
	private correctHorizontalExtendGraphic = (graphic: IGraphic, requiredPosition: number) => {
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}

		const parentRelativePagePosition = this.componentTree.getRelativePagePosition(parentGraphic);

		let offset = 0;
		if (parentRelativePagePosition.y > requiredPosition) {
			offset = -(parentRelativePagePosition.y - requiredPosition);
		} else {
			offset = Math.abs(parentRelativePagePosition.y) + requiredPosition;
		}

		graphic.setFrameConfiguration(prev => ({
			...prev,
			x: offset,
		}));
	};

	/**
	 * Возвращает родительскую графику для графики таблицы.
	 * @param tableComponent Компонент таблицы.
	 * @param graphicIndex Индекс графики таблицы, для которой необходимо вернуть родительскую графику.
	 */
	private getParentGraphicToExtendTable = (
		tableComponent: IComponent,
		graphicIndex: number,
	): IGraphic => {
		const tableComponentOffset = tableComponent.getOffset();
		if (tableComponentOffset === null) {
			throw new ManipulatorError('table component not include offset');
		}
		const lastTableGraphic = tableComponent.getLastGraphic();
		if (lastTableGraphic === null) {
			throw new ManipulatorError('last graphic not found');
		}
		const parentComponent = tableComponent.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}

		let parentComponentGraphics = parentComponent.getGraphics();
		let parentGraphic = parentComponentGraphics[tableComponentOffset + graphicIndex];
		if (parentGraphic === undefined) {
			this.generateParentsGraphics(tableComponent, lastTableGraphic);
		}

		parentComponentGraphics = parentComponent.getGraphics();
		parentGraphic = parentComponentGraphics[tableComponentOffset + graphicIndex];

		return parentGraphic;
	};

	/**
	 * Генерирует необходимую графику родительских компонентов таблицы для размещения всей графики таблицы.
	 * @param tableComponent Компонент таблицы, от которой будут генерироваться родительские графики.
	 * @param lastGraphic Последняя графика таблицы.
	 */
	private generateParentsGraphics = (
		tableComponent: IComponent,
		lastGraphic: IGraphic,
	) => {
		const tableComponentOffset = tableComponent.getOffset();
		if (tableComponentOffset === null) {
			throw new ManipulatorError('table component offset not found');
		}

		const forExtendComponents = new Stack<IComponent>();

		let parentComponent = tableComponent.getParentComponent();

		while (parentComponent !== null) {
			forExtendComponents.push(parentComponent);
			parentComponent = parentComponent.getParentComponent();
		}

		const tablePageIndex = this.componentTree.getGraphicPageNumber(lastGraphic) + 1;

		let forExtendComponent = forExtendComponents.pop();
		while (forExtendComponent !== undefined) {
			const lastGraphic = forExtendComponent.getLastGraphic();
			if (lastGraphic === null) {
				throw new ManipulatorError('last graphic not found');
			}
			const graphicPageIndex = this.componentTree.getGraphicPageNumber(lastGraphic);
			const extendGraphicCount = tablePageIndex - graphicPageIndex;

			for (let i = 0; i < extendGraphicCount; i++) {
				this.componentTree.mutateByExtendComponentToEnd(forExtendComponent);
			}

			forExtendComponent = forExtendComponents.pop();
		}
	};
}

export default OrganizerTableMutation;
