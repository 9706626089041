import React, { useState } from 'react';
import cx from 'classnames';
import Loader from 'react-ts-loaders';
import css from './gallery.module-card.module.scss';
import Utils from '../../../components/Sketch/utils/impl/Utils';
import { ModuleControls } from '../module/ui/controls/controls';
import { ModuleTags } from '../module/ui/tags/tags';
import { TemplateInfoIcon } from '../../../shared/ui';
import {
	ReactComponent as TemplateLockIcon,
} from '../../../shared/images/collection/template/template-lock.svg';
import {
	ReactComponent as TemplateUnlockIcon,
} from '../../../components/pages/collection/section/template/galleries/images/template-unlock.svg';
import { Tariff, TTariffName } from '../../tariff/model/data';
import { ISimpleTemplateGalleryModule } from '../../template-gallery-module/model/types/types';
import { notificationError } from '../../../components/Notifications/callNotifcation';
import { TemplateModulesModalTabsEnum } from '../../../widgets/template-modules-modal/model/types';

interface GalleryModuleCardProps {
	module: ISimpleTemplateGalleryModule,
	onDeleteModule?: () => void,
	onBindTag?: (moduleId: string) => void,
	onModuleClick?: ((id: string) => void) | null,
	currentTab: TemplateModulesModalTabsEnum,
    userTariff: TTariffName,
	isGlobalLoading: boolean;
	setGlobalLoading: (moduleId: string | null) => void;
}

export const GalleryModuleCard = ({
	module,
	onModuleClick,
	onDeleteModule,
	onBindTag,
	currentTab,
	userTariff,
	isGlobalLoading,
	setGlobalLoading,
}: GalleryModuleCardProps) => {
	const {
		name,
		id,
		tags,
		preview,
		description,
	} = module;

	const [isHoverBlockVisible, setIsHoverBlockVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onMouseEnterHandler = () => {
		setIsHoverBlockVisible(true);
	};

	const onMouseLeaveHandler = () => {
		setIsHoverBlockVisible(false);
	};

	const onBindingTag = () => {
		onBindTag?.(id);
	};

	const isModuleAccessible = (
		userTariff: TTariffName,
		tariffPermission: number,
	): boolean => {
		const trial = 1;
		const basic = 2;
		return userTariff === Tariff.TRIAL
			? Boolean(trial & tariffPermission)
			: Boolean(basic & tariffPermission);
	};

	const tariffPermission = module.gallery ? module.gallery.tariffPermission : 0;
	const isAccessible = isModuleAccessible(userTariff, tariffPermission);

	const moduleClick = async () => {
		if (isAccessible && !isGlobalLoading) {
			setGlobalLoading(id);
			setIsLoading(true);
			try {
				await onModuleClick?.(id);
			} catch (error) {
				notificationError('Возникла ошибка при выборе модуля в галерее!');
				console.error('Произошла ошибка: ', error);
			} finally {
				setIsLoading(false);
				setGlobalLoading(null);
			}
		}
	};

	return (
		<div
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onMouseEnter={!isGlobalLoading ? onMouseEnterHandler : undefined}
			onMouseLeave={!isGlobalLoading ? onMouseLeaveHandler : undefined}
		>
			<div
				className={css.picture}
				onClick={moduleClick}
				role="presentation"
			>
				{currentTab === TemplateModulesModalTabsEnum.GALLERY && (
					isAccessible ? (
						<TemplateInfoIcon type="light">
							<TemplateUnlockIcon />
						</TemplateInfoIcon>
					) : (
						<TemplateInfoIcon type="bright">
							<TemplateLockIcon />
						</TemplateInfoIcon>
					)
				)}
				{currentTab !== TemplateModulesModalTabsEnum.GALLERY && (
					<ModuleControls
						deleteModule={onDeleteModule}
						isHoverBlockVisible={isHoverBlockVisible}
					/>
				)}
				<img
					src={Utils.Backend.getSystemImageURI(preview)}
					alt="Картинка модуля"
					className={css.preview}
				/>
				{
					isLoading && <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />
				}
			</div>
			<ModuleTags tags={tags || []} moduleId={id} onBindTag={onBindingTag} />
			<div className={css.title}>{name}</div>
			<div className={css.comment}>
				{description}
			</div>
		</div>
	);
};
