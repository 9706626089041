import KeyCombination from '../KeyCombination';
import KeyboardSubscriber from './KeyboardSubscriber';

export type KeyEvent = ((ev: KeyboardEvent) => void) | (() => void)

class KeyboardObserver {
	private readonly subscribersOnKeyUp: KeyboardSubscriber[];
	private readonly subscribersOnKeyDown: KeyboardSubscriber[];

	private _isAltPressed = false;
	private _isCtrlPressed = false;
	private isShiftPressed = false;

	public isEnable: boolean;

	constructor() {
		this.subscribersOnKeyUp = [];
		this.subscribersOnKeyDown = [];

		document.addEventListener('keyup', this.onKeyUp);
		document.addEventListener('keydown', this.onKeyDown);

		this.isEnable = true;
	}

	public enableKeyboardObserver = () => {
		this.isEnable = true;
	};

	public disableKeyboardObserver = () => {
		this.isEnable = false;
	};

	public subscribeOnKeyUp = (key: KeyCombination, event: KeyEvent) => {
		const subscriber = new KeyboardSubscriber(key, event);
		this.subscribersOnKeyUp.push(subscriber);
	};

	public subscribeOnKeyDown = (key: KeyCombination, event: KeyEvent) => {
		const subscriber = new KeyboardSubscriber(key, event);
		this.subscribersOnKeyDown.push(subscriber);
	};

	public onCtrlDown = () => {
		if (this._isCtrlPressed) return;
		this._isCtrlPressed = true;
	};

	public onCtrlUp = () => {
		if (!this._isCtrlPressed) return;
		this._isCtrlPressed = false;
	};

	public onShiftDown = () => {
		if (this.isShiftPressed) return;
		this.isShiftPressed = true;
	};

	public onShiftUp = () => {
		if (!this.isShiftPressed) return;
		this.isShiftPressed = false;
	};

	public onAltDown = () => {
		this._isAltPressed = true;
	};

	public onAltUp = () => {
		this._isAltPressed = false;
	};

	public get isCtrlPressed(): boolean {
		return this._isCtrlPressed;
	}

	public get isAltPressed(): boolean {
		return this._isAltPressed;
	}

	public destruct = () => {
		document.removeEventListener('keyup', this.onKeyUp);
		document.removeEventListener('keydown', this.onKeyDown);
	};

	private onKeyDown = (ev: KeyboardEvent) => {
		this.call(this.subscribersOnKeyDown, ev);
	};

	private onKeyUp = (ev: KeyboardEvent) => {
		this.call(this.subscribersOnKeyUp, ev);
	};

	private call = (subscribers: KeyboardSubscriber[], ev: KeyboardEvent) => {
		if	(!this.isEnable) return;
		subscribers.forEach(subscriber => {
			const isCall = subscriber.isKey(ev);
			if (isCall) {
				subscriber.call(ev);
			}
		});
	};
}

export default KeyboardObserver;
