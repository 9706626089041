import React, { useEffect, useMemo } from 'react';
import 'react-calendar/dist/Calendar.css';
import { NewModal } from '../../../../../../ui/modals/new-modal';
import { DropdownArrowInput } from '../../../../../../../widgets/dropdown-arrow-input';
import { adminUserChangeTariffData, adminUserChangeTariffDurationData } from '../../../model/data';
import { useChangeTariffSelectedData } from '../../../libs/useChangeTariffSelectedData';
import css from './change-tariff.module.scss';
import { MainButton } from '../../../../../../ui/main-button/main-button';
import { ArrowInput } from '../../../../../../../shared/ui';
import { CustomDateTimePicker } from '../../../../../../../widgets/datetime-picker';
import { useTariffDate } from '../../../libs/useStartTariffDate';
import { calculateDuration } from '../../../../../../../shared/libs/utils/calculate-duration';
import { useCreateTransaction } from '../../../libs/useCreateTransaction';
import { TTariffName } from '../../../../../../../entities/tariff/model/data';
import { IChangeTariffModalProps } from './model/types';
import { formatDateForServer } from './utils/dateUtils';

export const ChangeTariffModal = ({
	organizationId,
	isActive,
	setActive,
}: IChangeTariffModalProps) => {
	const {
		data: selectedTariff,
		handle: handleTariff,
	} = useChangeTariffSelectedData();

	const {
		data: selectedDuration,
		handle: handleDuration,
	} = useChangeTariffSelectedData();

	const {
		selectedDate: selectedStartDate,
		handleDateWithCalendar: handleStartCalendarDate,
		startDate,
		formattedDate: formattedStartCalendarDate,
		setIsCalendarOpen: setStartDateCalendarOpen,
		isCalendarOpen: isStartCalendarOpen,
	} = useTariffDate();

	const {
		selectedDate: selectedDurationCalendarDate,
		handleDateWithCalendar: handleDurationCalendarDate,
		formattedDate: formattedDurationCalendarDate,
		setIsCalendarOpen: setDurationCalendarOpen,
		isCalendarOpen: isDurationCalendarOpen,
		setSelectedDate: setDurationDate,
	} = useTariffDate();

	const onSelectDurationCalendarDate = (id: string) => {
		if (selectedDuration[0] !== id) {
			setDurationCalendarOpen(true);
		} else {
			setDurationCalendarOpen(false);
		}
		setDurationDate(null);
		handleDuration(id);
	};

	useEffect(() => {
		if (selectedDuration.length) {
			const date = new Date(selectedStartDate || new Date());

			date.setMonth(
				date.getMonth()
                + (Number(adminUserChangeTariffDurationData[Number(selectedDuration[0]) - 1]?.name.split(' ')[0]) || 0),
			);

			setDurationDate(date);
		} else {
			setDurationDate(null);
		}
	}, [selectedDuration]);

	const {
		createTransaction,
		isLoading,
	} = useCreateTransaction({
		organizationId,
		tariff: selectedTariff[0] as TTariffName,
		startDateTime: formatDateForServer(formattedStartCalendarDate),
		duration: calculateDuration(
			selectedStartDate, 
			selectedDurationCalendarDate,
		),
	});

	const onCreateTransaction = async () => {
		await createTransaction();

		setActive(false);
		handleTariff(selectedTariff[0]);
		handleDuration(selectedDuration[0]);
		handleStartCalendarDate(null);
		handleDurationCalendarDate(null);

		setStartDateCalendarOpen(false);
		setDurationCalendarOpen(false);
	};

	const isBtnActive = useMemo(
		() => selectedTariff.length * selectedDuration.length && selectedStartDate,
		[selectedTariff, selectedDuration, selectedStartDate],
	);

	const minEndPeriodDate = useMemo(() => {
		if (selectedStartDate) {
			const date = new Date(selectedStartDate);
			date.setDate(date.getDate() + 1);
			return date;
		}
		return startDate;
	}, [selectedStartDate, startDate]);

	return (
		<NewModal active={isActive} closeFunc={setActive}>
			<div className={css.wrapper}>
				<div className={css.title}>Сменить тариф</div>
				<div className={css.content}>
					<DropdownArrowInput
						selectedData={selectedTariff}
						data={adminUserChangeTariffData}
						onItemClick={handleTariff}
					>
						Тариф
					</DropdownArrowInput>
					<ArrowInput onClick={() => setStartDateCalendarOpen(!isStartCalendarOpen)}>
						{
							selectedStartDate
								? `Дата начала: ${formattedStartCalendarDate}`
								: 'Дата начала'
						}
					</ArrowInput>
					<DropdownArrowInput
						selectedData={selectedDuration}
						data={adminUserChangeTariffDurationData}
						onItemClick={handleDuration}
						onLastItemClick={onSelectDurationCalendarDate}
					>
						{
							selectedDurationCalendarDate
								? `Дата окончания: ${formattedDurationCalendarDate}`
								: 'Продолжительность'
						}
					</DropdownArrowInput>
				</div>
				<MainButton
					type="button"
					btnStyle="bright"
					onClick={onCreateTransaction}
					disabled={!isBtnActive || isLoading}
				>
					Сохранить
				</MainButton>
				{
					isStartCalendarOpen && (
						<div className={css.calendar}>
							<CustomDateTimePicker
								minDate={startDate}
								value={selectedStartDate}
								setValue={handleStartCalendarDate}
								setOpen={setStartDateCalendarOpen}
								maxDate={selectedDurationCalendarDate}
							/>
						</div>
					)
				}
				{
					isDurationCalendarOpen && (
						<div className={css.calendar}>
							<CustomDateTimePicker
								setOpen={setDurationCalendarOpen}
								value={selectedDurationCalendarDate}
								setValue={handleDurationCalendarDate}
								minDate={minEndPeriodDate}
							/>
						</div>
					)
				}
			</div>
		</NewModal>
	);
};
