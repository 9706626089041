import API from '../../../../api/API';
import PictureComponent from '../../components/picture/PictureComponent';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import GraphicType from '../../graphic/GraphicType';
import IDescartesPosition from '../../utils/IDescartesPosition';
import IComponentInjectionParams from '../../component-injector/IComponentInjectionParams';
import IGraphic from '../../graphic/IGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import SketchComponentType from '../../components/SketchComponentType';
import IPictureTexture from '../../graphic/picture/IPictureTexture';
import Utils from '../../utils/impl/Utils';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';
import Dependent from '../../utils/dependent/Dependent';
import { notificationError } from '../../../Notifications/callNotifcation';

export interface IPictureImporterDependencies {
	sketchStabilizer: SketchStructureStabilizer,
	mousePositionObserver: MousePositionObserver;
	componentFocusObserver: ComponentUIObserver,
	componentTree: IComponentTreeMutator & IComponentTree & ILayeredComponentTree;
}

class PictureImporter extends Dependent<IPictureImporterDependencies> {
	private readonly DEFAULT_WIDTH = 500;
	private readonly DEFAULT_HEIGHT = 500;

	private readonly prevInjectListeners: VoidFunction[];
	private readonly postInjectListeners: VoidFunction[];

	constructor() {
		super();

		this.prevInjectListeners = [];
		this.postInjectListeners = [];

		this.addPrevInjectListener(() => {
			this.dependencies.sketchStabilizer.startUserAction();
		});
		this.addPostInjectListener(() => {
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 0);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction.bind(this), 0);
		});
	}

	/**
	 * Вставляет картинку в приложение на основание переданного Blob-элемента.
	 * Метод конвертирует Blob в base64 и отправляет на сервер, после чего добавляет картинку
	 * с полученным id.
	 * @param blob Блоб, который содержит изображение.
	 */
	public importPicture = async (blob: Blob) => {
		const id = await this.getId(blob);
		
		if (id === null) {
			notificationError('Вставка картинки.', 'В буфере нет картинок.');
			return;
		}

		this.callPrevInjectListeners();

		this.injectPicture(id);
	};

	/**
	 * Возвращает структуры картинок, найденных в переданном HTML.
	 * @param blob
	 */
	public getId = async (blob: Blob) => {
		const { type } = blob;

		if (type === 'text/html') {
			return null;
		}
		if (type === 'image/png' || type === 'image/jpeg') {
			const base64 = await this.blobToBase64(blob);

			const response = await API.file.createUserFile({
				name: `file_${Date.now()}`,
				bytes: base64,
			}, blob.size).catch(() => null);

			const id = response ? response.id : null;
			return id;
		} 
		return null;
	};
	
	private injectPicture = (id: string) => {
		const parentComponent = this.dependencies.componentTree.getRootComponent();
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const injectParams = this.calculateComponentInjectionParams(mousePosition);

		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		const structure = {
			id: componentId,
			type: SketchComponentType.PICTURE,
			offset: injectParams.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.PICTURE,
					frame: {
						x: injectParams.x,
						y: injectParams.y,
						width: this.DEFAULT_WIDTH,
						height: this.DEFAULT_HEIGHT,
						rotate: 0,
						layer: 100,
					},
					offset: 0,
					texture: {
						x: 0,
						y: 0,
						width: this.DEFAULT_WIDTH,
						height: this.DEFAULT_HEIGHT,
						source: id,
					} as IPictureTexture,
				},
			],
			texture: null,
			components: null,
		};

		let pictureComponent: PictureComponent;
		this.dependencies.componentTree.executeMutations(async tools => {
			pictureComponent = tools.componentFactory.createComponent<PictureComponent>(structure);

			structure.graphics?.forEach(graphicStructure => {
				const graphic = tools
					.graphicFactory.createGraphic<PictureGraphic>(GraphicType.PICTURE, pictureComponent);
				graphic.setStructure(() => graphicStructure);
				graphic.adaptPictureToFrame();
				pictureComponent.appendGraphic(graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, pictureComponent);

			const pictureGraphic = pictureComponent.getFirstGraphic();
			if (pictureGraphic === null) {
				throw new ManipulatorError('picture graphic not found');
			}
		});

		this.callPostInjectListeners();
	};

	private blobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			if (reader.result) {
				resolve(reader.result as string);
			} else {
				reject(new Error('Failed to read blob'));
			}
		};

		reader.onerror = () => reject(new Error('Error reading the blob'));
		reader.readAsDataURL(blob);
	});

	private addPostInjectListener(listener: () => void) {
		this.postInjectListeners.push(listener);
	}

	private callPostInjectListeners = () => {
		this.postInjectListeners.forEach(listener => listener());
	};

	private addPrevInjectListener(listener: () => void) {
		this.prevInjectListeners.push(listener);
	}

	private callPrevInjectListeners = () => {
		this.prevInjectListeners.forEach(listener => listener());
	};

	private calculateComponentInjectionParams = (
		mousePosition: IDescartesPosition,
	): IComponentInjectionParams => {
		// Переменная для хранения ближайшей страницы
		const nearestPage = this.getNearestPage(mousePosition);

		const { width, height } = nearestPage.getFrameConfiguration();
		const { x, y } = nearestPage.getGlobalPosition();
		const {
			paddingLeft, paddingRight, paddingTop, paddingBottom,
		} = nearestPage.getTexture();

		/* Вычисляем параметры вставки картинки:
		 * Определяем положение мыши относительно границ страницы и выполняем соответствующие действия.
		 * 1. Первый случай: мышь находится внутри границ страницы по обеим координатам x и y.
		 *		Рассчитываем смещение от позиции вставки относительно левого верхнего угла страницы.
		 * 2. Второй случай: координата x мыши лежит внутри страницы, но y — за пределами страницы (выше или ниже).
		 * 		В этом случае ограничиваем смещение по y в пределах paddingTop или height - paddingBottom.
		 * 3. Третий случай: координата y мыши лежит внутри страницы, но x — за пределами страницы (слева или справа).
		 * 		Здесь ограничиваем смещение по x в пределах paddingLeft или width - paddingRight.
		 * 4. Четвертый случай: ни x, ни y не попадают внутрь страницы.
		 * 		В этом случае размещаем текст в центре страницы.
 		*/
		let posX: number;
		let posY: number;
		// Первый случай
		if ((mousePosition.x >= x && mousePosition.x <= x + width)
			&& (mousePosition.y >= y && mousePosition.y <= y + height)) {
			posX = mousePosition.x - x;
			posY = mousePosition.y - y;
			// Второй случай
		} else if ((mousePosition.x >= x && mousePosition.x <= x + width)
			&& (mousePosition.y < y || mousePosition.y > y + height)) {
			posX = mousePosition.x - x;
			posY = mousePosition.y < y ? paddingTop : height - paddingBottom - this.DEFAULT_HEIGHT;
			// Третий случай
		} else if ((mousePosition.y >= y && mousePosition.y <= y + height)
			&& (mousePosition.x < x || mousePosition.x > x + width)) {
			posX = mousePosition.x < x ? paddingLeft
				: Math.max(paddingLeft, width - paddingRight - this.DEFAULT_WIDTH);
			posY = mousePosition.y - y;
			// Четвертый случай
		} else {
			posX = Math.max(paddingLeft, width / 2 - this.DEFAULT_WIDTH / 2);
			posY = height / 2;
		}

		return {
			x: posX,
			y: posY,
			componentOffset: nearestPage.getOffset(),
		};
	};

	private getNearestPage = (mousePosition: IDescartesPosition): IGraphic => {
		let nearestPage: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;

		// Получаем список страниц
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const { y, height } = rootGraphic.getFrameConfiguration();
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от текущей позиции мыши до центра текущей страницы
			const distance = Math.abs(mousePosition.y - pageCenterY);
			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestPage = rootGraphic;
			}
		}

		if (nearestPage === null) {
			throw new ManipulatorError('nearest page not found');
		}

		return nearestPage;
	};
}

export default PictureImporter;
