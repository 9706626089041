import React, { useState } from 'react';
import Loader from 'react-ts-loaders';
import cx from 'classnames';
import { IModule } from '../model/types';
import css from './module-card.module.scss';
import { ModuleControls } from './controls/controls';
import { ModuleTags } from './tags/tags';
import Utils from '../../../../components/Sketch/utils/impl/Utils';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { TemplateModulesModalTabsEnum } from '../../../../widgets/template-modules-modal/model/types';

interface ModuleCardProps {
	module: IModule;
	onDeleteModule?: () => void;
	onBindTag?: (moduleId: string) => void;
	onModuleClick?: ((id: string) => void) | null;
	currentTab: TemplateModulesModalTabsEnum;
	isGlobalLoading: boolean;
	setGlobalLoading: (moduleId: string | null) => void;
}

export const ModuleCard = ({
	module,
	onModuleClick,
	onDeleteModule,
	onBindTag,
	currentTab,
	isGlobalLoading,
	setGlobalLoading,
}: ModuleCardProps) => {
	const {
		name,
		id,
		tags,
		preview,
		description,
	} = module;

	const [isHoverBlockVisible, setIsHoverBlockVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onMouseEnterHandler = () => {
		setIsHoverBlockVisible(true);
	};

	const onMouseLeaveHandler = () => {
		setIsHoverBlockVisible(false);
	};

	const onBindingTag = () => {
		onBindTag?.(id);
	};

	const moduleClick = async () => {
		if (!isGlobalLoading) {
			setGlobalLoading(id);
			setIsLoading(true);
			try {
				await onModuleClick?.(id);
			} catch (error) {
				notificationError('Возникла ошибка при выборе модуля организации!');
				console.error('Произошла ошибка: ', error);
			} finally {
				setIsLoading(false);
				setGlobalLoading(null);
			}
		}
	};

	return (
		<div
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onMouseEnter={!isGlobalLoading ? onMouseEnterHandler : undefined}
			onMouseLeave={!isGlobalLoading ? onMouseLeaveHandler : undefined}
		>
			<div
				className={css.picture}
				onClick={moduleClick}
				role="presentation"
			>
				{currentTab !== TemplateModulesModalTabsEnum.GALLERY && (
					<ModuleControls
						deleteModule={onDeleteModule}
						isHoverBlockVisible={isHoverBlockVisible}
						hideEditIcon
						hideTemplateIcon
					/>
				)}
				<img
					src={Utils.Backend.getSystemImageURI(preview)}
					alt="Картинка модуля"
					className={css.preview}
				/>
				{
					isLoading && <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />
				}
			</div>
			<ModuleTags tags={tags || []} moduleId={id} onBindTag={onBindingTag} />
			<div className={css.title}>{name}</div>
			<div className={css.comment}>
				{description}
			</div>
		</div>
	);
};
