import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrivateTemplateConstructor from './PrivateTemplateConstructor';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import { notificationError } from '../../Notifications/callNotifcation';
import store from '../../../redux/store/store';
import { templatePrivateAPI } from '../../../entities/templates/private/api/api';
import savePDFWithNotifications from '../SketchPDFGenerator/savePDFWithNotifications';
import { useAppDispatch } from '../../../shared/libs/redux';
import { closeAllModals } from '../../../features/ai/model/slice/aiSlice';
import { notifyStructureError } from '../SketchPDFGenerator/PDFNotifications';

export interface PrivateTemplateConstructorProps {
	sketchID: string;
}

const ComponentPrivateTemplateConstructor = (props: PrivateTemplateConstructorProps) => {
	const { sketchID } = props;
	const navigate = useNavigate();
	const refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();
	const dispatch = useAppDispatch();

	let templateName = '';

	const onExitConstructor = () => {
		navigate('/collection/template/storage');
		dispatch(closeAllModals());
	};

	const onSendOffer = () => {
		navigate(`/private-template-present/${sketchID}`);
		dispatch(closeAllModals());
	};

	const onDownloadPDF = async () => {
		store.dispatch(templatePrivateAPI.endpoints.generatePDF
			.initiate({ templateID: sketchID }, { forceRefetch: true }))
			.unwrap()
			.then((res) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (res instanceof Blob || res instanceof Uint8Array) {
					// Создаем URL для скачивания
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const url = window.URL.createObjectURL(res);
					const link = document.createElement('a');
					link.href = url;
					link.download = `${templateName}.pdf`;
					link.click();
					window.URL.revokeObjectURL(url);
				} else {
					console.error('Ответ не является Blob/Uint8Array:', res);
				}
			})
			.catch((err) => {
				console.error('Ошибка при выполнении запроса:', err);
				notifyStructureError(); // Обработка ошибок
			});
	};

	useEffect(() => {
		if (refManipulatorContainer.current === null || refManipulatorContainer.current === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}

		let manipulator: PrivateTemplateConstructor | null = null;

		store.dispatch(templatePrivateAPI.endpoints?.get.initiate({ id: sketchID }))
			.unwrap()
			.then(res => {
				const { template, rootComponent } = res;

				templateName = template.name;

				manipulator = new PrivateTemplateConstructor({
					onSendOffer,
					onDownloadPDF,
					templateData: template,
					structure: rootComponent,
					onBackPage: onExitConstructor,
					manipulatorContainer: refManipulatorContainer.current as HTMLDivElement,
				});
			})
			.catch(() => {
				notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры.');
			});

		return () => {
			manipulator && manipulator.destruct();
		};
	}, []);

	return (
		<div ref={refManipulatorContainer} className="sketch-manipulator private-template-constructor" />
	);
};

// Получает id скетча из параметров
export const TemplateConstructorWithRouter = () => {
	const { id } = useParams();
	if (id === undefined) {
		return <p>error get sketch id</p>;
	}

	return <ComponentPrivateTemplateConstructor sketchID={id} />;
};
