import Graphic from '../Graphic';
import GraphicType from '../GraphicType';
import IGraphicStructure from '../IGraphicStructure';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import { AnySpatialArea } from '../../Types';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import ILineTexture from './ILineTexture';
import LineStyle from './LineStyle';
import LineOrientationType from '../../components/LineOrientationType';

class LineGraphic extends Graphic<ILineTexture> {
	public readonly type: GraphicType = GraphicType.LINE;

	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-line graphic-line';
	private readonly CSS_PROPERTY_THICKNESS_HORIZONTAL = 'border-top-width';
	private readonly CSS_PROPERTY_THICKNESS_VERTICAL = 'border-left-width';
	private readonly CSS_PROPERTY_LONG_VERTICAL = 'height';
	private readonly CSS_PROPERTY_STYLE_HORIZONTAL = 'border-top-style';
	private readonly CSS_PROPERTY_STYLE_VERTICAL = 'border-left-style';
	private readonly CSS_PROPERTY_BACKGROUND_HORIZONTAL = 'border-top-color';
	private readonly CSS_PROPERTY_BACKGROUND_VERTICAL = 'border-left-color';

	protected texture: ILineTexture;

	constructor() {
		super();
		this.graphicElement.className = this.GRAPHIC_CLASS_NAME;
		this.texture = {
			background: '',
			thickness: 1,
			style: LineStyle.SOLID,
			orientation: LineOrientationType.HORIZONTAL,
		};
	}

	public setStructure = (
		fn: (prev: IGraphicStructure<ILineTexture>) => IGraphicStructure<ILineTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const {
			texture, frame, id, offset,
		} = updated;

		if (texture === null) {
			throw new ManipulatorError('figure graphic texture cannot be null');
		}
		if (frame === null) {
			throw new ManipulatorError('figure graphic frame cannot be null');
		}

		this.id = id;
		this.offset = offset;
		this.setFrameConfiguration(_ => frame);
		this.setTexture(_ => texture);
	};

	public getTexture = (): ILineTexture => ({ ...this.texture });

	public setTexture = (
		fn: (prevTexture: ILineTexture) => ILineTexture,
	): void => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);

		this.texture = updatedTexture;
		if (updatedTexture.orientation === LineOrientationType.HORIZONTAL) {
			this.graphicElement.style.removeProperty(this.CSS_PROPERTY_THICKNESS_VERTICAL);
			this.graphicElement.style.removeProperty(this.CSS_PROPERTY_STYLE_VERTICAL);
			this.graphicElement.style.removeProperty(this.CSS_PROPERTY_BACKGROUND_VERTICAL);

			this.graphicElement.style.setProperty(
				this.CSS_PROPERTY_THICKNESS_HORIZONTAL,
				`${updatedTexture.thickness}px`,
			);
			this.graphicElement.style.setProperty(
				this.CSS_PROPERTY_STYLE_HORIZONTAL,
				`${updatedTexture.style}`,
			);
			this.graphicElement.style.setProperty(
				this.CSS_PROPERTY_BACKGROUND_HORIZONTAL,
				`${updatedTexture.background}`,
			);
		} else {
			this.graphicElement.style.removeProperty(this.CSS_PROPERTY_THICKNESS_HORIZONTAL);
			this.graphicElement.style.removeProperty(this.CSS_PROPERTY_STYLE_HORIZONTAL);
			this.graphicElement.style.removeProperty(this.CSS_PROPERTY_BACKGROUND_HORIZONTAL);

			this.graphicElement.style.setProperty(
				this.CSS_PROPERTY_THICKNESS_VERTICAL,
				`${updatedTexture.thickness}px`,
			);
			this.graphicElement.style.setProperty(
				this.CSS_PROPERTY_STYLE_VERTICAL,
				`${updatedTexture.style}`,
			);
			this.graphicElement.style.setProperty(
				this.CSS_PROPERTY_BACKGROUND_VERTICAL,
				`${updatedTexture.background}`,
			);
			this.changeLongVertical();
		}
	};

	public changeLongVertical = () => {
		if (this.texture.orientation === LineOrientationType.VERTICAL) {
			const { height } = this.getFrameConfiguration();
			this.graphicElement.style.setProperty(this.CSS_PROPERTY_LONG_VERTICAL, `${height}px`);
		}
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphicArea = new SpatialGraphicArea(this);
		if (this.texture.orientation === LineOrientationType.HORIZONTAL) {
			this.setMutationPermissions({
				TOP: false,
				RIGHT: true,
				BOTTOM: false,
				LEFT: true,
				LEFT_TOP: false,
				RIGHT_TOP: false,
				RIGHT_BOTTOM: false,
				LEFT_BOTTOM: false,
			});
		} else {
			this.setMutationPermissions({
				TOP: true,
				RIGHT: false,
				BOTTOM: true,
				LEFT: false,
				LEFT_TOP: false,
				RIGHT_TOP: false,
				RIGHT_BOTTOM: false,
				LEFT_BOTTOM: false,
			});
		}

		const resizeAreas = this.getResizeAreas();

		return [graphicArea, ...resizeAreas];
	};

	public getUniqueTexture = (): ILineTexture => this.getTexture();

	protected startMutation = (): void => {
		if (this.type === GraphicType.LINE) return;
		this.graphicElement.contentEditable = 'true';
	};

	protected finishMutation = (): void => {
		this.graphicElement.contentEditable = 'false';
	};
}

export default LineGraphic;
