import React, { useState } from 'react';
import CreateModuleWindow from './CreateModuleWindow';
import { useCreateModule } from '../../../../../features/module/create/api/useCreateModule';
import { useAppSelector } from '../../../../../shared/libs/redux';
import logo from '../../../registration/logo/Logo';

interface CreateModuleWindowContainerProps {
	isModalActive: boolean,
}

const CreateModuleWindowContainer = ({ isModalActive }: CreateModuleWindowContainerProps) => {
	const {
		createTemplateWindowPreviewId: preview,
		createTemplateWindowStructure: structure,
	} = useAppSelector(state => state.moduleWindow);

	const [selectedTagsIds, setSelectedTagsIds] = useState<string[]>([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState<string[]>([]);
	const [selectedLegalEntityId, setSelectedLegalEntityId] = useState<string[]>([]);

	// * При добавлении элемента в список выбранных тегов
	const onAddChoicedTag = (id: string) => setSelectedTagsIds(selectedId => [...selectedId, id]);

	// * При удалении элемента из списка выбранных тегов
	const onRemoveChoicedTag = (id: string) => setSelectedTagsIds(selectedId => selectedId.filter(item => item !== id));

	// * При добавлении элемента в список выбранных юр лиц
	const onAddChoicedLegalEntity = (id: string) => setSelectedLegalEntityId(selectedId => [...selectedId, id]);

	// * При удалении элемента из списка выбранных юр лиц
	const onRemoveChoicedLegalEntity = (id: string) => {
		setSelectedLegalEntityId(choicedId => choicedId.filter(item => item !== id));
	};
	const { onCreateModule } = useCreateModule(
		preview,
		selectedCategoryId,
		selectedTagsIds,
		selectedLegalEntityId,
		structure,
	);

	return (
		<CreateModuleWindow
			onCreateModule={onCreateModule}
			isModalActive={isModalActive}
			setChoicedCategoryId={setSelectedCategoryId}
			onRemoveChoicedLegalEntity={onRemoveChoicedLegalEntity}
			onRemoveChoicedTag={onRemoveChoicedTag}
			onAddChoicedTag={onAddChoicedTag}
			onAddChoicedLegalEntity={onAddChoicedLegalEntity}
			choicedLegalEntityId={selectedLegalEntityId}
			choicedCategoryId={selectedCategoryId}
			choicedTagsIds={selectedTagsIds}
		/>
	);
};

export default CreateModuleWindowContainer;
