import ConfirmModal from '../../../../components/modals/common/confirmModal/ConfirmModal';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import { setIsDeletingCategoryModalActive } from '../../../../redux/reducers/module/categoryReducer';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { useDeleteCategory } from '../api/useDeleteCategory';

export const DeleteCategoryModal = () => {
	const dispatch = useAppDispatch();

	const id = useAppSelector(state => state.category.deletingCategoryId);
	const isActive = useAppSelector(state => state.category.modalsActive.isDeletingCategoryModalActive);

	const handleModal = (value: boolean) => dispatch(setIsDeletingCategoryModalActive(value));

	const {
		isLoading,
		onDeleteCategory,
	} = useDeleteCategory(id, () => handleModal(false));

	return (
		<NewModal
			active={isActive}
			closeFunc={handleModal}
		>
			<ConfirmModal
				disabled={isLoading}
				isModalActive={isActive}
				onDelete={onDeleteCategory}
				onCloseModal={() => handleModal(false)}
				text="Вы действительно хотите удалить категорию без возможности восстановления?"
			/>
		</NewModal>
	);
};
