import ColorexColor from './colors/ColorexColor';
import ColorexColorCircle from './ColorexColorCircle';

class ColorexFrequentlyUsed {
	private readonly CSS_ELEMENT_CLASS_NAME = 'colorex-frequently-block';
	private readonly BASE_COLORS = [ColorexColor.Black, ColorexColor.White, ColorexColor.Transparent];

	private readonly element: HTMLElement;

	private colorCircles: ColorexColorCircle[];
	private readonly colorCirclesBase: ColorexColorCircle[];
	private selectColorEvent: ((color: ColorexColor) => void) | null;
	private selectColorEventBlock: ((color: string[]) => void) | null;
	private beforeSelectColorEvent: ((color: ColorexColor) => void) | null;
	private activeColors: string[];

	constructor(isDisableTransparent: boolean) {
		this.element = document.createElement('div');
		this.element.className = this.CSS_ELEMENT_CLASS_NAME;
		this.colorCircles = [];
		this.colorCirclesBase = [];
		this.selectColorEvent = null;
		this.selectColorEventBlock = null;
		this.beforeSelectColorEvent = null;
		this.renderBaseColors(isDisableTransparent);
		this.activeColors = [];
	}

	public getColorCircles = (): ColorexColorCircle[] => [...this.colorCircles, ...this.colorCirclesBase];

	public setColorsUsed = (colors: string[]): void => {
		this.activeColors = colors;
		this.colorCircles.forEach(circle => {
			if (colors.includes(circle.getColor())) {
				circle.getCircleElement().style.outline = 'solid';
				circle.getCircleElement().style.outlineColor = '#0075ff';
			} else {
				circle.getCircleElement().style.outline = 'none';
			}
		});

		this.colorCirclesBase.forEach(circle => {
			if (colors.includes(circle.getColor())) {
				circle.getCircleElement().style.outline = 'solid';
				circle.getCircleElement().style.outlineColor = '#0075ff';
			} else {
				circle.getCircleElement().style.outline = 'none';
			}
			
			if (circle.getColor() === '#000000' && colors.includes('#000')) {
				circle.getCircleElement().style.outline = 'solid';
				circle.getCircleElement().style.outlineColor = '#0075ff';
			}
		});
	};

	public setColors = (colors: ColorexColor[]) => {
		this.render(colors);
	};

	public addSelectColorEventBlock = (ev: (color: string[]) => void) => {
		this.selectColorEventBlock = ev;
	};

	public addSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.selectColorEvent = ev;
	};

	public addBeforeSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.beforeSelectColorEvent = ev;
	};

	public getElement = (): HTMLElement => this.element;

	private render = (colors: ColorexColor[]) => {
		this.colorCircles.forEach(circle => circle.remove());
		this.colorCircles = [];

		colors.forEach(color => {
			const circle = this.appendCircle(color);
			this.colorCircles.push(circle);
		});
		this.setColorsUsed(this.activeColors);
	};

	private onSelectColorClick = (color: ColorexColor) => {
		if (this.beforeSelectColorEvent !== null) {
			this.beforeSelectColorEvent(color);
		}
		if (this.selectColorEvent !== null) {
			this.selectColorEvent(color);
		}

		if (this.selectColorEventBlock !== null) {
			this.selectColorEventBlock([color.toString()]);
		}

		this.setColorsUsed([color.toString()]);
	};

	private appendCircle = (color: ColorexColor): ColorexColorCircle => {
		const createdCircle = new ColorexColorCircle();
		const circleElement = createdCircle.getElement();

		createdCircle.setColor(color);
		createdCircle.addClickEvent(this.onSelectColorClick.bind(this, color));
		this.element.append(circleElement);

		return createdCircle;
	};

	private renderBaseColors = (isDisableTransparent: boolean) => {
		this.BASE_COLORS.forEach(color => {
			if (isDisableTransparent) {
				if (color === 'transparent') {
					return;
				}
			}
			const circle = this.appendCircle(color);
			this.colorCirclesBase.push(circle);
		});
		if (this.activeColors) {
			this.setColorsUsed(this.activeColors);
		}
	};
}

export default ColorexFrequentlyUsed;
