import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../shared/libs/redux';
import { RootState } from '../../redux/reducers/reducer';
import {
	getDisableKeyObserverFn,
	getEnableKeyObserverFn,
	getIsNeedDisableHotkeys,
	modalsSliceActions
} from '../../redux/reducers/modals';

const useTrackingHotkeys = (active: boolean) => {
	const dispatch = useAppDispatch();

	const isNeedDisableHotkeys = useAppSelector((state: RootState) => getIsNeedDisableHotkeys(state));
	const disableHotKey = useAppSelector((state: RootState) => getDisableKeyObserverFn(state));
	const enableHotKey = useAppSelector(state => getEnableKeyObserverFn(state));

	const setDisableHotkeys = () => {
		dispatch(modalsSliceActions.setIsNeedDisableHotkeys(true));
	};
	const setEnableHotkeys = () => {
		dispatch(modalsSliceActions.setIsNeedDisableHotkeys(false));
	};

	let focusableElements: NodeListOf<Element>;
	useEffect(() => {
		focusableElements = document.querySelectorAll('[data-action="not-hotkeys"]');
		focusableElements.forEach((element) => {
			// eslint-disable-next-line no-return-assign
			element.addEventListener('focus', setDisableHotkeys);
			// eslint-disable-next-line no-return-assign
			element.addEventListener('blur', setEnableHotkeys);
		});
		if (isNeedDisableHotkeys) {
			if (disableHotKey) {
				disableHotKey();
			}
		} else if (!active) {
			if (enableHotKey) {
				enableHotKey();
			}
		}

		return () => {
		focusableElements.forEach((element) => {
			// eslint-disable-next-line no-return-assign
			element.removeEventListener('focus', setDisableHotkeys);
			// eslint-disable-next-line no-return-assign
			element.removeEventListener('blur', setEnableHotkeys);
		});
		}
	}, [active, isNeedDisableHotkeys]);
};

export default useTrackingHotkeys;
