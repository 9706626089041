import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'react-ts-loaders';
import { classNames } from '../../../../../shared/libs/classNames';
import css from './CreateWorkshopModuleModal.module.scss';
import Button, { buttonsTypes } from '../../../../../components/ui/button/button';
import { SelectModuleCategories } from '../select-categories/SelectModuleCategories';
import { SelectModuleTags } from '../select-tags/SelectModuleTags';
import { SetModuleName } from '../set-name/SetModuleName';
import { SelectModuleEntities } from '../select-entities/SelectModuleEntities';
import { useActionCreators } from '../../../../../shared';
import { NewModal } from '../../../../../components/ui/modals/new-modal';
import { useCreateWorkshopModule } from '../../../model/services/create-module/useCreateWorkshopModule';
import { SetModuleDescription } from '../set-description/SetModuleDescription';
import { ReactComponent as OpenArrowIcon } from '../../../assets/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../../assets/close-arrow.svg';
import {
	createWorkshopModuleActions,
	createWorkshopModuleSlice,
} from '../../../model/create-module/slice/createWorkshopModuleSlice';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { RootState } from '../../../../../redux/reducers/reducer';
import { setCreateModuleWindowPreview } from '../../../../../entities/modules/module/model/slice';

interface CreateWorkshopModuleModalProps {
	className?: string,
}

export const CreateWorkshopModuleModal = ({ className }: CreateWorkshopModuleModalProps) => {
	const dispatch = useAppDispatch();

	const isOpen = useSelector(createWorkshopModuleSlice.selectors.getIsWindowOpen);
	const selectedCategories = useSelector(createWorkshopModuleSlice.selectors.getSelectedCategoriesIds);
	const name = useSelector(createWorkshopModuleSlice.selectors.getName);
	/* Потому что превью тянем из общего слайса для модульного окна модулей без запроса на сервер */
	const preview = useSelector((state: RootState) => state.moduleWindow.createTemplateWindowPreview);
	const selectedTags = useSelector(createWorkshopModuleSlice.selectors.getSelectedTagsIds);
	const selectedLegalEntities = useSelector(createWorkshopModuleSlice.selectors.getSelectedLegalEntitiesIds);
	const isBtnActive = useSelector(createWorkshopModuleSlice.selectors.getIsBtnActive);
	// Состояние загрузки изображения
	const [isImageLoading, setIsImageLoading] = useState(true);

	const actions = useActionCreators(createWorkshopModuleActions);

	const selectTag = (id: string) => {
		actions.addSelectedTagId(id);
	};

	const unselectTag = (id: string) => {
		actions.removeSelectedTag(id);
	};

	const selectLegalEntity = (id: string) => {
		actions.addSelectedLegalEntity(id);
	};

	const unselectLegalEntity = (id: string) => {
		actions.removeSelectedLegalEntity(id);
	};

	const handleName = (value: string) => {
		actions.setName(value);
	};

	const selectCategory = (ids: string[]) => {
		actions.setSelectedCategoriesIds(ids);
	};

	const unselectCategory = (id: string) => {
		actions.removeSelectedCategoryId(id);
	};

	const handleWindow = (value: boolean) => {
		actions.setIsWindowOpen(value);
	};

	const { onCreateModule, requestData } = useCreateWorkshopModule();

	const [showMore, setShowMore] = useState(false);

	// Обработчик для события загрузки изображения
	const handleImageLoad = () => {
		setIsImageLoading(false);
	};

	const handleImageError = () => {
		setIsImageLoading(false);
	};

	useEffect(() => {
		if (!isOpen) {
			dispatch(createWorkshopModuleActions.cleanStateCreatingData());
			dispatch(setCreateModuleWindowPreview(''));
		}
	}, [isOpen]);

	return (
		<NewModal active={isOpen} closeFunc={handleWindow}>
			<div className={classNames(css.createTemplateWrapper, {}, [className])}>
				{/* Лоадер, если изображение еще загружается */}
				{isImageLoading && <Loader className={css.loader} type="ring" color="#8e9db0" size={148} />}

				{/* <img */}
				{/*	src={Utils.Backend.getUserImageURI(preview)} */}
				{/*	alt="module" */}
				{/*	className={css.preview} */}
				{/* /> */}
				{preview
					&& (
						<img
							src={preview}
							alt="Base64"
							className={css.preview}
							onLoad={handleImageLoad}
							onError={handleImageError}
							// Скрываем изображение, пока оно не загружено
							style={{ display: isImageLoading ? 'none' : 'block' }}
						/>
					)}
				<div className={css.wrapper}>
					<div className={css.content}>
						<div className={css.title}>
							Создать модуль
						</div>
						<SetModuleName name={name} handleName={handleName} />
						<SelectModuleCategories
							selectCategory={selectCategory}
							selectedCategories={selectedCategories}
							unselectCategory={unselectCategory}
						/>
						<SelectModuleTags
							selectedTags={selectedTags}
							selectTag={selectTag}
							unselectTag={unselectTag}
						/>
						<div
							className={css.more}
							onClick={() => setShowMore(!showMore)}
							role="presentation"
						>
							<div className={css.text}>Дополнительно</div>
							{
								showMore
									? <OpenArrowIcon />
									: <CloseArrowIcon />
							}
						</div>
						{
							showMore && (
								<>
									<SelectModuleEntities
										selectedLegalEntityId={selectedLegalEntities}
										selectLegalEntity={selectLegalEntity}
										unselectLegalEntity={unselectLegalEntity}
									/>
									<SetModuleDescription />
								</>
							)
						}
						<Button
							text="Создать модуль"
							onClick={onCreateModule}
							buttonType={buttonsTypes.BRIGHT}
							cssClass={css.btn}
							inActive={isBtnActive && !requestData.isLoading}
						/>
					</div>
				</div>
			</div>
		</NewModal>
	);
};
