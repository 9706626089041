import ComponentCloner from './component-cloner/ComponentCloner';
import IDescartesPosition from '../utils/IDescartesPosition';
import IComponent from '../components/IComponent';
import SketchComponentType from '../components/SketchComponentType';
import TextComponent from '../components/text/TextComponent';
import { notificationError } from '../../Notifications/callNotifcation';
import TableComponent from '../components/table/TableComponent';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import IClonerClipboard from './component-cloner/IClonerClipboard';
import ComponentBuilder from '../factories/ComponentBuilder';
import IGraphicFactory from '../factories/graphic/IGraphicFactory';
import IComponentFactory from '../factories/component/IComponentFactory';
import IGraphic from '../graphic/IGraphic';
import ComponentInjector from '../component-injector/ComponentInjector';
import { Token, TokenFormat, TokenType } from './mext/parser';
import { FontFamily, FontSize, TextAlign } from './mext/editor/types';
import MousePositionObserver from '../utils/observers/MousePositionObserver';
import Dependent from '../utils/dependent/Dependent';
import HTMLTableImporter from './html-import-table/HTMLTableImporter';
import IComponentTree from '../component-tree/IComponentTree';
import HTMLTableReplacer from './html-replace-table/HTMLTableReplacer';
import TextImporter from './import-text/TextImporter';
import SketchStructureStabilizer from './mutation-observer/SketchStructureStabilizer';
import PictureImporter from './import-picture/PictureImporter';
import { MimeType } from '../Types';
import PictureComponent from '../components/picture/PictureComponent';
import PictureReplacer from './replace-picture/PictureReplacer';

interface IPasteCoordinatorDependencies {
	textImporter: TextImporter;
	componentTree: IComponentTree;
	graphicFactory: IGraphicFactory;
	componentCloner: ComponentCloner;
	pictureImporter: PictureImporter;
	pictureReplacer: PictureReplacer;
	componentFactory: IComponentFactory;
	componentInjector: ComponentInjector;
	mouseObserver: MousePositionObserver;
	HTMLTableImporter: HTMLTableImporter;
	HTMLTableReplacer: HTMLTableReplacer;
	sketchStabilizer: SketchStructureStabilizer;
}

interface IClipboardBlob {
	type: MimeType;
	blob: Blob;
	text: string;
}

class PasteCoordinator extends Dependent<IPasteCoordinatorDependencies> {
	private readonly PNG = 'image/png';
	private readonly JPEG = 'image/jpeg';
	private readonly HTML = 'text/html';
	private readonly COMPONENT = 'web wakadoo/fragment';

	public pasteAtPosition = (focusComponents: IComponent[] | null, position: IDescartesPosition) => {
		this.onPaste(focusComponents, position);
	};

	public pasteInComponents = (focusComponents: IComponent[] | null) => {
		this.onPaste(focusComponents);
	};

	public pasteFromBuffer = () => {
		this.onPaste();
	};

	/**
	 * Читает данные из буфера обмена.
	 */
	private readInBuffer = async (): Promise<IClipboardBlob[]> => {
		const bufferData = await navigator.clipboard.read();
		const clipboardBlobs: IClipboardBlob[] = [];

		const types = ['image/png', 'image/jpeg', 'text/html', 'web wakadoo/fragment', 'text/plain'];

		for (const item of bufferData) {
			for (const type of types) {
				if (item.types.includes(type)) {
					// eslint-disable-next-line no-await-in-loop
					const blob = await item.getType(type);
					// eslint-disable-next-line no-await-in-loop
					const blobText = await blob.text();
					clipboardBlobs.push({ type, blob, text: blobText } as IClipboardBlob);
				}
			}
		}

		return clipboardBlobs;
	};

	/**
	 * Координирует данные для последующей вставки.
	 * @param focusComponents - компоненты, в которые будет производиться вставка текста.
	 * @param position - позиция для вставки компонента.
	 */
	private onPaste = async (focusComponents?: IComponent[] | null, position?: IDescartesPosition) => {
		const clipboardBlobs = await this.readInBuffer();
		if (clipboardBlobs.length === 0) {
			return;
		}

		// Обрабатываем только первый блоб из списка
		const imageBlob = this.getBlobByTypes(clipboardBlobs, [this.PNG, this.JPEG]);
		const htmlBlob = this.getBlobByTypes(clipboardBlobs, [this.HTML]);
		const component = this.getBlobByTypes(clipboardBlobs, [this.COMPONENT]);

		if (htmlBlob && this.isTable(htmlBlob.text)) {
			this.pasteTableComponent(htmlBlob.text);
			return;
		}

		if (imageBlob) {
			await this.pastePictureComponent(imageBlob.blob);
			return;
		}

		if (component) {
			await this.pasteJSONComponent(component.text, position);
			return;
		}
		
		// Если есть фокусируемые компоненты и они в режиме редактирования, вставляем текст в них;
		// в противном случае создаем новый компонент и вставляем текст в него.
		if (htmlBlob) {
			const plainText = this.extractPlainText(htmlBlob.text);
			const tokens = this.convertBlobTextToTokens(plainText);
			if (focusComponents && this.canEditFocusedComponent(focusComponents)) {
				this.pasteText(focusComponents[0], tokens);
			} else {
				this.dependencies.textImporter.importText(tokens);
			}
		}
	};

	private getBlobByTypes = (blobs: IClipboardBlob[], types: MimeType[]):
		IClipboardBlob => blobs.filter(blob => types.includes(blob.type))[0];

	private isPicture = (clipboardBlob: IClipboardBlob): boolean => {
		const { type, text } = clipboardBlob;
		const hasImgTag = /<img[\S\s]*?>/i.test(text);
		const hasImagePng = type === this.PNG;
		const hasImageJpeg = type === this.JPEG;
		return hasImgTag || hasImagePng || hasImageJpeg;
	};

	private isTable = (text: string): boolean => {
		const hasTableTag = /<table[\S\s]*?>/i.test(text);
		const hasRowTag = /<tr[\S\s]*?>/i.test(text);
		const hasCellTag = /<td[\S\s]*?>/i.test(text);
		return hasTableTag && hasRowTag && hasCellTag;
	};
	
	private pastePictureComponent = async (blob: Blob) => {
		const pictures = this.dependencies.componentTree
			.getUniformFocusComponents<PictureComponent>(SketchComponentType.PICTURE);
		if (pictures !== null) {
			await this.dependencies.pictureReplacer.replacePicture(pictures, blob);
		} else {
			await this.dependencies.pictureImporter.importPicture(blob);
		}
	};

	private pasteTableComponent(text: string): void {
		const tables = this.dependencies.componentTree
			.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables !== null) {
			this.dependencies.HTMLTableReplacer.replaceTable(tables, text);
		} else {
			this.dependencies.HTMLTableImporter.importTable(text);
		}
	}

	private async pasteJSONComponent(blobText: string, position?: IDescartesPosition): Promise<void> {
		const clonerClipboard = this.pullClipBoard(blobText);
		const firstGraphic = this.getBufferFirstGraphic(blobText);
		const pastePosition = position || this.dependencies.mouseObserver.getCurrentPosition();
		this.dependencies.sketchStabilizer.startUserAction();
		await this.dependencies.componentCloner.pasteAtPosition(pastePosition, firstGraphic, clonerClipboard);
		this.dependencies.sketchStabilizer.stopUserAction();
	}

	private canEditFocusedComponent(focusComponents: IComponent[] | null): boolean {
		return focusComponents !== null && focusComponents !== undefined && focusComponents[0].isEnableEditMode();
	}

	private extractPlainText = (html: string) => {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || '';
	};

	private pasteText(component: IComponent, tokens: Token[]): void {
		this.pasteTokensToComponent(component, tokens);
	}

	/**
	 * Достает из буфера обмена скопированные структуры компонентов и указатель на ID скетча,
	 * где последний раз происходила вставка.
	 * @param bufferData - данные из буфера обмена
	 */
	private pullClipBoard = (bufferData: string): IClonerClipboard => {
		const plainText = bufferData.substring(5);
		let clonerClipboard: IClonerClipboard;

		try {
			clonerClipboard = JSON.parse(plainText) as IClonerClipboard;
		} catch (error) {
			throw new ManipulatorError(error as string);
		}

		return clonerClipboard;
	};

	/**
	 * Извлекает из буфера обмена скопированный токен текста
	 * @return возвращает токен текста в виде строки или массива токенов.
	 */
	private convertBlobTextToTokens = (blobText: string): Token[] => {
		let tokens: Token[] = [];
		try {
			// если текст был скопирован из редактора wakadoo, то выполниться это условие
			if (blobText.startsWith('TEXT:')) {
				const blobTextSubstring = blobText.substring(5);
				tokens = JSON.parse(blobTextSubstring) as Token[];
			} else {
				const newToken: Token[] = [];
				newToken.push({
					fontFamily: FontFamily.Default,
					fontSize: FontSize.Pt14,
					color: '#000000',
					textAlign: TextAlign.LEFT,
					type: TokenType.Text,
					value: blobText,
					format: TokenFormat.None,
					lineHeight: 1,
					sticky: false,
				});
				const newTokenJson = JSON.stringify(newToken);
				tokens = JSON.parse(newTokenJson) as Token[];
			}
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			console.log(error.message);
		}
		return tokens;
	};

	/**
	 * Достает из буфера обмена первую графику первого скопированного компонента для расчета позиции при вставке.
	 * @param bufferData - данные из буфера обмена
	 */
	private getBufferFirstGraphic = (bufferData: string): IGraphic => {
		const plainText = bufferData.substring(5);
		let clonerClipboard: IClonerClipboard;

		try {
			clonerClipboard = JSON.parse(plainText) as IClonerClipboard;
		} catch (error) {
			throw new ManipulatorError(error as string);
		}

		if (clonerClipboard === null) {
			throw new ManipulatorError('clonerClipboard is null');
		}
		if (clonerClipboard.structures.length === 0) {
			throw new ManipulatorError('buffer is empty');
		}

		const builder = new ComponentBuilder();
		builder.connectDependencies({
			graphicFactory: this.dependencies.graphicFactory,
			componentFactory: this.dependencies.componentFactory,
		});
		builder.injectDependencies();
		builder.scanStructure(clonerClipboard.structures[0]);

		const component = builder.getComponent();

		return component.getGraphics()[0];
	};

	/**
	 * Запускает событие вставки текста в компонент.
	 * @param component Компонент, в который вставляется текст.
	 */
	private pasteTokensToComponent = (component: IComponent, tokens: Token[]) => {
		if (component.type === SketchComponentType.TEXT) {
			const editors = (component as TextComponent).getEditors();
			if (!editors[0].isActiveEditable()) {
				notificationError('Копирование текста', 'Текст не в режиме редактирования');
			}
			editors[0].onPaste(tokens);
		}
		if (component.type === SketchComponentType.TABLE
			&& (component as TableComponent).getFocusCellContexts()) {
			const focusCells = (component as TableComponent).getFocusCellContexts();
			if (focusCells === null) return;
			const editors = focusCells.map((cell) => cell.getEditor());
			const editableEditor = editors.find((editor) => editor.isActiveEditable());
			if (editableEditor === undefined) {
				notificationError('Копирование текста', 'Нет ячейки в режиме редактирования');
				throw new ManipulatorError('not have editable cell');
			}
			editableEditor.onPaste(tokens);
		}
	};
}

export default PasteCoordinator;
