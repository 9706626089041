import { CSSTransition } from 'react-transition-group';
import React, {
	MouseEventHandler, useRef, useState,
} from 'react';
import Loader from 'react-ts-loaders';
import cx from 'classnames';
import { ReactComponent as GalleryIcon }
	from '../../../../components/pages/integrator-panel/templates/images/template-gallery.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ISimpleWorkshopModule } from '../../model/types/types';
import Utils from '../../../../components/Sketch/utils/impl/Utils';
import { popUpTransitionClasses } from '../../../../styles/transitionStyles/transitionStyles';
import { ModuleTag } from '../../../modules/tag/ui/module-tag';
import css from './WorkshopModuleCard.module.scss';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { useIsMounted } from '../../../../hooks/lifecycle/useIsMounted';
import { TemplateInfoIcon } from '../../../../shared/ui';

interface WorkshopModuleCardProps {
	className?: string;
	data?: ISimpleWorkshopModule;
	onModuleClick?: ((moduleId: string) => void) | null;
	onDeleteModule?: (moduleId: string) => void;
	onUnbindTag?: (moduleId: string, tagId: number) => void;
	onBindTag?: (moduleId: string) => void;
	isGlobalLoading: boolean;
	setGlobalLoading: (moduleId: string | null) => void;
}

export const WorkshopModuleCard = ({
	className,
	data,
	onModuleClick,
	onDeleteModule,
	onUnbindTag,
	onBindTag,
	isGlobalLoading,
	setGlobalLoading,
}: WorkshopModuleCardProps) => {
	const [isHoverBlockVisible, setIsHoverBlockVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isMounted = useIsMounted();

	const hiddenBlockRef = useRef<HTMLDivElement>(null);

	if (!data) return null;

	const {
		id, preview, tags, description, name, gallery,
	} = data;

	const onMouseEnterHandler = () => setIsHoverBlockVisible(true);
	const onMouseLeaveHandler = () => setIsHoverBlockVisible(false);

	const moduleClick = async () => {
		if (!isGlobalLoading) {
			setGlobalLoading(id);
			setIsLoading(true);
			try {
				await onModuleClick?.(id);
			} catch (error) {
				notificationError('Возникла ошибка при выборе модуля!');
				console.error('Произошла ошибка: ', error);
			} finally {
				if (isMounted.current) {
					setIsLoading(false);
					setGlobalLoading(null);
				}
			}
		}
	};

	const handleDelete: MouseEventHandler<HTMLDivElement> = (e) => {
		e.stopPropagation();
		onDeleteModule?.(id);
	};

	const handleBindTag = () => onBindTag?.(id);

	return (
		<div
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onMouseEnter={!isGlobalLoading ? onMouseEnterHandler : undefined}
			onMouseLeave={!isGlobalLoading ? onMouseLeaveHandler : undefined}
		>
			<div
				className={css.picture}
				onClick={moduleClick}
				role="presentation"
			>
				{
					gallery && (
						<TemplateInfoIcon type="bright">
							<GalleryIcon />
						</TemplateInfoIcon>
					)
				}
				<CSSTransition
					timeout={200}
					unmountOnExit
					nodeRef={hiddenBlockRef}
					in={isHoverBlockVisible}
					classNames={popUpTransitionClasses}
				>
					<div className={css.controls} ref={hiddenBlockRef}>
						<div
							className={css.item}
							role="presentation"
							onClick={handleDelete}
						>
							<DeleteIcon />
						</div>
					</div>
				</CSSTransition>
				<img
					src={Utils.Backend.getSystemImageURI(preview)}
					alt="Картинка модуля"
					className={css.preview}
				/>
				{isLoading && <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />}
			</div>
			<div className={css.tags_block}>
				{tags?.map(tag => {
					const unbindTag = () => onUnbindTag?.(id, tag.id);
					return <ModuleTag key={tag.id} tag={tag} onUnbind={unbindTag} styles={css.tag} />;
				})}
				<PlusIcon className={css.plus} onClick={handleBindTag} />
			</div>
			<div className={css.title}>{name}</div>
			<div className={css.comment}>{description}</div>
		</div>
	);
};
