import React from 'react';
import { useAppSelector } from '../../../../../../../../../shared/libs/redux';
import { NewModal } from '../../../../../../../../ui/modals/new-modal';
import { useMoveTemplates } from '../../../api/useMoveTemplates';
import { SelectMoveTeamContent } from './select-move-team-content/select-move-team-content';
import { ITeamItem } from '../../../../../../../../../entities/team/api/types';

interface MoveMyTemplateModalProps {
	currentTeams: ITeamItem[] | undefined,
}

export const MovePrivateTemplateModal = ({
	currentTeams,
}: MoveMyTemplateModalProps) => {
	const {
		setActive,
		isLoading,
		moveTemplate,
	} = useMoveTemplates();

	const movingItemsCount = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList)?.length;

	const isActive = useAppSelector(state => state.privateTemplate.isChoicePlaceToMoveModalActive);

	return (
		<NewModal active={isActive} closeFunc={setActive}>
			<SelectMoveTeamContent
				teams={currentTeams}
				isDataLoading={isLoading}
				onMoveTemplates={moveTemplate}
				movingCount={movingItemsCount || 1}
				isActive={isActive}
			/>
		</NewModal>
	);
};
