import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import IComponent from '../../components/IComponent';
import ManipulatorError from '../manipulator-error/ManipulatorError';
import API from '../../../../api/API';
import ColorexColor from '../../../../libs/colorex/colors/ColorexColor';
import { compressImage } from './CompressImage';
import { setCreateModuleWindowPreviewId } from '../../../../entities/modules/module/model/slice';
import store from '../../../../redux/store/store';

class Generate {
	public ComponentPreview = async (component: IComponent, showPreview: (fileID: string) => void) => {
		const firstGraphic = component.getFirstGraphic();
		if (firstGraphic === null) {
			throw new ManipulatorError('first graphic not found');
		}

		const graphicElement = firstGraphic.getFrameElement();
		graphicElement.style.position = 'absolute';
		graphicElement.style.left = '-5000px';
		graphicElement.style.top = '-5000px';

		document.body.append(graphicElement);

		setTimeout(() => {
			html2canvas(graphicElement, {
				backgroundColor: ColorexColor.Transparent,
				scale: 1,
			})
				.then(canvas => canvas.toDataURL('image/png', 1.0))
				.then(previewBytes => {
					/* previewBytes - это сейчас строка base64. Сразу её запишем в slice чтобы не делать
					 лишних запросов, но потом нам нужен будет id, который сгенерирует сервер после
					 отправки ему этого превью (ниже). Этот id будет отправлен на сервер при создании
					 модуля. */
					showPreview(previewBytes);

					return compressImage(previewBytes);
				})
				.then(previewBytes => {
					const blob = new Blob([previewBytes]);
					graphicElement.remove();

					return API.file.createSystemFile({
						name: `preview-${new Date().toISOString()}_${this.UUID4()}`,
						bytes: previewBytes,
					}, blob.size);
				})
				.then(previewId => {
					/* Понадобится для передачи данных при создании модуля */
					store.dispatch(setCreateModuleWindowPreviewId(previewId.id));
				})
				.catch(error => {
					graphicElement.remove();
					throw new ManipulatorError(error.message);
				});
		}, 0);
	};

	public UUID4 = (): string => uuidv4();
}

export default Generate;
