import ConstructorInjector, { IComponentInjectorDependencies } from '../ConstructorInjector';
import LineInjectArea from './LineInjectArea';
import CSSCursor from '../../cursor/CSSCursor';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import LineComponent from '../../components/line/LineComponent';
import LineGraphic from '../../graphic/line/LineGraphic';
import GraphicType from '../../graphic/GraphicType';
import IComponentStructure from '../../components/IComponentStructure';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import ILineTexture from '../../graphic/line/ILineTexture';
import LineStyle from '../../graphic/line/LineStyle';
import LineOrientationType from '../../components/LineOrientationType';

interface IFigureDefaultStructureProps {
	x: number,
	y: number,
	width: number,
	height: number,
	componentOffset: number,
	orientation: LineOrientationType,
}

export interface ILineInjectorDependencies extends IComponentInjectorDependencies {}

/**
 * Инжектор для добавления фигур в структуру.
 */
class LineInjector extends ConstructorInjector<ILineInjectorDependencies> {
	private readonly DEFAULT_WIDTH = 200;
	private readonly DEFAULT_HEIGHT = 3;
	private readonly MIN_CUSTOM_WIDTH = 1;
	private readonly MIN_CUSTOM_HEIGHT = 1;

	private readonly lineInjectArea: LineInjectArea;

	constructor() {
		super();
		this.lineInjectArea = new LineInjectArea();

		this.addPostInjectDependenciesListener(dependencies => {
			const rootElement = dependencies.componentTree.getWorkAreaElement();
			this.lineInjectArea.connectDependencies({
				rootElement,
				mousePositionObserver: this.dependencies.mousePositionObserver,
			});
			this.lineInjectArea.injectDependencies();
		});
	}

	/**
	 * Запускает инжекцию линии.
	 */
	public run = (): void => {
		// Включаем режим вставки компонента
		this.dependencies.cursorView.enableComponentInjectMode();
		// Устанавливаем курсор в режим определения области
		this.dependencies.cursorView.setCursor(CSSCursor.CROSSHAIR);
		// Устанавливаем флаг процесса вставки
		this.isProcess = true;
		// Отключаем готовность к вставке (для реагирования на `onMouseUp`)
		this.setNotReadyInject();
	};

	/**
	 * Инжектирует фигуру в структуру.
	 */
	public inject = (): void => {
		const isReady = this.isReadyInject();
		if (!isReady) {
			throw new ManipulatorError('injector not ready inject component');
		}

		const parentComponent = this.dependencies.componentTree.getRootComponent();

		// Получаем область вставки фигуры
		const area = this.lineInjectArea.getArea();
		const injectParams = this.calculateAreaComponentInjectionParams(area);
		const isDefaultSize = area.height === 0 || area.width === 0;
		let width: number;
		let height: number;
		if (isDefaultSize) {
			width = this.DEFAULT_WIDTH;
			height = this.DEFAULT_HEIGHT;
		} else {
			const heightSmallerThenMin = area.height < this.MIN_CUSTOM_HEIGHT;
			const widthSmallerThenMin = area.width < this.MIN_CUSTOM_WIDTH;

			if (heightSmallerThenMin && widthSmallerThenMin) {
				height = this.MIN_CUSTOM_HEIGHT;
				width = this.MIN_CUSTOM_WIDTH;
			} else if (widthSmallerThenMin) {
				height = area.height;
				width = this.MIN_CUSTOM_WIDTH;
			} else if (heightSmallerThenMin) {
				height = this.MIN_CUSTOM_HEIGHT;
				width = area.width;
			} else {
				height = area.height;
				width = area.width;
			}
		}

		const x = area.x - injectParams.x;
		const y = area.y - injectParams.y;

		const structure = this.getDefaultStructure({
			x,
			y,
			width,
			height,
			componentOffset: injectParams.componentOffset,
			orientation: width > height ? LineOrientationType.HORIZONTAL : LineOrientationType.VERTICAL,
		});

		let lineComponent: LineComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			lineComponent = tools.componentFactory.createComponent<LineComponent>(structure);

			structure.graphics?.forEach(graphicStructure => {
				const graphic = tools.graphicFactory.createGraphic<LineGraphic>(GraphicType.LINE, lineComponent);
				if (width > height) {
					graphicStructure.texture.orientation = LineOrientationType.HORIZONTAL;
				} else {
					graphicStructure.texture.orientation = LineOrientationType.VERTICAL;
				}
				graphic.setStructure(() => graphicStructure);
				lineComponent.appendGraphic(graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, lineComponent);

			this.callPostInjectListeners(lineComponent);
		});

		this.stop();
		this.callSingleUseInjectListeners(lineComponent!);
	};

	/**
	 * Останавливает инжекцию фигуры.
	 */
	public stop = (): void => {
		this.dependencies.cursorView.disableComponentInjectMode();
		this.isProcess = false;
		this.lineInjectArea.clearArea();
	};

	/**
	 * Обработчик события нажатия кнопки мыши.
	 */
	public override onMouseDown = () => {
		const position = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.lineInjectArea.start(position);
	};

	/**
	 * Обработчик события отпускания кнопки мыши.
	 */
	public override onMouseUp = () => {
		this.setReadyInject();
		this.inject();
		this.lineInjectArea.stop();
	};

	/**
	 * Возвращает структуру линии по умолчанию.
	 */
	protected getDefaultStructure = (props: IFigureDefaultStructureProps): IComponentStructure<null> => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		return {
			id: componentId,
			type: SketchComponentType.LINE,
			offset: props.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.LINE,
					frame: {
						x: props.orientation === LineOrientationType.HORIZONTAL ? props.x : props.x + props.width / 2,
						y: props.orientation === LineOrientationType.HORIZONTAL ? props.y + props.height / 2 : props.y,
						width: props.orientation === LineOrientationType.HORIZONTAL ? props.width : this.DEFAULT_HEIGHT,
						height: props.orientation === LineOrientationType.HORIZONTAL
							? this.DEFAULT_HEIGHT : props.height,
						rotate: 0,
						layer: 100,
					},
					offset: 0,
					texture: {
						background: '#000000',
						thickness: this.DEFAULT_HEIGHT,
						style: LineStyle.SOLID,
						orientation: props.orientation,
					} as ILineTexture,
				},
			],
			texture: null,
			components: null,
		};
	};
}

export default LineInjector;
