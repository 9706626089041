import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModule, IModulesState } from './types';
import { AnyComponentStructure } from '../../../../components/Sketch/Types';

const initialState: IModulesState = {
	onGalleryModuleClick: null,
	onEntityModuleClick: null,
	modules: null,
	totalCount: 0,
	bindingModuleId: null,
	createTemplateWindowPreview: '',
	createTemplateWindowPreviewId: '',
	createTemplateWindowStructure: null,
	modalsActive: {
		isModuleWindowOpen: false,
		isCreateTableModalOpen: false,
		isEditModuleModalActive: false,
		isCreateModuleWindowOpen: false,
		isBindingTagModuleModalActive: false,
	},
	currentCategoriesIds: [],
	currentTagsIds: [],
	onCreateTable: null,
	onCloseTableModal: null,
	onCloseModuleModal: null,
};

export const moduleWindow = createSlice({
	name: 'moduleWindow',
	initialState,
	selectors: {
		getIsTemplateModuleActive: (state) => state.modalsActive.isEditModuleModalActive,
		getEntityModuleClick: (state) => state.onEntityModuleClick,
		getGalleryModuleClick: (state) => state.onGalleryModuleClick,
	},
	reducers: {
		setCreateTableFunc: (state, action: PayloadAction<(
			columnCount: number,
			rowCount: number,
		) => void>) => {
			state.onCreateTable = action.payload;
		},
		setCloseTableModalFunc: (state, action: PayloadAction<() => void>) => {
			state.onCloseTableModal = action.payload;
		},
		setCloseModuleModalFunc: (state, action: PayloadAction<() => void>) => {
			state.onCloseModuleModal = action.payload;
		},
		setWindowModuleOpen: (state) => {
			state.modalsActive.isModuleWindowOpen = true;
		},
		setWindowModuleClose: (state) => {
			state.modalsActive.isModuleWindowOpen = false;
		},
		setOnEntityModuleClick: (state, action: PayloadAction<(moduleId: string) => void>) => {
			state.onEntityModuleClick = action.payload;
		},
		setOnGalleryModuleClick: (state, action: PayloadAction<(moduleId: string) => void>) => {
			state.onGalleryModuleClick = action.payload;
		},
		setModules: (state, action: PayloadAction<IModule[]>) => {
			state.modules = action.payload;
		},
		setTotalCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		setCreateModuleWindowOpen: (state) => {
			state.modalsActive.isCreateModuleWindowOpen = true;
		},
		setCreateModuleWindowClose: (state) => {
			state.modalsActive.isCreateModuleWindowOpen = false;
		},
		/* Использовать именно для превью (картинки в base64) */
		setCreateModuleWindowPreview: (state, action: PayloadAction<string>) => {
			state.createTemplateWindowPreview = action.payload;
		},
		/* Использовать для id превью */
		setCreateModuleWindowPreviewId: (state, action: PayloadAction<string>) => {
			state.createTemplateWindowPreviewId = action.payload;
			console.log(action.payload);
		},
		setCreateTemplateWindowStructure: (state, action: PayloadAction<AnyComponentStructure>) => {
			state.createTemplateWindowStructure = action.payload;
		},
		setIsEditModuleModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isEditModuleModalActive = action.payload;
		},
		setIsCreateTableModalOpen: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isCreateTableModalOpen = action.payload;
		},
		setIsBindingTagModuleModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isBindingTagModuleModalActive = action.payload;
		},
		setBindingModuleId: (state, action: PayloadAction<string | null>) => {
			state.bindingModuleId = action.payload;
		},
		setCurrentCategoriesIds: (state, action: PayloadAction<number[]>) => {
			state.currentCategoriesIds = action.payload;
		},
		setCurrentTagsIds: (state, action: PayloadAction<number[]>) => {
			state.currentTagsIds = action.payload;
		},
	},
});

export const {
	setModules,
	setOnEntityModuleClick,
	setOnGalleryModuleClick,
	setTotalCount,
	setCloseTableModalFunc,
	setCloseModuleModalFunc,
	setCreateTableFunc,
	setCurrentTagsIds,
	setBindingModuleId,
	setWindowModuleOpen,
	setWindowModuleClose,
	setCurrentCategoriesIds,
	setCreateModuleWindowOpen,
	setIsCreateTableModalOpen,
	setCreateModuleWindowClose,
	setIsEditModuleModalActive,
	setCreateModuleWindowPreview,
	setIsBindingTagModuleModalActive,
	setCreateTemplateWindowStructure,
	setCreateModuleWindowPreviewId,
} = moduleWindow.actions;

export const { actions: moduleWindowActions } = moduleWindow;

export default moduleWindow.reducer;
