import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import TestServerNotification from "./components/TestServerNotification";
import { App } from "./app/App";
import { setupListeners } from "@reduxjs/toolkit/query";
import { savePrivateTemplatePDF } from './components/SketchManipulators/global/savePrivateTemplatePDF';
import { saveOfferPDF } from './components/SketchManipulators/global/saveOfferPDF';

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_ENV = process.env.REACT_APP_ENVIRONMENT_SERVER;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.savePrivateTemplatePDF = savePrivateTemplatePDF;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.saveOfferPDF = saveOfferPDF;

setupListeners(store.dispatch);

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter>
				<TestServerNotification/>
				<App/>
			</BrowserRouter>
		</React.StrictMode>
	</Provider>,
	document.getElementById("root")
);

