// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';

// Объявление типов для глобального объекта ym (Яндекс-Метрика)
declare global {
	interface Window {
		ym: any;
	}
}

export const YandexMetric: React.FC = () => {
	useEffect(() => {
		// Инициализация метрики
		(function (m: Window, e: Document, t: string, r: string, i: string, k?: any, a?: any) {
			m[i] = m[i] || function () {
				// eslint-disable-next-line prefer-rest-params
				(m[i].a = m[i].a || []).push(arguments);
			};
			m[i].l = 1 * new Date();
			for (let j = 0; j < document.scripts.length; j++) {
				if (document.scripts[j].src === r) {
					return;
				}
			}
			k = e.createElement(t);
			// eslint-disable-next-line prefer-destructuring
			a = e.getElementsByTagName(t)[0];
			k.async = 1;
			k.src = r;
			a.parentNode.insertBefore(k, a);
		}(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'));

		// Инициализация метрики с нужными параметрами
		window.ym(96920835, 'init', {
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
			webvisor: true,
		});

		// Очистка (необязательно, можно убрать, если не нужно)
		return () => {
			// Тут можно добавить очистку, если это нужно
		};
	}, []);

	return (
		<noscript>
			<div>
				<img 
					src="https://mc.yandex.ru/watch/96920835" 
					style={{ position: 'absolute', left: '-9999px' }} 
					alt="" 
				/>
			</div>
		</noscript>
	);
};
