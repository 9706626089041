import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import css from './TemplateModulesModal.module.scss';
import { classNames } from '../../../../shared/libs/classNames';
import { TemplateModulesModalTabs } from '../tabs/TemplateModulesModalTabs';
import { SettingsButton } from '../../../../shared/ui';
import { TemplateGalleryModules } from '../gallery-modules/TemplateGalleryModules';
import { TemplateEntityModules } from '../entity-modules/TemplateEntityModules';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import { templateModuleActions, templateModuleSelectors } from '../../../../entities/template-module';
import { ModuleTagBindModal } from '../../../module-tag-bind';
import { TemplateModuleCategoriesBar } from '../categories/bar/TemplateModuleCategoriesBar';
import { getAccount } from '../../../../redux/reducers/personalAccountReducer';
import { accountSettingsAPI } from '../../../../entities/user/account-settings/api/settingsApi';
import { TemplateModulesModalTabsEnum } from '../../model/types';

interface TemplateModulesModalProps {
	className?: string,
}

export const TemplateModulesModal = ({ className }: TemplateModulesModalProps) => {
	const dispatch = useAppDispatch();
	const [update] = accountSettingsAPI.useUpdateMutation();

	// Настройка дефолтного таба с сервера
	const account = useAppSelector(getAccount);
	const defaultTab = account.modalTab;

	const [currentTab, setCurrentTab] = useState(
		defaultTab,
	);
	const isActive = useSelector(templateModuleSelectors.getIsTemplateModuleActive);
	const isBindingModuleActive = useAppSelector(
		state => state.moduleWindow.modalsActive.isBindingTagModuleModalActive,
	);

	const isEditModalActive = useSelector(templateModuleSelectors.getIsEditTemplateModuleActive);

	const onOpenEditModuleWindow = () => {
		dispatch(templateModuleActions.setIsEditTemplateModuleActive(true));
	};

	const handleTemplateModal = (value: boolean) => {
		dispatch(templateModuleActions.setIsTemplateModuleActive(value));
		if (!value) {
			dispatch(templateModuleActions.setSelectedCategoryIds([]));
		}
	};

	// Функция для изменения состояния чекбокса
	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Определить выбран ли чекбокс
		const isChecked = event.target.checked;

		try {
			if ((currentTab === TemplateModulesModalTabsEnum.MODULES && isChecked)
				|| (currentTab === TemplateModulesModalTabsEnum.GALLERY && !isChecked)
			) {
				const updateData = {
					email: account.email,
					modal_module_tab_default: TemplateModulesModalTabsEnum.MODULES,
				};
				update(updateData).unwrap();
			} else if ((currentTab === TemplateModulesModalTabsEnum.GALLERY && isChecked)
				|| (currentTab === TemplateModulesModalTabsEnum.MODULES && !isChecked)) {
				const updateData = {
					email: account.email,
					modal_module_tab_default: TemplateModulesModalTabsEnum.GALLERY,
				};
				update(updateData).unwrap();
			}
		} catch (err) {
			// Логирование ошибки или уведомление пользователя
			console.error('Ошибка обновления аккаунта:', err);
		}
	};

	return (
		<>
			<NewModal
				active={isActive}
				closeFunc={handleTemplateModal}
				modalInModalActive={
					isEditModalActive || isBindingModuleActive
				}
			>
				<div className={classNames(css.wrapper, {}, [className])}>
					<div className={css.left_side}>

						<div className={css.sticky_tabs}>
							<TemplateModulesModalTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
							<label htmlFor="checkbox">
								<input
									type="checkbox"
									id="checkbox"
									checked={currentTab === defaultTab}
									onChange={handleCheckboxChange}
								/>
								Открывать по умолчанию
							</label>
						</div>

						<div className={css.modules_list}>
							{currentTab === TemplateModulesModalTabsEnum.MODULES ? <TemplateEntityModules />
								: <TemplateGalleryModules />}
						</div>
					</div>

					{/* Блок с категориями */}
					<div className={css.right_side}>
						<div className={css.categories}>
							<TemplateModuleCategoriesBar />
						</div>
						{
							currentTab === TemplateModulesModalTabsEnum.MODULES && (
								<SettingsButton onClick={onOpenEditModuleWindow} />
							)
						}
					</div>
				</div>
			</NewModal>
			<ModuleTagBindModal />
		</>
	);
};
