import React, { useEffect } from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import css from '../../modal.module.scss';
import CloseModalBtn from '../../closeModalBtn/CloseModalBtn';
import { useModal } from '../util/useModal';
import { useAppSelector } from '../../../../../shared/libs/redux';
import { RootState } from '../../../../../redux/reducers/reducer';
import { getDisableKeyObserverFn, getEnableKeyObserverFn } from '../../../../../redux/reducers/modals';

interface INewModalProps {
	active: boolean,
	closeFunc: (active: boolean) => void,
	styles?: string,
	children: React.ReactNode,
	modalInModalActive?: boolean
}

export const portal = document.getElementById('portal');

/** Компонент - модальное окно */
export const NewModal = ({
	active,
	closeFunc,
	styles,
	children,
	modalInModalActive,
}: INewModalProps) => {
	const {
		modalRef,
		modalContentRef,
	} = useModal(closeFunc, active, modalInModalActive);

	const disableFunction = useAppSelector((state: RootState) => getDisableKeyObserverFn(state));
	const enableFunction = useAppSelector(state => getEnableKeyObserverFn(state));

	const onClose = () => closeFunc(false);

	useEffect(() => {
		if (active) {
			if (disableFunction) {
				disableFunction();
			}
		} else if (!active) {
			if (enableFunction) {
				enableFunction();
			}
		}
	}, [active]);

	return (
		<>
			{
				ReactDOM.createPortal(
					<CSSTransition
						in={active}
						timeout={500}
						nodeRef={modalRef}
						classNames={{
							enter: css.modal_enter,
							enterActive: css.modal_enter_active,
							exit: css.modal_exit,
							exitActive: css.modal_exit_active,
						}}
						unmountOnExit
					>
						<div
							className={css.modal}
							ref={modalRef}
							role="presentation"
						>
							<div
								className={cx(css.content_wrapper, styles)}
								ref={modalContentRef}
							>
								<CloseModalBtn onClose={onClose} />
								{children}
							</div>
						</div>
					</CSSTransition>,
					portal as Element,
				)
			}
		</>
	);
};
