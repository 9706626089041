import React from 'react';
import { ModalTitle } from '../../../shared/ui/modal-title/modal-title';
import { MainButton } from '../main-button/main-button';
import css from './create-modal-wrapper.module.scss';
import { NewModal } from '../modals/new-modal';

interface CreateModalProps {
	children: React.ReactNode,
	isActive: boolean,
	disabled: boolean,
	title: string,
	btnText: string,
	onCreate: () => void,
	setIsActive: (value: boolean) => void,
}

export const CreateModalWrapper = ({
	onCreate,
	title,
	children,
	btnText,
	disabled,
	setIsActive,
	isActive,
}: CreateModalProps) => {
	const createTeam = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		onCreate();
	};

	return (
		<NewModal active={isActive} closeFunc={setIsActive}>
			<ModalTitle>{title}</ModalTitle>
			<form onSubmit={createTeam}>
				{children}
				<MainButton
					type="submit"
					styles={css.btn}
					btnStyle="bright"
					disabled={disabled}
				>
					{btnText}
				</MainButton>
			</form>
		</NewModal>
	);
};
