import HoveringFrame from '../hovering/HoveringFrame';
import IMutationPermission from '../../mechanics/IMutationPermission';
import IFocusableFrame from './IFocusableFrame';

/**
 * Фрейм, поддерживающий фокусирование.
 */
class FocusableFrame extends HoveringFrame implements IFocusableFrame {
	private readonly FOCUS_CLASS_NAME = 'focus';

	private readonly postEnableFocusListeners: VoidFunction[];
	private readonly postDisableFocusListeners: VoidFunction[];
	private readonly postFocusChangeEvents: VoidFunction[];

	private mutationPermissions: IMutationPermission;
	private isFocus: boolean;

	constructor() {
		super();
		this.isFocus = false;
		this.mutationPermissions = {
			TOP: true,
			LEFT: true,
			RIGHT: true,
			BOTTOM: true,
			LEFT_TOP: true,
			RIGHT_TOP: true,
			LEFT_BOTTOM: true,
			RIGHT_BOTTOM: true,
		};
		this.postEnableFocusListeners = [];
		this.postDisableFocusListeners = [];
		this.postFocusChangeEvents = [];
	}

	public enableFocus = () => {
		if (this.isFocus) {
			return;
		}
		this.isFocus = true;
		this.applyVisualChange();
		this.callPostFocusChangeEvents();
		this.runPostEnableFocusModeListeners();
	};

	public disableFocus = () => {
		if (!this.isFocus) {
			return;
		}
		this.isFocus = false;
		this.applyVisualChange();
		this.callPostFocusChangeEvents();
		this.runPostDisableFocusModeListeners();
	};

	public addPostEnableFocusListener = (listener: VoidFunction) => {
		this.postEnableFocusListeners.push(listener);
	};

	public addPostDisableFocusListener = (listener: VoidFunction) => {
		this.postDisableFocusListeners.push(listener);
	};

	private runPostDisableFocusModeListeners = () => {
		this.postDisableFocusListeners.forEach(listener => listener());
	};

	private runPostEnableFocusModeListeners = () => {
		this.postEnableFocusListeners.forEach(listener => listener());
	};

	public setMutationPermissions = (permission: IMutationPermission) => {
		this.mutationPermissions = { ...permission };
	};

	public addPostChangeFocusEvent = (event: VoidFunction) => {
		this.postFocusChangeEvents.push(event);
	};

	public isEnableFocus = (): boolean => this.isFocus;

	public getMutationPermission = (): IMutationPermission => ({ ...this.mutationPermissions });

	// Функции визуального преображения компонента в режиме фокуса, могут быть переопределены
	protected applyFocusOnVisual = () => {
		this.enableColorBorder();
	};

	protected applyFocusOffVisual = () => {
		this.disableColorBorder();
	};

	private applyVisualChange = () => {
		if (this.isFocus) {
			this.applyFocusOnVisual();
			return;
		}
		this.applyFocusOffVisual();
	};

	private callPostFocusChangeEvents = () => {
		this.postFocusChangeEvents.forEach(ev => ev());
	};

	private enableColorBorder = () => {
		this.frameElement.classList.add(this.FOCUS_CLASS_NAME);
	};

	private disableColorBorder = () => {
		this.frameElement.classList.remove(this.FOCUS_CLASS_NAME);
	};
}

export default FocusableFrame;
