import React from 'react';
import { useSelector } from 'react-redux';
import { useDeleteWorkshopModule } from '../../model/services/delete-module/useDeleteWorkshopModule';
import ConfirmModal from '../../../../components/modals/common/confirmModal/ConfirmModal';
import { workshopModuleActions, workshopModuleSlice } from '../../../../entities/workshop-module';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { NewModal } from '../../../../components/ui/modals/new-modal';

export const DeleteWorkshopModuleModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopModuleSlice.selectors.getIsDeletingModuleModalActive);

	const handleModal = (value: boolean) => {
		dispatch(workshopModuleActions.setIsDeletingModuleModalActive(value));
	};

	const closeModal = () => handleModal(false);

	const { onDeleteModule } = useDeleteWorkshopModule();

	return (
		<NewModal active={isActive} closeFunc={handleModal}>
			<ConfirmModal
				onDelete={onDeleteModule}
				title="Вы уверены?"
				closeBtnText="Отменить"
				actionBtnText="Удалить"
				isModalActive={isActive}
				text="Удаление модуля - необратимое действие"
				onCloseModal={closeModal}
			/>
		</NewModal>
	);
};
