import { templatePrivateAPI } from '../../../entities/templates/private/api/api';
import store from '../../../redux/store/store';
import { notificationError } from '../../Notifications/callNotifcation';
import savePDF from '../SketchPDFGenerator/savePDF';

export const savePrivateTemplatePDF = (templateID: string) => {
	store.dispatch(templatePrivateAPI.endpoints?.get.initiate({ id: templateID }, { forceRefetch: true }))
		.unwrap()
		.then(res => {
			const { template, rootComponent } = res;
			savePDF(rootComponent, `${template.name}.pdf`);
		})
		.catch(() => {
			notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры.');
		});
};
