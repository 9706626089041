import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { IAccount } from '../../account/api/types';
import { setSettings } from './settingsSlice';
import { URI_ACCOUNT } from '../../account/api/consts';
import { IDefaultSettingsRequest, IUpdateSettingsRequest } from './types';
import { DEFAULT_SETTINGS, URI_SETTINGS_UPDATE } from './consts';

export const accountSettingsAPI = createApi({
	reducerPath: 'accountAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['settings'],
	endpoints: builder => ({
		get: builder.query<IAccount, void>({
			query: () => ({
				url: URI_ACCOUNT,
				method: 'GET',
			}),
			providesTags: ['settings'],
			async onQueryStarted(arg, {
				dispatch,
				queryFulfilled,
			}) {
				try {
					const { data } = await queryFulfilled;
					// Исторически сложилось - настройки приходят вместе со всем аккаунтом
					dispatch(setSettings({ modalTab: data.modalTab }));
				} catch (error) {
					// 	nothing
				}
			},
		}),
		update: builder.mutation<void, IUpdateSettingsRequest>({
			query: (data) => ({
				url: 'settings/modalTab',
				method: 'PUT',
				body: { modalTab: data.modal_module_tab_default },
			}),
			invalidatesTags: ['settings'],
		}),
		// Настройки по умолчанию
		defaultSettings: builder.mutation<void, IDefaultSettingsRequest>({
			query: (email) => ({
				url: `auth/${email}/settings`,
				method: 'PUT',
				body: { ...DEFAULT_SETTINGS },
			}),
			invalidatesTags: ['settings'],
		}),
	}),
});
