import React from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../shared/libs/classNames';
import { EditWorkshopCategories } from '../../../features/category/edit';
import { NewModal } from '../../../components/ui/modals/new-modal';
import css from './EditingWorkshopModules.module.scss';
import { useAppDispatch } from '../../../shared/libs/redux';
import { moduleWindow, moduleWindowActions } from '../../../entities/modules/module/model/slice';
import { workshopTagSlice } from '../../../entities/workshop-tag';
import { EditWorkshopTagGroups } from '../../../features/manage-workshop-tag-group/ui';
import { workshopTagGroupSlice } from '../../../entities/workshop-tag-group';
import { categoryReducer } from '../../../redux/reducers/module/categoryReducer';
import { EditWorkshopTags } from '../../../features/manage-workshop-module-tags';
import { workshopModuleActions, workshopModuleSlice } from '../../../entities/workshop-module';

interface EditingWorkshopModulesProps {
	className?: string,
}

/** Модалка редактирования модулей (модалка где добавляются/удаляются/редактируются категории, теги и группы тегов) */
export const EditingWorkshopModules = ({ className }: EditingWorkshopModulesProps) => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopModuleSlice.selectors.getIsEditModuleModalActive);

	const isEditCategoriesModalActive = useSelector(categoryReducer.selectors.getIsEditCategoryModalActive);
	const isCreateCategoriesModalActive = useSelector(categoryReducer.selectors.getIsCreatingCategoryModalActive);
	const isDeleteCategoriesModalActive = useSelector(categoryReducer.selectors.getIsDeletingCategoryModalActive);

	const isCreateTagModalActive = useSelector(workshopTagSlice.selectors.getIsCreateModalActive);
	const isDeleteTagModalActive = useSelector(workshopTagSlice.selectors.getIsDeleteModalActive);
	const isEditingTagModalActive = useSelector(workshopTagSlice.selectors.getIsEditingModalActive);

	const isCreateGroupModalActive = useSelector(workshopTagGroupSlice.selectors.getIsCreateGroupModalActive);
	const isDeleteGroupModalActive = useSelector(workshopTagGroupSlice.selectors.getIsDeleteGroupModalActive);
	const isEditingGroupModalActive = useSelector(workshopTagGroupSlice.selectors.getIsEditingGroupModalActive);
	const isUnbindingGroupModalActive = useSelector(workshopTagGroupSlice.selectors.getIsUnbindingGroupModalActive);
	const isBindingGroupModalActive = useSelector(workshopTagGroupSlice.selectors.getIsBindingGroupModalActive);

	const setActive = (value: boolean) => {
		dispatch(workshopModuleActions.setIsEditModuleModalActive(value));
	};

	return (
		<NewModal
			modalInModalActive={
				isCreateTagModalActive
				|| isDeleteTagModalActive
				|| isEditingTagModalActive
				|| isCreateGroupModalActive
				|| isDeleteGroupModalActive
				|| isEditingGroupModalActive
				|| isUnbindingGroupModalActive
				|| isEditCategoriesModalActive
				|| isCreateCategoriesModalActive
				|| isDeleteCategoriesModalActive
				|| isBindingGroupModalActive
			}
			styles={css.modal}
			active={isActive}
			closeFunc={setActive}
		>
			<div className={classNames(css.wrapper, {}, [className])}>
				<div className={css.header}>
					<div className={css.title_block}>
						<div className={css.title}>Редактирование модулей</div>
					</div>
				</div>
				<EditWorkshopCategories />
				<EditWorkshopTags />
				<EditWorkshopTagGroups />
				{/* <TagBlock /> */}
				{/* <TagGroupBlock /> */}
			</div>
		</NewModal>
	);
};
