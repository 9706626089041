import imageCompression from 'browser-image-compression';

const MAX_SIZE_COMPRESS_IMAGE_MB = 4;
const MAX_WIDTH_OR_HEIGHT_COMPRESS_IMAGE: number = Number.MAX_VALUE;

export const compressImage = async (dataUrl: string): Promise<string> => {
	const options = {
		maxSizeMB: MAX_SIZE_COMPRESS_IMAGE_MB,
		maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT_COMPRESS_IMAGE,
		useWebWorker: true,
	};

	try {
		const base64String = dataUrl.split(',')[1];

		// Декодирование Base64 вручную
		const uint8Array = decodeBase64(base64String);

		// Создаем Blob из массива байтов
		const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
		const blob = new Blob([uint8Array], { type: mimeString });

		// Создаем File из Blob
		const file = new File([blob], 'image.png', { type: blob.type, lastModified: Date.now() });

		// Сжимаем изображение
		const compressedFile = await imageCompression(file, options);

		// Возвращаем изображение в виде Data URL
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.onerror = reject;
			reader.readAsDataURL(compressedFile);
		});
	} catch (error) {
		console.error('Ошибка при сжатии изображения:', error);
		throw error;
	}
};

// Функция для декодирования Base64 строки
function decodeBase64(base64: string): Uint8Array {
	const binaryString = window.atob(base64);
	const uint8Array = new Uint8Array(binaryString.length);

	for (let i = 0; i < binaryString.length; i++) {
		uint8Array[i] = binaryString.charCodeAt(i);
	}

	return uint8Array;
}
