import HTMLGenerator from '../../../../utils/HTMLGenerator';
import SvgCollection from '../../../../utils/SvgCollection';
import ColorInput from '../../../../mechanics/ColorInput';
import { FontFamily, FontSize, TextAlign } from '../../../../mechanics/mext/editor/types';
import TextConfigurationPanel from '../../../TextConfigurationPanel';
import Utils from '../../../../utils/impl/Utils';
import FontFamilySelector from './FontFamilySelector';
import FontSizeSelector from './FontSizeSelector';
import LineHeightSelector from './line-height/LineHeightSelector';
import LineHeight from './line-height/LineHeight';

class TextPanelView extends TextConfigurationPanel {
	private readonly CSS_ELEMENT_CLASS_NAME = 'propertyBar__text-panel text-panel';
	private readonly CSS_ACTIVE_CLASS_NAME = 'active';

	private readonly titleElement: HTMLElement;
	protected readonly fontFamilySelector: FontFamilySelector;
	protected readonly fontSizeSelector: FontSizeSelector;
	private readonly fontColorInput: ColorInput;
	private readonly textAlignLeftElement: HTMLElement;
	private readonly textAlignCenterElement: HTMLElement;
	private readonly textAlignRightElement: HTMLElement;
	private readonly textAlignJustifyElement: HTMLElement;
	private readonly textLineHeight: HTMLElement;
	private readonly textFont: HTMLElement;
	private readonly textSize: HTMLElement;
	private readonly fontBoldElement: HTMLElement;
	private readonly fontItalicElement: HTMLElement;
	private readonly ulElement: HTMLElement;
	private readonly olElement: HTMLElement;
	private readonly linkElement: HTMLElement;
	protected readonly lineHeightSelector: LineHeightSelector;

	private textAlignChangeHandler: (align: TextAlign) => void;

	constructor() {
		super();
		this.setRootElementClassName(this.CSS_ELEMENT_CLASS_NAME);

		this.titleElement = HTMLGenerator.getHTMlElement({
			className: 'text-panel__title',
			tag: 'h4',
			text: 'Текст',
		});

		/** Шрифт, размер, цвет */
		const firstRow = HTMLGenerator.getHTMlElement({
			tag: 'ul',
			className: 'text-panel__list',
		});

		// 1. Шрифт
		this.textFont = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__font',
		});
		this.fontFamilySelector = new FontFamilySelector();
		this.textFont.addEventListener('click', () => {
			this.fontFamilySelector.openWindowAtPosition();
		});
		this.textFont.append(this.fontFamilySelector.getElement());
		this.textFont.append(this.fontFamilySelector.getCurrentValueElement());
		firstRow.append(this.textFont);

		// 2. Size
		this.textSize = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__size',
		});
		this.fontSizeSelector = new FontSizeSelector();
		this.textSize.addEventListener('click', () => {
			this.fontSizeSelector.openWindowAtPosition();
		});
		this.textSize.append(this.fontSizeSelector.getElement());
		this.textSize.append(this.fontSizeSelector.getCurrentValueElement());
		firstRow.append(this.textSize);

		// 3. Цвет
		const color = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__color',
		});
		// TODO
		this.fontColorInput = new ColorInput(false);
		const fontColorElement = this.fontColorInput.getElement();
		color.append(fontColorElement);
		firstRow.append(color);

		/** Выравнивания, вес, стиль */
		const secondRow = HTMLGenerator.getHTMlElement({
			tag: 'ul',
			className: 'text-panel__list',
		});

		/* 1. text-align-left */
		this.textAlignLeftElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__text-align-left',
			fnClick: this.onTextAlignClick.bind(this, TextAlign.LEFT),
		});
		this.textAlignLeftElement.insertAdjacentHTML('beforeend', SvgCollection.TEXT_ALIGN_LEFT);
		secondRow.append(this.textAlignLeftElement);

		/* 2. textAlignCenter */
		this.textAlignCenterElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__text-align-center',
			fnClick: this.onTextAlignClick.bind(this, TextAlign.CENTER),
		});
		this.textAlignCenterElement.insertAdjacentHTML('beforeend', SvgCollection.TEXT_ALIGN_CENTER);
		secondRow.append(this.textAlignCenterElement);

		/* 3. textAlignRight */
		this.textAlignRightElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			fnClick: this.onTextAlignClick.bind(this, TextAlign.RIGHT),
			className: 'text-panel__item text-panel__text-align-right',
		});
		Utils.DOM.injectSVG(this.textAlignRightElement, SvgCollection.TEXT_ALIGN_RIGHT);
		secondRow.append(this.textAlignRightElement);

		/* 4. textAlignJustify */
		this.textAlignJustifyElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			fnClick: this.onTextAlignClick.bind(this, TextAlign.JUSTIFY),
			className: 'text-panel__item text-panel__text-align-all-width',
		});
		this.textAlignJustifyElement.insertAdjacentHTML('beforeend', SvgCollection.TEXT_ALIGN_JUSTIFY);
		secondRow.append(this.textAlignJustifyElement);

		/* 5. lineHeight */
		this.lineHeightSelector = new LineHeightSelector();
		this.textLineHeight = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__text-line-height',
			fnClick: this.lineHeightSelector.toggle,
		});
		this.textLineHeight.insertAdjacentHTML('beforeend', SvgCollection.TEXT_ALIGN_HEIGHT);
		this.textLineHeight.addEventListener('click', () => {
			this.lineHeightSelector.openWindowAtPosition();
		});
		this.textLineHeight.append(this.lineHeightSelector.getElement());
		secondRow.append(this.textLineHeight);

		/* 6. fontWeight */
		this.fontBoldElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__font-weight',
		});
		this.fontBoldElement.insertAdjacentHTML('beforeend', SvgCollection.FONT_BOLD);
		secondRow.append(this.fontBoldElement);

		/* 7. fontStyle */
		this.fontItalicElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__font-style',
		});
		this.fontItalicElement.insertAdjacentHTML('beforeend', SvgCollection.FONT_ITALIC);
		secondRow.append(this.fontItalicElement);

		/** Списки и ссылка */
		const thirdRow = HTMLGenerator.getHTMlElement({
			tag: 'ul',
			className: 'text-panel__list',
		});

		/* 1. ol */
		this.olElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__ol',
		});
		this.olElement.insertAdjacentHTML('beforeend', SvgCollection.TEXT_PANEL_OL);
		thirdRow.append(this.olElement);

		/* 2. ul */
		this.ulElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__ul',
		});
		this.ulElement.insertAdjacentHTML('beforeend', SvgCollection.TEXT_PANEL_UL);
		thirdRow.append(this.ulElement);

		/* 3. link */
		this.linkElement = HTMLGenerator.getHTMlElement({
			tag: 'li',
			className: 'text-panel__item text-panel__link',
		});
		this.linkElement.insertAdjacentHTML('beforeend', SvgCollection.TEXT_PANEL_LINK);
		// this.link.insertAdjacentHTML('beforeend', '<h5>Текст-ссылка</h5>');
		thirdRow.append(this.linkElement);

		this.rootElement.append(
			this.titleElement,
			firstRow,
			secondRow,
			// thirdRow,
		);
		// this.injectPanels(this.lineHeightPanel);
	}

	public removeTitle = () => {
		this.titleElement.remove();
	};

	public reloadSVG = () => {
		this.olElement.replaceChildren();
		this.olElement.insertAdjacentHTML('beforeend', SvgCollection.OL_TABLE);
	};

	public resetValuesAll = () => {
		this.disableBold();
		this.disableItalic();
		this.disableLeftAlign();
		this.disableCenterAlign();
		this.disableRightAlign();
		this.disableJustifyAlign();
		this.fontFamilySelector.hideTextOption();
		this.fontSizeSelector.hideTextOption();
		this.lineHeightSelector.disableOptions();
	};

	public getSelectedColor = (): string => this.fontColorInput.getColor();

	/** Добавляет обработчик события выбора цвета шрифта пользователем. */
	protected addInputColorEvent = (ev: (color: string) => void) => {
		this.fontColorInput.addSelectColorListener(ev);
	};

	/** Добавляет обработчик события выбора bold начертания пользователем. */
	protected addBoldClickEvent = (ev: () => void) => {
		this.fontBoldElement.addEventListener('click', ev);
	};

	/** Добавляет обработчик события выбора italic начертания пользователем. */
	protected addItalicClickEvent = (ev: () => void) => {
		this.fontItalicElement.addEventListener('click', ev);
	};

	/** Добавляет обработчик события выбора выравнивания текста пользователем. */
	protected addTextAlignChangeEvent = (ev: (align: TextAlign) => void) => {
		this.textAlignChangeHandler = ev;
	};

	/** Визуально активирует кнопку переключения начертания `bold` текста. */
	protected enableBold = () => {
		this.fontBoldElement.classList.add(this.CSS_ACTIVE_CLASS_NAME);
	};

	/** Визуально отключает кнопку переключения начертания `bold` текста. */
	protected disableBold = () => {
		this.fontBoldElement.classList.remove(this.CSS_ACTIVE_CLASS_NAME);
	};

	/** Визуально активирует кнопку переключения начертания `italic` текста. */
	protected enableItalic = () => {
		this.fontItalicElement.classList.add(this.CSS_ACTIVE_CLASS_NAME);
	};

	/** Визуально отключает кнопку переключения начертания `italic` текста. */
	protected disableItalic = () => {
		this.fontItalicElement.classList.remove(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected enableLeftAlign = () => {
		this.textAlignLeftElement.classList.add(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected disableLeftAlign = () => {
		this.textAlignLeftElement.classList.remove(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected enableCenterAlign = () => {
		this.textAlignCenterElement.classList.add(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected disableCenterAlign = () => {
		this.textAlignCenterElement.classList.remove(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected enableRightAlign = () => {
		this.textAlignRightElement.classList.add(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected disableRightAlign = () => {
		this.textAlignRightElement.classList.remove(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected enableJustifyAlign = () => {
		this.textAlignJustifyElement.classList.add(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected disableJustifyAlign = () => {
		this.textAlignJustifyElement.classList.remove(this.CSS_ACTIVE_CLASS_NAME);
	};

	protected disableAlign = () => {
		this.disableLeftAlign();
		this.disableCenterAlign();
		this.disableRightAlign();
		this.disableJustifyAlign();
	};

	protected enableAlign = (align: TextAlign) => {
		this.disableAlign();

		switch (align) {
		case TextAlign.LEFT: {
			this.enableLeftAlign();
			break;
		}
		case TextAlign.CENTER: {
			this.enableCenterAlign();
			break;
		}
		case TextAlign.RIGHT: {
			this.enableRightAlign();
			break;
		}
		case TextAlign.JUSTIFY: {
			this.enableJustifyAlign();
			break;
		}
		default: break;
		}
	};

	protected setColor = (color: string) => {
		this.fontColorInput.setColor(color);
	};

	protected setFontSize = (size: FontSize) => {
		this.fontSizeSelector.setSelectedOption(size);
	};

	protected setFontFamily = (fontFamily: FontFamily) => {
		this.fontFamilySelector.setSelectedOption(fontFamily);
	};

	protected setLineHeight = (lineHeight: LineHeight) => {
		this.lineHeightSelector.setSelectedOption(lineHeight);
	};

	private onTextAlignClick = (align: TextAlign) => {
		if (this.textAlignChangeHandler === undefined) {
			return;
		}
		this.textAlignChangeHandler(align);
	};
}

export default TextPanelView;
