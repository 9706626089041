import React from 'react';
import ConfirmModal from '../../../../../modals/common/confirmModal/ConfirmModal';
import { NewModal } from '../../../../../ui/modals/new-modal';

interface DeletingTagWindowProps {
	text: string,
	onDelete: () => void,
	isDeletingModalActive: boolean,
	handleDeletingModal: (value: boolean) => void,
}

const DeletingTagWindow = ({
	text,
	onDelete,
	handleDeletingModal,
	isDeletingModalActive,
}: DeletingTagWindowProps) => (
	<NewModal
		closeFunc={handleDeletingModal}
		active={isDeletingModalActive}
	>
		<ConfirmModal
			text={text}
			onDelete={onDelete}
			isModalActive={isDeletingModalActive}
			onCloseModal={() => handleDeletingModal(false)}
		/>
	</NewModal>
);

export default DeletingTagWindow;
