import InterfacePanel from '../../../InterfacePanel';
import ColorInput from '../../../../mechanics/ColorInput';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import SvgCollection from '../../../../utils/SvgCollection';
import ButtonsContainer from '../../../buttons/ButtonsContainer';
import SquareButton from '../../../buttons/SquareButton';
import NumberInputField from '../../../buttons/NumberInputField';

class PagePropertiesView extends InterfacePanel {
	private readonly CSS_ACTIVE_CLASS_NAME = 'active';

	private portraitButton: SquareButton;
	private landscapeButton: SquareButton;
	protected colorInput: ColorInput;
	private backgroundImageButton: SquareButton;
	private editPageButton: SquareButton;
	private repeatingImageButton: SquareButton;
	private repeatingImageSizeField: NumberInputField;

	constructor() {
		super();
		this.setRootElementClassName('propertyBar__page-properties page-properties');
		const title = HTMLGenerator.getHTMlElement({
			tag: 'h4',
			className: 'page-properties__title',
			text: 'Параметры страницы',
		});

		const firstRowContainer = new ButtonsContainer('page-properties__orientation');
		firstRowContainer.appendBaseClassNameButton('page-properties__item');

		// 1. Вертикальная ориентация
		firstRowContainer.appendSquareButton(button => {
			button.appendClassName('page-properties__verticalOrientationBtn');
			button.setIcon(SvgCollection.portraitOrientationBtn);
			button.setHint('Установить страницам портретную ориентацию');
			this.portraitButton = button;
		});

		/* 2. Горизонтальная ориентация */
		firstRowContainer.appendSquareButton(button => {
			button.appendClassName('page-properties__horizontalOrientationBtn');
			button.setIcon(SvgCollection.landscapeOrientationBtn);
			button.setHint('Установить страницам альбомную ориентацию');
			this.landscapeButton = button;
		});

		const secondRowContainer = new ButtonsContainer('page-properties__tools');
		secondRowContainer.appendBaseClassNameButton('page-properties__item');

		// 3. Выбор цвета
		secondRowContainer.appendColorInput(input => {
			this.colorInput = input;
			this.colorInput.setHint('Установить цвет страницы');
		});

		// 4. Режим редактирования страницы
		secondRowContainer.appendSquareButton(button => {
			button.appendClassName('page-properties__edit-page');
			button.setIcon(SvgCollection.PENCIL_BUTTON);
			button.setHint('Включить режим редактирования страницы');
			this.editPageButton = button;
		});

		// 5. Загрузка фонового изображения
		secondRowContainer.appendSquareButton(button => {
			button.appendClassName('page-properties__background-image');
			button.setIcon(SvgCollection.BACKGROUND_PICTURE);
			button.setHint('Установить фоновое изображение');
			this.backgroundImageButton = button;
		});

		// 6. Загрузка повторяющегося изображения
		secondRowContainer.appendSquareButton(button => {
			button.appendClassName('page-properties__repeating-image');
			button.setIcon(SvgCollection.PAGE_PANEL_COLLAGE_PLUS);
			button.setHint('Установить изображение для коллажа');
			this.repeatingImageButton = button;
		});

		const thirdRowContainer = new ButtonsContainer('page-properties__repeating-image-size');
		thirdRowContainer.appendBaseClassNameButton('page-properties__item');

		// 7. Установка масштаба коллажа
		thirdRowContainer.appendNumberInput(field => {
			field.setLabel('Масштаб коллажа');
			field.setMinValue(40);
			field.setMaxValue(1000);
			field.setValue(200);
			field.setRootElementClassName('page-properties__repeating-image-size-field');
			field.appendInputClassName('page-properties__repeating-image-size-input');
			this.repeatingImageSizeField = field;
		});

		this.rootElement.append(
			title,
			// firstRowContainer.getElement(),
			secondRowContainer.getElement(),
			thirdRowContainer.getElement(),
		);
	}

	public enablePortrait = () => {
		this.portraitButton.appendClassName(this.CSS_ACTIVE_CLASS_NAME);
		this.disableLandscape();
	};

	public disablePortrait = () => {
		this.portraitButton.removeClassName(this.CSS_ACTIVE_CLASS_NAME);
	};

	public addPortraitClickEvent = (listener: VoidFunction) => {
		this.portraitButton.addClickListener(listener);
	};

	public enableLandscape = () => {
		this.landscapeButton.appendClassName(this.CSS_ACTIVE_CLASS_NAME);
		this.disablePortrait();
	};

	public disableLandscape = () => {
		this.landscapeButton.removeClassName(this.CSS_ACTIVE_CLASS_NAME);
	};

	public enableEditPage = () => {
		this.editPageButton.appendClassName(this.CSS_ACTIVE_CLASS_NAME);
	};

	public disableEditPage = () => {
		this.editPageButton.removeClassName(this.CSS_ACTIVE_CLASS_NAME);
	};

	public addLandscapeClickEvent = (listener: VoidFunction) => {
		this.landscapeButton.addClickListener(listener);
	};

	public addSelectColorEvent = (ev: (color: string) => void) => {
		this.colorInput.addSelectColorListener(ev);
	};

	public addSelectImageEvent = (listener: VoidFunction) => {
		this.backgroundImageButton.addClickListener(listener);
	};

	public setColor = (color: string) => {
		this.colorInput.setColor(color);
	};

	public setRepeatingImageSize = (size: number) => {
		this.repeatingImageSizeField.setValue(size);
		this.repeatingImageSizeField.show();
	};

	public hideRepeatingImageSizeBlock = () => {
		this.repeatingImageSizeField.hide();
	};

	public addEditPageEvent = (listener: VoidFunction) => {
		this.editPageButton.addClickListener(listener);
	};

	public addSelectRepeatingImage = (listener: VoidFunction) => {
		this.repeatingImageButton.addClickListener(listener);
	};

	public addChangeBackgroundImageSizeListener = (listener: (value: number) => void) => {
		this.repeatingImageSizeField.addChangeValueListener(listener);
	};
}

export default PagePropertiesView;
