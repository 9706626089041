import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagMenuModal from './TagMenuModal';
import { RootState } from '../../../../../../redux/reducers/reducer';
import {
	setDeletingTagGroupId,
	setEditingTagGroupId,
	setIsCreatingTagGroupModalActive,
	setIsDeletingTagGroupModalActive,
	setIsEditTagGroupModalActive,
	setIsTagGroupMenuModalActive,
} from '../../../../../../redux/reducers/module/tagGroupReducer';
import { tagsGroupModalsActive } from '../../../../../../redux/selectors/module/tagGroup';
import { useAppSelector } from '../../../../../../shared/libs/redux';
import { NewModal } from '../../../../../ui/modals/new-modal';

interface TagsGroupMenuModalWrapperProps {
	modalInModalActive: boolean,
}

const TagsGroupMenuModalWrapper = ({
	modalInModalActive,
}: TagsGroupMenuModalWrapperProps) => {
	const tagsGroup = useAppSelector(state => state.tagGroup.tagsGroupData);

	const isTagGroupMenuModalActive = useSelector((
		state: RootState,
	) => tagsGroupModalsActive(state).isTagGroupMenuModalActive);

	const dispatch = useDispatch();

	const onDeletingGroup = (id: number) => {
		dispatch(setDeletingTagGroupId(id));
		dispatch(setIsDeletingTagGroupModalActive(true));
	};

	const onEditingGroup = (id: number) => {
		dispatch(setEditingTagGroupId(id));
		dispatch(setIsEditTagGroupModalActive(true));
	};

	const handleTagMenuModal = (value: boolean) => dispatch(setIsTagGroupMenuModalActive(value));

	const onClickBtnTagMenu = () => dispatch(setIsCreatingTagGroupModalActive(true));

	return (
		<NewModal
			closeFunc={handleTagMenuModal}
			active={isTagGroupMenuModalActive}
			modalInModalActive={modalInModalActive}
		>
			<TagMenuModal
				data={tagsGroup}
				onEditing={onEditingGroup}
				onDeleting={onDeletingGroup}
				searchHolder="Поиск группового тега"
				onCreate={onClickBtnTagMenu}
				createBtnText="Создать групповой тег"
			/>
		</NewModal>
	);
};

export default TagsGroupMenuModalWrapper;
