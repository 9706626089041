import InterfacePanel from '../../../InterfacePanel';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import BorderSelect from './BorderSelect';
import IFigureTexture from '../../../../graphic/figure/IFigureTexture';
import BorderStyle from '../../../../graphic/figure/BorderStyle';
import NamedColorSelector from '../NamedColorSelector';
import FigureComponent from '../../../../components/figure/FigureComponent';
import Utils from '../../../../utils/impl/Utils';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';
import SvgCollection from '../../../../utils/SvgCollection';

/**
 * Панель для управления характеристиками фигур.
 */
class FigurePanel extends InterfacePanel {
	private readonly CSS_ELEMENT_CLASS_NAME = 'propertyBar__figure figure-panel';
	private readonly HEADER_TEXT = 'Фигура';

	private readonly fillInput: NamedColorSelector;
	private readonly borderColorInput: NamedColorSelector;
	private readonly borderWidthInput: HTMLInputElement;
	private readonly borderTypeSelect: BorderSelect;

	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		super();

		this.setRootElementClassName(this.CSS_ELEMENT_CLASS_NAME);
		this.setHeader(this.HEADER_TEXT);

		this.fillInput = new NamedColorSelector({
			containerClassName: 'figure-panel__fill',
			textClassName: '',
			onSelectColorListener: this.onBackgroundChange,
			text: '',
			isDisableTransparent: false,
		});

		this.borderColorInput = new NamedColorSelector({
			containerClassName: 'figure-panel__border',
			textClassName: '',
			onSelectColorListener: this.onBorderColorChange,
			text: '',
			isDisableTransparent: false,
		});

		const borderWidthContainer = HTMLGenerator.getDiv({
			className: 'figure-panel__corner-radius-wrapper',
		});

		const borderWidthSvgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		borderWidthSvgIcon.setAttribute('class', 'figure-panel__corner-radius-svg-icon');
		borderWidthSvgIcon.innerHTML = SvgCollection.FIGURE_BORDER_WIDTH;

		this.borderWidthInput = HTMLGenerator.getInput({
			className: 'figure-panel__border-width-input',
			type: 'number',
			placeholder: '1',
			fnOnInput: this.onBorderWidthChange,
		});

		borderWidthContainer.append(borderWidthSvgIcon);
		borderWidthContainer.append(this.borderWidthInput);

		this.borderTypeSelect = new BorderSelect(this);
		this.borderTypeSelect.setValue(this.borderTypeSelect.solid, BorderStyle.SOLID);

		const borderColorElement = this.fillInput.getElement();
		borderColorElement.append(
			this.borderColorInput.getElement(),
			borderWidthContainer,
			this.borderTypeSelect.getElement(),
		);

		this.rootElement.append(borderColorElement);
	}

	private onBackgroundChange = () => {
		const selectedColor = this.fillInput.getColor();
		this.useCases.setFocusFigureBackground(selectedColor);
	};

	private onBorderColorChange = () => {
		const selectedColor = this.borderColorInput.getColor();
		this.useCases.setFocusFigureBorderColor(selectedColor);
	};

	protected onBorderWidthChange = () => {
		let width = +this.borderWidthInput.value;
		if (width < 0) {
			width = 0;
		}
		this.useCases.setFocusFigureBorderWidth(width);
	};

	public onBorderStyleChange = () => {
		const style = this.borderTypeSelect.getValue();
		this.useCases.setFocusFigureBorderStyle(style);
	};

	public sync = (components: FigureComponent[]) => {
		const textures = components
			.map(component => component.getGraphics()
				.map(graphic => graphic.getTexture())
				.flat())
			.flat();
		const sameProperties = Utils.Object.checkFieldsEquality<IFigureTexture>(...textures);

		if (sameProperties.borderColor !== null) {
			this.borderColorInput.setColor(sameProperties.borderColor as string);
		}
		if (sameProperties.borderStyle !== null) {
			this.borderTypeSelect.activeValue = sameProperties.borderStyle as BorderStyle;
		}
		if (sameProperties.borderWidth !== null) {
			this.borderWidthInput.value = sameProperties.borderWidth.toString();
		}
		if (sameProperties.background !== null) {
			this.fillInput.setColor(sameProperties.background as string);
		}

		this.fillInput.syncActiveColors(this.useCases.getColorsBackgroundFigureFocusComponents());
		this.borderColorInput.syncActiveColors(this.useCases.getColorsBorderFigureFocusComponents());
	};

	private setHeader = (text: string) => {
		const headerElement = HTMLGenerator.getH4({
			className: 'figure-panel__title',
			text,
		});
		this.rootElement.append(headerElement);
	};
}

export default FigurePanel;
