import ColorexFrequentlyUsed from './ColorexFrequentlyUsed';
import ColorexColor from './colors/ColorexColor';
import ColorexColorsBlock from './ColorexColorsBlock';
import { ColorexCore } from './ColorexCore';

class ColorexPalette {
	private readonly CSS_ELEMENT_CLASS_NAME = 'colorex';
	private readonly INDENTATION_PALETTE_IN_FIGURES = 8;

	private readonly paletteElement: HTMLElement;
	private readonly frequentlyUsedBlock: ColorexFrequentlyUsed;
	private colorsBlock: ColorexColorsBlock;
	private readonly core: ColorexCore;

	private target: HTMLElement | null;

	private isShowed: boolean;

	constructor(core: ColorexCore, isDisableTransparent: boolean) {
		this.core = core;
		this.target = null;
		this.isShowed = false;
		this.paletteElement = document.createElement('div');
		this.paletteElement.className = this.CSS_ELEMENT_CLASS_NAME;

		this.frequentlyUsedBlock = new ColorexFrequentlyUsed(isDisableTransparent);

		this.colorsBlock = new ColorexColorsBlock(this.frequentlyUsedBlock);
		this.colorsBlock.addBeforeSelectColorEvent(this.sendSelectColorToCore);

		this.frequentlyUsedBlock.addSelectColorEventBlock(this.colorsBlock.setColorsUsed);

		this.setupPalette();

		this.core.connectPalette(this);
	}

	public syncActiveColors = (colors: string[]): void => {
		this.colorsBlock.setColorsUsed(colors);
		this.frequentlyUsedBlock.setColorsUsed(colors);
	};

	/** Указывает элемент, от которого будет происходить вывод цветовой палитры. */
	public setTargetElement = (target: HTMLElement) => {
		this.target = target;
		target.addEventListener('click', this.onTargetClick);
	};

	public show = () => {
		if (this.target === null) {
			throw new Error('colorex: not set target element');
		}
		this.isShowed = true;
		const frequentlyColors = this.core.getFrequentlyColors();
		this.frequentlyUsedBlock.setColors(frequentlyColors);

		const paletteContainer = this.core.getContainer();
		const targetBound = this.target.getBoundingClientRect();
		paletteContainer.append(this.paletteElement);

		const paletteBound = this.paletteElement.getBoundingClientRect();

		this.setPalettePosition(
			targetBound.left - paletteBound.width - this.INDENTATION_PALETTE_IN_FIGURES,
			targetBound.top + targetBound.height,
		);
		this.checkAndFixPalettePosition();
	};

	/**
	 * Проверяет, не выходит ли элемент за границы экрана. Если это происходит, то метод исправляет положение элемента.
	 */
	public checkAndFixPalettePosition = () => {
		if (this.target === null) {
			throw new Error('colorex: not set target element');
		}

		const targetBound = this.target.getBoundingClientRect();
		const paletteBound = this.paletteElement.getBoundingClientRect();
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		let newLeft = targetBound.left - paletteBound.width - this.INDENTATION_PALETTE_IN_FIGURES;
		let newTop = targetBound.top + targetBound.height;

		// Проверяем, выходит ли элемент за правую границу окна
		if (paletteBound.right > windowWidth) {
			newLeft = windowWidth - paletteBound.width;
		}

		// Проверяем, выходит ли элемент за нижнюю границу окна
		if (paletteBound.bottom > windowHeight) {
			const verticalOffset = paletteBound.bottom - windowHeight;
			newTop -= verticalOffset;
		}

		this.setPalettePosition(newLeft, newTop);
	};

	public hidden = () => {
		if (this.target === null) {
			throw new Error('colorex: not set target element');
		}

		this.paletteElement.remove();
		this.isShowed = false;
	};

	public addSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.colorsBlock.addSelectColorEvent(ev);
		this.frequentlyUsedBlock.addSelectColorEvent(ev);
	};

	public isShow = () => this.isShowed;
	public getClassName = () => this.CSS_ELEMENT_CLASS_NAME;
	public getTargetElement = (): HTMLElement | null => this.target;

	private setupPalette = () => {
		const frequentlyUsedElement = this.frequentlyUsedBlock.getElement();
		const colorsBlockElement = this.colorsBlock.getElement();

		this.paletteElement.append(
			frequentlyUsedElement,
			colorsBlockElement,
		);
	};

	private onTargetClick = () => {
		if (this.isShowed) {
			this.hidden();
			return;
		}
		this.show();
	};

	private sendSelectColorToCore = (color: ColorexColor) => {
		this.core.pushFrequentlyColor(color);
		const frequentlyColors = this.core.getFrequentlyColors();
		this.frequentlyUsedBlock.setColors(frequentlyColors);
	};

	private setPalettePosition = (left: number, top: number) => {
		this.paletteElement.style.setProperty('--colorex-left', `${left}px`);
		this.paletteElement.style.setProperty('--colorex-top', `${top}px`);
	};
}

export default ColorexPalette;
