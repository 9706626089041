import store from '../../../redux/store/store';
import { notificationError } from '../../Notifications/callNotifcation';
import savePDF from '../SketchPDFGenerator/savePDF';
import { offerAPI } from '../../../entities/offer/api/api';

export const saveOfferPDF = (offerID: string) => {
	store.dispatch(offerAPI.endpoints?.get.initiate({ id: offerID }, { forceRefetch: true }))
		.unwrap()
		.then(res => {
			const { rootComponent, offer } = res;
			savePDF(rootComponent, `${offer.name}.pdf`);
		})
		.catch(() => {
			notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры.');
		});
};
