import OneInputModal from '../../../../components/modals/common/oneInputModal/OneInputModal';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import { setIsEditCategoryModalActive } from '../../../../redux/reducers/module/categoryReducer';
import { useEditCategory } from '../api/useEditCategory';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';

export const EditCategoryModal = () => {
	const dispatch = useAppDispatch();

	const id = useAppSelector(state => state.category.editingCategoryId);
	const isActive = useAppSelector(state => state.category.modalsActive.isEditCategoryModalActive);

	const handleModal = (value: boolean) => dispatch(setIsEditCategoryModalActive(value));

	const {
		isLoading,
		editCategory,
	} = useEditCategory(id);

	return (
		<NewModal
			closeFunc={handleModal}
			active={isActive}
		>
			<OneInputModal
				maxLength={255}
				btnText="Изменить"
				placeholder="Введите новое название"
				onBtnClick={editCategory}
				title="Изменение категории"
				onCloseModal={() => handleModal(false)}
				isModalActive={isActive}
				isDataLoading={isLoading}
			/>
		</NewModal>
	);
};
