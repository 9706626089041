import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IModalsState {
	/**	Чтобы протащить в React методы отключения/включения горячих клавиш конструктора,
	 * так как возникла проблема срабатывания при работе в модальных окнах (большинство мод. окон
	 * на React) */
	enableKeyObserverFn: (() => void) | null,
	disableKeyObserverFn: (() => void) | null,

	/** Исключительный случай для дополнительной обработки, нужен нам например когда открыты модальные окна AI и при
	 * этом предполагается работа в конструкторе. В этом случае при переходе на textarea либо input (горячие клавиши не
	 * должны мешать вводу текста) мы помечаем это как исключительный случай */
	isNeedDisableHotkeys: boolean,
}

const initialState: IModalsState = {
	enableKeyObserverFn: null,
	disableKeyObserverFn: null,
	isNeedDisableHotkeys: false,
};

export const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	selectors: {
		getEnableKeyObserverFn: (state) => state.enableKeyObserverFn,
		getDisableKeyObserverFn: (state) => state.disableKeyObserverFn,
		getIsNeedDisableHotkeys: (state) => state.isNeedDisableHotkeys,
	},
	reducers: {
		setIsNeedDisableHotkeys: (state, action: PayloadAction<boolean>) => {
			state.isNeedDisableHotkeys = action.payload;
		},
		setEnableKeyObserverFn: (state, action: PayloadAction<() => void>) => {
			state.enableKeyObserverFn = action.payload;
		},
		setDisableKeyObserverFn: (state, action: PayloadAction<() => void>) => {
			state.disableKeyObserverFn = action.payload;
		},
		enableKeyObserverFn: (state) => {
			setTimeout(() => {
				if (state.enableKeyObserverFn === null) {
					console.log('Enable key observer function was not passed');
					return;
				}
				state.enableKeyObserverFn();
			}, 0);
		},
		disableKeyObserverFn: (state) => {
			setTimeout(() => {
				if (state.disableKeyObserverFn === null) {
					console.log('disable Key Observer function was not passed');
					return;
				}
				state.disableKeyObserverFn();
			}, 0);
		},
	},
});

export const {
	setEnableKeyObserverFn,
	enableKeyObserverFn,
	setDisableKeyObserverFn,
	disableKeyObserverFn,
} = modalsSlice.actions;

export const {
	getEnableKeyObserverFn,
	getDisableKeyObserverFn,
	getIsNeedDisableHotkeys,
} = modalsSlice.selectors;

export const { actions: modalsSliceActions } = modalsSlice;
export default modalsSlice.reducer;
