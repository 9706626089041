import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccount } from '../../entities/user/account/api/types';
import { Tariff } from '../../entities/tariff/model/data';

interface IInitialState {
	authorized: boolean | undefined,
	account: IAccount,
	sessionId: string,
	isCookieAccepted: boolean,
}

const initialState: IInitialState = {
	authorized: undefined,
	account: {} as IAccount,
	sessionId: '',
	isCookieAccepted: true,
};

export const personalAccountSlice = createSlice({
	name: 'personalAccount',
	initialState,
	selectors: {
		authorized: state => state.authorized,
		getAccount: state => state.account,
		tariff: state => (state.account.tariff?.name),
		isBasicTariff: state => (state.account.tariff?.name === Tariff.BASIC) || false,
		isTrialTariff: state => (state.account.tariff?.name === Tariff.TRIAL) || false,
	},
	reducers: {
		setAccountAuthorized: (state, action: PayloadAction<boolean>) => {
			state.authorized = action.payload;
		},

		setAccount: (state, action: PayloadAction<IAccount>) => {
			state.account = action.payload;
		},

		setAccountSessionId: (state, action: PayloadAction<string>) => {
			state.sessionId = action.payload;
		},
		setAccountIsCookieAccepted: (state, action: PayloadAction<boolean>) => {
			state.isCookieAccepted = action.payload;
		},
	},
});

export const {
	setAccountAuthorized,
	setAccount,
	setAccountSessionId,
	setAccountIsCookieAccepted,
} = personalAccountSlice.actions;

export const {
	tariff,
	getAccount,
} = personalAccountSlice.selectors;

export default personalAccountSlice.reducer;
