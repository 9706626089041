import React from 'react';
import { useSelector } from 'react-redux';
import { Title } from '../../../../components/ui/title';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import { workshopModuleActions, workshopModuleSlice } from '../../../../entities/workshop-module';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { SearchWorkshopModuleTagBar } from '../search-module-tag-bar/SearchWorkshopModuleTagBar';
import css from './BindWorkshopModuleTagModal.module.scss';
import { CreateWorkshopTagInput } from '../create-tag-input/CreateWorkshopTagInput';
import { WorkshopModuleBindTagList } from '../tag-list/WorkshopModuleBindTagList';
import { NewModal } from '../../../../components/ui/modals/new-modal';

export const BindWorkshopModuleTagModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopModuleSlice.selectors.getIsBindingTagModuleModalActive);
	const id = useSelector(workshopModuleSlice.selectors.getBindingModuleId);

	const setModalVisibility = (value: boolean) => {
		dispatch(workshopModuleActions.setIsBindingTagModuleModalActive(value));
	};

	const onClose = () => {
		setModalVisibility(false);
	};

	return (
		<NewModal styles={css.wrapper} active={isActive} closeFunc={onClose}>
			<Title styles={css.title}>Изменение тегов модуля</Title>
			<SearchWorkshopModuleTagBar />
			<div className={css.tags_bar}>
				<CreateWorkshopTagInput moduleId={id} />
				<WorkshopModuleBindTagList moduleId={id} />
			</div>
			<MainButton btnStyle="bright" onClick={onClose}>Сохранить</MainButton>
		</NewModal>
	);
};
