import IComponent from '../components/IComponent';
import ModuleComponent from '../components/module/ModuleComponent';
import Dependent from '../utils/dependent/Dependent';
import IComponentFactory from './component/IComponentFactory';
import IGraphicFactory from './graphic/IGraphicFactory';
import SketchComponentType from '../components/SketchComponentType';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import ComponentBuilder from './ComponentBuilder';
import IComponentStructure from '../components/IComponentStructure';
import Utils from '../utils/impl/Utils';
import IPagesComponentTree from '../component-tree/IPagesComponentTree';
import IGraphicStructure from '../graphic/IGraphicStructure';
import GraphicType from '../graphic/GraphicType';
import ITableGraphicTexture from '../graphic/table/ITableGraphicTexture';
import { AnyComponentStructure, AnyGraphicStructure } from '../Types';
import TableComponent from '../components/table/TableComponent';
import IModuleComponentTexture from '../components/module/IModuleComponentTexture';

interface IModuleBuilderDependencies {
	componentTree: IPagesComponentTree,
	graphicFactory: IGraphicFactory,
	componentBuilder: ComponentBuilder,
	componentFactory: IComponentFactory,
}

/**
 * Сборщик компонента модуля из разрозненных компонентов.
 */
class ModuleBuilder extends Dependent<IModuleBuilderDependencies> {
	/**
	 * Возвращает собранный модуль, содержащий переданные компоненты.
	 * @param components Компоненты для объединения.
	 */
	public build = (...components: IComponent[]): ModuleComponent => {
		if (components.length === 0) {
			throw new ManipulatorError('components not found');
		}
		const copiedComponents = this.getCopiedComponents(components);
		const moduleStructure = this.getDefaultModuleStructure();

		if (components.length === 1 && components[0].isUniter) {
			const childComponents = copiedComponents[0].getComponents();
			if (childComponents === null) throw new ManipulatorError('components not found', copiedComponents[0]);

			this.fillComponentsModule(childComponents, moduleStructure);

			const moduleComponent = this.buildModuleComponent(moduleStructure);
			moduleComponent.syncSizeFrames();
			return moduleComponent;
		}

		this.fillComponentsModule(copiedComponents, moduleStructure);
		this.removeEmptyModuleGraphics(moduleStructure);

		const moduleComponent = this.buildModuleComponent(moduleStructure);
		moduleComponent.syncSizeFrames();

		return moduleComponent;
	};

	/**
	 * Уменьшает компоненты таблиц за счет удаления дополнительных график. Количество строк и ячейки сохраняются.
	 * @param components Компоненты таблиц.
	 */
	private compressTableComponents = (components: IComponent[]) => {
		components.forEach(component => {
			const graphics = component.getGraphics();

			if (component.type === SketchComponentType.TABLE && graphics.length > 1) {
				const tableComponent = component as TableComponent;
				const structure = tableComponent.getStructure();
				if (structure.graphics === null) {
					return;
				}

				const rowCount = structure.graphics
					.reduce((rowCount, graphic) => (graphic.texture as ITableGraphicTexture).rowCount + rowCount, 0);

				const firstGraphic = tableComponent.getFirstGraphic();
				if (firstGraphic === null) {
					throw new ManipulatorError('first graphic not found');
				}

				const graphics = tableComponent.getGraphics();
				for (let i = 1; i < graphics.length; i++) {
					component.removeGraphic(graphics[i]);
				}

				firstGraphic.setTexture(prev => ({
					...prev,
					startRow: 0,
					rowCount,
				}));
			}
		});
	};

	private getCompresedStucture = (component: IComponent): AnyComponentStructure => {
		const structure = component.getStructure();
		structure.offset = 0;
		if (structure.graphics === null) {
			return structure;
		}
		const graphics = component.getGraphics();
		structure.graphics.forEach((graphicStructure, index) => {
			const graphic = graphics[index];
			if (graphicStructure.frame) {
				graphicStructure.offset = 0;
				graphicStructure.frame.y = graphic.getGlobalPosition().y;
			}
		});

		// Удаляем все графики, кроме первой
		structure.graphics.splice(1);

		// TODO Почему-то с компонентами группы возникают проблема
		if (component.type === SketchComponentType.TABLE && graphics.length > 1) {
			// const rowCount = structure.graphics
			// 	.reduce((rowCount, graphic) => (graphic.texture as ITableGraphicTexture).rowCount + rowCount, 0);
			// Обновляем текстуру первой графики
			structure.graphics[0].texture = {
				startRow: 0,
				rowCount: (component as TableComponent).getRowCount(),
			};

			return structure;
		}

		if (component.isUniter && graphics.length > 1) {
			const components = component.getComponents();
			if (components === null || structure.components === null) {
				throw new ManipulatorError('component uniter has no components');
			}

			// TODO внутрение компоненты группы
			for (let i = 0; i < components.length; i++) {
				const componentStructure = structure.components[i];
				componentStructure.offset = 0;
				const graphicsComponent = components[i].getGraphics();
				const graphicsStructureComponent = componentStructure.graphics;
				if (graphicsStructureComponent === null) {
					throw new ManipulatorError('component has no graphics');
				}

				for (let j = 0; j < graphicsComponent.length; j++) {
					const graphic = graphicsComponent[j];
					const graphicStructure = graphicsStructureComponent[j];
					if (graphicStructure.frame) {
						graphicStructure.offset = 0;
						graphicStructure.frame.y = graphic.getGlobalPosition().y - graphics[0].getGlobalPosition().y;
					}
				}
			}

			return structure;
		}

		return structure;
	};

	private recursiveUpdate = (component: IComponent, structure: AnyComponentStructure, baseY: number) => {
		const components = component.getComponents();
		if (components === null) {
			return;
		}

		if (structure.components === null) {
			throw new ManipulatorError('component uniter has no components');
		}

		for (let i = 0; i < components.length; i++) {
			const sturcutreComponent = structure.components[i];
			sturcutreComponent.offset = 0;
			const graphicsComponent = components[i].getGraphics();
			const graphicsStuctureComponent = sturcutreComponent.graphics;
			if (graphicsStuctureComponent === null) {
				throw new ManipulatorError('component has no graphics');
			}

			for (let j = 0; j < graphicsComponent.length; j++) {
				const graphic = graphicsComponent[j];
				const graphicStructure = graphicsStuctureComponent[j];
				if (graphicStructure.frame) {
					graphicStructure.offset = 0;
					graphicStructure.frame.y = graphic.getGlobalPosition().y - baseY;
				}
			}
			this.recursiveUpdate(components[i], structure, baseY);
		}
	};

	/**
	 * Возвращает коллекцию скопированных компонентов.
	 * @param components Компоненты для копирования.
	 */
	private getCopiedComponents = (components: IComponent[]): IComponent[] => components.map(component => {
		if (components.length === 1 && component.isUniter && component.type !== SketchComponentType.MODULE) {
			/* (заменить группу на модуль и типы и фрейм) */
			const groupStructure = this.getCompresedStucture(component);
			let moduleStructure = this.getDefaultModuleStructure();
			if (groupStructure.graphics === null) {
				throw new ManipulatorError('graphics is null', groupStructure);
			}

			/* Нужно удалить графику группы, перезаписать фреймы */
			moduleStructure = {
				...moduleStructure,
				components: groupStructure.components,
				graphics: groupStructure.graphics.map((g) => {
					const moduleGraphic = this.getDefaultModuleGraphicStructure();
					moduleGraphic.frame = g.frame;
					return moduleGraphic;
				}),
			};
			this.dependencies.componentBuilder.scanStructure(moduleStructure);
			this.dependencies.componentBuilder.regenerate();
			return this.dependencies.componentBuilder.getComponent();
		}
		const componentStructure = this.getCompresedStucture(component);

		this.dependencies.componentBuilder.scanStructure(componentStructure);
		this.dependencies.componentBuilder.regenerate();
		return this.dependencies.componentBuilder.getComponent();
	});

	/**
	 * Возвращает стандартную структуру модуля.
	 */
	private getDefaultModuleStructure = (): IComponentStructure<IModuleComponentTexture> => ({
		graphics: [this.getDefaultModuleGraphicStructure()],
		texture: {
			baseModule: null,
			layerSequence: [],
		},
		components: [],
		id: Utils.Generate.UUID4(),
		offset: 0,
		type: SketchComponentType.MODULE,
	});

	/**
	 * Заполняет структуру модуля нужным количеством её графики.
	 * @param components Дочерние компоненты, на основании которых будет высчитано количество графики модуля.
	 * @param moduleStructure Структура модуля.
	 */
	private fillGraphicsModuleStructure = (components: IComponent[], moduleStructure: AnyComponentStructure) => {
		const componentGraphics = components.map(component => component.getGraphics()).flat();

		const graphicPageNumbers = new Set<number>();
		componentGraphics.forEach(graphic => {
			const pageNumber = this.dependencies.componentTree.getGraphicPageNumber(graphic);
			graphicPageNumbers.add(pageNumber);
		});

		const groupGraphicCount = graphicPageNumbers.size;

		const moduleGraphicStructures: IGraphicStructure<null>[] = [];
		for (let i = 0; i < groupGraphicCount; i++) {
			const graphicStructure = this.getDefaultModuleGraphicStructure();
			graphicStructure.offset = i;
			moduleGraphicStructures.push(graphicStructure);
		}

		moduleGraphicStructures.forEach(structure => {
			if (moduleStructure.graphics === null) {
				throw new ManipulatorError('graphics is null');
			}
			moduleStructure.graphics.push(structure);
		});
	};

	private getDefaultModuleGraphicStructure = (): AnyGraphicStructure => ({
		id: Utils.Generate.UUID4(),
		type: GraphicType.MODULE,
		texture: null,
		offset: 0,
		frame: {
			y: 0,
			x: 0,
			height: 0,
			width: 0,
			rotate: 0,
			layer: 0,
		},
	});

	/**
	 * Актуализирует офсеты новых компонентов относительно их новой позиции внутри модуля.
	 * @param components Компоненты для обновления офсетов.
	 */
	private updateOffsets = (components: IComponent[]) => {
		// const componentOffsets: Map<IComponent, number> = new Map();

		// let minComponentOffset = Number.MAX_SAFE_INTEGER;
		// components.forEach(component => {
		// 	const { offset } = component.getStructure();
		// 	if (offset === null) {
		// 		throw new ManipulatorError('offset is null');
		// 	}
		// 	if (offset < minComponentOffset) {
		// 		minComponentOffset = offset;
		// 	}
		// 	componentOffsets.set(component, offset);
		// });
		components.forEach(component => {
			component.setStructure(prev => ({
				...prev,
				offset: 0,
			}));
		});
	};

	/**
	 * Заполняет переданную структуру дочерними компонентами.
	 * @param components Дочерние компоненты.
	 * @param moduleStructure Структура модуля.
	 */
	private fillComponentsModule = (components: IComponent[], moduleStructure: AnyComponentStructure) => {
		components.forEach(component => {
			if (moduleStructure.components === null) {
				throw new ManipulatorError('components is null');
			}
			if (component.type === SketchComponentType.TABLE) {
				(component as TableComponent).applyMutations();
			}
			moduleStructure.components.push(component.getStructure());
		});
	};

	/**
	 * Собирает компонент модуля из настроенной структуры.
	 * @param structure Структура модуля.
	 */
	private buildModuleComponent = (structure: IComponentStructure<IModuleComponentTexture>): ModuleComponent => {
		this.dependencies.componentBuilder.scanStructure(structure);
		return this.dependencies.componentBuilder.getComponent() as ModuleComponent;
	};

	/**
	 * Удаляет графику модуля, на которой не располагает никакая другая графика.
	 * @param moduleStructure Структура модуля.
	 * @private
	 */
	private removeEmptyModuleGraphics(moduleStructure: AnyComponentStructure) {
		const absoluteGraphicOffsets: AnyGraphicStructure[][] = [];

		if (moduleStructure.graphics === null) {
			throw new ManipulatorError('graphics not found');
		}
		if (moduleStructure.components === null) {
			throw new ManipulatorError('components not found');
		}

		moduleStructure.components.forEach(componentStructure => {
			this.recursiveFillAbsoluteGraphicOffsets(absoluteGraphicOffsets, componentStructure, 0);
		});

		if (absoluteGraphicOffsets.length === moduleStructure.graphics.length) {
			return;
		}

		moduleStructure.graphics.splice(absoluteGraphicOffsets.length);
	}

	private recursiveFillAbsoluteGraphicOffsets = (
		absoluteGraphicPositions: AnyGraphicStructure[][],
		structure: AnyComponentStructure,
		sumOffset: number,
	) => {
		if (structure.graphics === null || structure.offset === null) {
			throw new ManipulatorError('graphics or structure offset not found');
		}

		for (let i = 0; i < structure.graphics.length; ++i) {
			if (absoluteGraphicPositions[sumOffset + structure.offset + i] === undefined) {
				absoluteGraphicPositions[sumOffset + structure.offset + i] = [];
			}
			absoluteGraphicPositions[sumOffset + structure.offset + i].push(structure.graphics[i]);
		}

		if (structure.components === null) {
			return;
		}

		structure.components.forEach(componentStructure => {
			if (structure.offset === null) {
				throw new ManipulatorError('structure offset not found');
			}
			this.recursiveFillAbsoluteGraphicOffsets(
				absoluteGraphicPositions,
				componentStructure,
				sumOffset + structure.offset,
			);
		});
	};
}

export default ModuleBuilder;
