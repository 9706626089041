import HTMLTableImporter from '../html-import-table/HTMLTableImporter';
import TableComponent from '../../components/table/TableComponent';
import Dependent from '../../utils/dependent/Dependent';
import { notificationError } from '../../../Notifications/callNotifcation';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TableCellContext from '../../graphic/table/cells/context/TableCellContext';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import ComponentOrganizer from '../component-organizer/ComponentOrganizer';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';
import { ITokenText, TokenType } from '../mext/parser';

export interface IHTMLTableReplacerDependencies {
	HTMLTableImporter: HTMLTableImporter,
	componentOrganizer: ComponentOrganizer,
	sketchStabilizer: SketchStructureStabilizer,
	componentFocusObserver: ComponentUIObserver,
}

class HTMLTableReplacer extends Dependent<IHTMLTableReplacerDependencies> {
	private readonly prevReplaceListeners: VoidFunction[];
	private readonly postReplaceListeners: VoidFunction[];

	constructor() {
		super();
		this.prevReplaceListeners = [];
		this.postReplaceListeners = [];

		this.addPrevReplaceListener(() => {
			this.dependencies.sketchStabilizer.startUserAction();
		});

		this.addPostReplaceListener(() => {
			setTimeout(this.dependencies.componentOrganizer.sync, 0);
			setTimeout(this.dependencies.componentOrganizer.sync, 100);
			setTimeout(this.dependencies.componentOrganizer.sync, 200);
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 200);
		});
	}

	public replaceTable = (tableComponents: TableComponent[], htmlString: string) => {
		if (tableComponents.length > 1) {
			notificationError('Замена таблицы', 'В фокусе более одной таблицы');
			return;
		}

		// Получаем таблицу и ячейки в фокусе
		const focusTable = tableComponents[0];
		if (focusTable === undefined) {
			throw new ManipulatorError('table is undefined');
		}
		const focusCells = focusTable.getFocusCellContexts();

		// Получаем ячейки по которым будет копировать форматирование
		const patternCells: TableCellContext[] = focusCells === null ? focusTable.getCellContexts() : focusCells;

		// Создаем шаблон стилей и проверяем его
		const patternStyles = this.generatePatternStyles(patternCells);

		if (!this.isPatternStylesRectangle(patternCells, patternStyles)) {
			notificationError('Замена таблицы', 'Ячейки в фокусе, не образуют прямоугольник');
		}

		const tableStructures = this.dependencies.HTMLTableImporter.getStuctures(htmlString);
		
		if (tableStructures.length === 0) {
			notificationError('Замена таблиц.', 'В буфере нет таблиц.');
			return;
		}
		const { rowCount, columnCount, cells } = tableStructures[0];

		if (cells.length < 1) {
			notificationError('Загрузка таблицы.', 'Загружаемая таблица не имеет ячеек с текстом.');
			return;
		}

		const resizedPatternStyles = this.resizePatternStyles(patternStyles, rowCount, columnCount);

		for (let i = 0; i < cells.length; i++) {
			const cell = cells[i];
			const { row, column } = cell;

			cell.content.tokens = this.getStylesFromPattern(cell, resizedPatternStyles[row][column]);
			cell.background = resizedPatternStyles[row][column].background;
		}

		const rowMultipliers = Array(rowCount).fill(1);
		const columnMultipliers = Array(columnCount).fill(1);

		this.callPrevReplaceListeners();

		focusTable.mutateRowMultipliers(rowMultipliers);
		focusTable.mutateColumnMultipliers(columnMultipliers);
		focusTable.mutateCellTextures(cells);
		focusTable.applyMutations();

		this.callPostReplaceListeners();
	};

	private addPostReplaceListener(listener: () => void) {
		this.postReplaceListeners.push(listener);
	}

	private callPostReplaceListeners = () => {
		this.postReplaceListeners.forEach(listener => listener());
	};

	private addPrevReplaceListener(listener: () => void) {
		this.prevReplaceListeners.push(listener);
	}

	private callPrevReplaceListeners = () => {
		this.prevReplaceListeners.forEach(listener => listener());
	};

	private getStylesFromPattern = (
		cell: ITableCellTexture,
		pattern: ITableCellTexture,
	) => {
		const patternTextToken = pattern.content.tokens.find(token => token.type === TokenType.Text) as ITokenText;

		const tokens = [...cell.content.tokens];
		for (let i = 0; i < tokens.length; i++) {
			if (tokens[i].type === TokenType.Text) {
				tokens[i] = { ...patternTextToken, value: tokens[i].value };
			}
		}
		return tokens;
	};

	/**
	 * Возвращает масштабированный шаблон стилей, до размеров импортируемой таблицы.
	 * @param patternStyles Шаблон стилей
	 * @param rowCount Количество строк, в импортируемой таблице
	 * @param columnCount Количество колонок, в импортируемой таблице
	 */
	private resizePatternStyles = (
		patternStyles: ITableCellTexture[][],
		rowCount: number,
		columnCount: number,
	): ITableCellTexture[][] => {
		const resizedArray: ITableCellTexture[][] = [];

		const currentRowCount = patternStyles.length;
		const currentColumnCount = patternStyles[0].length;

		for (let row = 0; row < rowCount; row++) {
			if (resizedArray[row] === undefined) resizedArray[row] = [];
			for (let column = 0; column < columnCount; column++) {
				const originalRow = row % currentRowCount;
				const originalColumn = column % currentColumnCount;

				resizedArray[row][column] = patternStyles[originalRow][originalColumn];
			}
		}

		return resizedArray;
	};

	/**
	 * Возвращает результат проверки полученной фигуры из ячеек на соответствие прямоугольнику.
	 * @param cells Ячейки для вычисления итогового количества строк и колонок
	 * @param patternStyles Шаблон стилей, для проверки
	 */
	private isPatternStylesRectangle = (cells: TableCellContext[], patternStyles: ITableCellTexture[][]): boolean => {
		// Размеры шаблонной таблицы
		const uniqueColumns = new Set<number>();
		const uniqueRows = new Set<number>();

		for (let i = 0; i < cells.length; i++) {
			const { row, column } = cells[i].getTexture();
			uniqueRows.add(row);
			uniqueColumns.add(column);
		}

		const rowCount = uniqueRows.size;
		const columnCount = uniqueColumns.size;

		for (let row = 0; row < rowCount; row++) {
			for (let column = 0; column < columnCount; column++) {
				if (patternStyles[row][column] === undefined) {
					return false;
				}
			}
		}

		return true;
	};

	/**
	 * Возвращает сгенерированный шаблон стилей, на основе ячеек.
	 * @param cells Ячейки для копирования стилей
	 */
	private generatePatternStyles = (cells: TableCellContext[]): ITableCellTexture[][] => {
		// Вычисляем размеры таблицы для построения
		let rowCount = 0;
		let columnCount = 0;

		for (let i = 0; i < cells.length; i++) {
			const { row, column } = cells[i].getTexture();

			if (row > rowCount) rowCount = row;
			if (column > columnCount) columnCount = column;
		}

		rowCount++;
		columnCount++;

		const pattern: ITableCellTexture[][] = [];

		// Инициализация 2-мерного массива нужного размера
		for (let i = 0; i < rowCount; i++) {
			pattern[i] = new Array(columnCount).fill(null);
		}
		// Заполняем 2-мерный массив клетками для последующего копирование стилей
		for (let i = 0; i < cells.length; i++) {
			const cell = cells[i].getTexture();
			const {
				row, rowSpan, column, columnSpan,
			} = cell;

			pattern[row][column] = cell;

			if (rowSpan > 1 || columnSpan > 1) {
				for (let currentRow = row; currentRow < row + rowSpan; currentRow++) {
					for (let currentColumn = column; currentColumn < column + columnSpan; currentColumn++) {
						if (!pattern[currentRow][currentColumn]) {
							pattern[currentRow][currentColumn] = cell;
						}
					}
				}
			}
		}
		// Вырезаем необходимую часть для копирования стилей
		const slicedPattern = pattern.flatMap(cells => {
			const filteredCells = cells.filter(cell => cell !== null);
			return filteredCells.length > 0 ? [filteredCells] : [];
		});

		return slicedPattern;
	};
}

export default HTMLTableReplacer;
