import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TemplateModulesModalTabsEnum } from '../../../../widgets/template-modules-modal/model/types';

interface ISettings {
	modalTab: TemplateModulesModalTabsEnum,
}

const initialState: ISettings = {
	modalTab: TemplateModulesModalTabsEnum.GALLERY,
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	selectors: {
		getSettings: state => state,

	},
	reducers: {
		setSettings: (state, action: PayloadAction<ISettings>) => {
			state.modalTab = action.payload.modalTab;
		},
	},
});

export const {
	setSettings,
} = settingsSlice.actions;

export const {
	getSettings,
} = settingsSlice.selectors;

export default settingsSlice.reducer;
