import React, {
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import css from './template-card.module.scss';
import { TemplatePicture } from './picture/picture';
import { SELECTABLE_ITEM_CLASS } from '../../../../hooks/template-selection/useSelection';
import { ITemplateTags } from '../../../../entities/templates/types';
import { TemplateTags } from './tags/tags';
import ManipulatorError from '../../../../components/Sketch/utils/manipulator-error/ManipulatorError';

interface ITemplateCard {
	id: string,
	name: string,
	preview: string,
	tags?: ITemplateTags,
}

export interface TemplateCardProps {
	styles?: string,
	selected?: boolean,
	description?: string,
	template: ITemplateCard,
	children?: ReactNode,
	isEditableTitle?: boolean,
	onFinishEditTemplateTitle?: (id: string, name: string) => void,
}

export const titlePadding = 50;

export const TemplateCard = ({
	styles,
	template,
	selected,
	description,
	children,
	isEditableTitle,
	onFinishEditTemplateTitle,
}: TemplateCardProps) => {
	const {
		id,
		preview,
		name,
		tags,
	} = template;

	// Логика для переименования шаблона
	const [inputValue, setInputValue] = useState<string>(name);
	const inputRef = useRef<HTMLInputElement | null>(null);
	// Чтобы высчитывать ширину (одним inputRef не обойтись, потому что нужен оригинальный размер каждый раз)
	const sizerRef = useRef<HTMLSpanElement | null>(null);
	// Инициализация ширины окна
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const setFocusOnEditableTitle = () => {
		if (!inputRef.current) return;
		inputRef.current.focus();
		const { length } = inputRef.current.value;
		inputRef.current.setSelectionRange(length, length); // Устанавливаем каретку в конец
	};
	// Проверить ширину названия и при необходимости сместить от правой границы документа
	const handleInputWidth = () => {
		if (inputRef.current === null || sizerRef.current === null) {
			throw new ManipulatorError('Не передана ref ссылка');
		}
		inputRef.current.style.left = '16px';

		const newWidth = sizerRef.current.scrollWidth;
		let rightmostPoint = inputRef.current.getBoundingClientRect().left + newWidth;

		// Логика для ширины
		if (newWidth > 214 && newWidth < (windowWidth - titlePadding * 2)) {
			inputRef.current.style.width = `${newWidth + 10}px`;
		} else if (newWidth > (windowWidth - titlePadding * 2)) {
			/* Если ширина названия больше допустимого - нужно зафиксировать ширину по ширине экрана и установить
			правильно смещение, но тут момент такой: так как элемент уже смещался - не получится посчитать правильно.
			Для этого установим смещение = 0 */
			inputRef.current.style.width = `${windowWidth - titlePadding * 2}px`;
			inputRef.current.style.left = '0px';
			rightmostPoint = inputRef.current.getBoundingClientRect().left + newWidth;

			const leftValue = inputRef.current.getBoundingClientRect().left;
			inputRef.current.style.left = `-${leftValue - titlePadding}px`;
		} else {
			inputRef.current.style.width = '218px';
		}
		const offset = rightmostPoint - windowWidth; // Рассчитываем, насколько выходит за пределы
		// Логика для сдвига
		/* У нас 2 кейса для сдвига: когда ширина названия упирается в правую границу документа, при этом не превышая
		* допустимую ширину и когда Ширина больше допустимой и мы фиксируем ширину названия. */
		if (rightmostPoint > windowWidth - titlePadding
			&& newWidth < windowWidth - titlePadding * 2
		) {
			const computedStyle = window.getComputedStyle(inputRef.current);
			const leftValue = parseFloat(computedStyle.left);
			inputRef.current.style.left = `${leftValue - offset - titlePadding}px`; // Сдвигаем название влево,
		}
	};

	const onFinishEditTitle = onFinishEditTemplateTitle
		|| (() => console.log('Обработчик завершения редактирования имени шаблона не передан!'));

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter'
			|| event.key === 'Escape') {
			onFinishEditTitle(id, inputValue);
			inputRef.current?.blur();
		}
	};

	// Обработчик изменения текста в поле ввода
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let newValue = event.target.value;
		// Ограничиваем длину до 40 символов
		if (newValue.length > 40) {
			newValue = newValue.substring(0, 40);
		}
		setInputValue(newValue);
		
		if (inputRef.current === null) {
			throw new ManipulatorError('Не передана ref ссылка');
		}

		setTimeout(() => {
			handleInputWidth();
		}, 0);
	};

	const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		// updateTemplate({ template: id, name: inputValue });
		onFinishEditTitle(id, inputValue);
		inputRef.current?.blur();
	};

	const inputClass = cx(css.title, {
		[css.titleActive]: isEditableTitle,
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (inputRef.current && isEditableTitle === true) {
			handleInputWidth();
		}
		if	(isEditableTitle) setFocusOnEditableTitle();
	}, [isEditableTitle, windowWidth]);

	// * Класс SELECTABLE_ITEM_CLASS необходим для корректной работы выделения карточек шаблонов
	// * Поменять в хуке useSelection
	return (
		<div id={id} className={cx(SELECTABLE_ITEM_CLASS, css.wrapper, selected && css.selected, styles)}>
			<TemplatePicture preview={preview} />

			<div className={css.info}>

				{/* Скрытый span для вычисления ширины текста */}
				<span ref={sizerRef} className={css.sizer} aria-hidden="true">
					{inputValue || ' '}
				</span>

				<input
					type="text"
					className={inputClass}
					ref={inputRef}
					value={inputValue}
					disabled={!isEditableTitle}
					onKeyDown={handleKeyDown}
					onChange={handleInputChange}
					onBlur={onBlur}
				/>
				{tags && <TemplateTags tags={tags} />}
				<div className={css.description}>{description}</div>
			</div>
			{children}
		</div>
	);
};
