import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../shared/libs/classNames';
import SearchInput from '../../../../components/ui/searchInput/SearchInput';
import { useDebounce } from '../../../../shared/libs/useDebounce';
import { moduleAPI } from '../../../../entities/modules/module/api/api';
import { ModuleCard } from '../../../../entities/modules/module';
import { templateModuleSelectors } from '../../../../entities/template-module';
import { useDeleteModule } from '../../../../features/module/delete/api/useDeleteModule';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { TagGroupsScrollSearch } from '../../../modules/tag-groups-scroll-search';
import { moduleWindowActions } from '../../../../entities/modules/module/model/slice';
import css from './TemplateEntityModules.module.scss';
import { TemplateModulesModalTabsEnum, TypeOfTags } from '../../model/types';

interface TemplateEntityModulesProps {
	className?: string,
}

export const TemplateEntityModules = ({ className }: TemplateEntityModulesProps) => {
	const dispatch = useAppDispatch();

	const [search, setSearch] = useState('');

	const [isGlobalLoading, setIsGlobalLoading] = useState(false);

	const debouncedSearch = useDebounce(search.trim(), 200);

	const onModuleClick = useSelector(templateModuleSelectors.getEntityModuleClick);
	const categories = useAppSelector(templateModuleSelectors.getSelectedCategoryIds);
	const tags = useAppSelector(state => state.moduleWindow.currentTagsIds);

	const { data: modulesData } = moduleAPI.useGetAllQuery({
		limit: 127,
		page: 1,
		categories,
		tags,
		search: debouncedSearch,
	});

	const { onDeleteModule } = useDeleteModule();

	const onBindTag = (id: string) => {
		dispatch(moduleWindowActions.setBindingModuleId(id));
		dispatch(moduleWindowActions.setIsBindingTagModuleModalActive(true));
	};

	const setGlobalLoadingState = (moduleId: string | null) => {
		setIsGlobalLoading(!!moduleId);
	};

	return (
		<div className={classNames('', {}, [className])}>
			<SearchInput
				onChange={setSearch}
				placeholder="Поиск по модулям"
				searchValue={search}
				styles={css.search}
			/>

			{/* Группы тегов */}
			<TagGroupsScrollSearch scrollDistance={200} typeOfTags={TypeOfTags.PRIVATE_TAGS} />

			<div className={css.list}>
				{
					modulesData?.modules?.map(module => (
						<ModuleCard
							key={module.id}
							module={module}
							onModuleClick={onModuleClick}
							onBindTag={onBindTag}
							onDeleteModule={() => onDeleteModule(module.id)}
							currentTab={TemplateModulesModalTabsEnum.MODULES}
							isGlobalLoading={isGlobalLoading}
							setGlobalLoading={setGlobalLoadingState}
						/>
					))
				}
			</div>
		</div>
	);
};
