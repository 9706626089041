import { Bitmap, Jimp } from 'jimp';
import Utils from '../../utils/impl/Utils';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import Dependent from '../../utils/dependent/Dependent';
import IDescartesPosition from '../../utils/IDescartesPosition';
import HTMLGenerator from '../../utils/HTMLGenerator';
import IComponentTree from '../../component-tree/IComponentTree';

export interface IModulePlacerHolderDependencies {
	componentTree: IComponentTree,
	mousePositionObserver: MousePositionObserver,
}

// Отвечает за создание и управления плейсхолдером модуля на экране
class ModulePlaceholder extends Dependent<IModulePlacerHolderDependencies> {
	private readonly MODULE_PLACEHOLDER_CLASS_NAME = 'module-placeholder';
	private readonly MODULE_PLACEHOLDER_DARK_MODE_CLASS_NAME = 'module-placeholder_dark-mode';
	private readonly MODULE_PREVIEW_CLASS_NAME = 'module-placeholder_module-preview';

	private readonly CSS_PROPERTY_TOP = '--top';
	private readonly CSS_PROPERTY_LEFT = '--left';

	private readonly previewElement: HTMLImageElement;
	private readonly placeholderElement: HTMLElement;

	private containerElement: HTMLElement;
	private initializePosition: IDescartesPosition | null;
	private isStarted: boolean;

	// Коэффициент для красного канала
	private readonly RED_WEIGHT = 0.299;
	// Коэффициент для зелёного канала
	private readonly GREEN_WEIGHT = 0.587;
	// Коэффициент для синего канала
	private readonly BLUE_WEIGHT = 0.114;
	// Максимальное значение цвета
	private readonly MAX_COLOR_VALUE = 255;
	// Порог для яркости изображения
	private readonly BRIGHTNESS_THRESHOLD = 0.765;

	constructor(manipulatorElement: HTMLDivElement) {
		super();

		this.containerElement = manipulatorElement;
		this.initializePosition = null;
		this.isStarted = false;

		this.previewElement = HTMLGenerator.getImg();
		this.previewElement.className = this.MODULE_PREVIEW_CLASS_NAME;

		this.placeholderElement = HTMLGenerator.getDiv();
		this.placeholderElement.className = this.MODULE_PLACEHOLDER_CLASS_NAME;

		this.addPostInjectDependenciesListener((dependencies) => {
			dependencies.mousePositionObserver.subscribe(this.updatePreviewPosition);
		});
	}

	public start = (position: IDescartesPosition, preview: string) => {
		this.isStarted = true;
		this.initializePosition = position;
		this.appendPreview(preview);
	};

	public stop = () => {
		this.isStarted = false;
		this.initializePosition = null;
		this.removePreview();
	};

	private appendPreview = (preview: string) => {
		if (this.initializePosition === null) {
			throw new ManipulatorError('initialize position was null');
		}
		this.updatePreviewPosition(this.initializePosition);

		const imageURI = Utils.Backend.getSystemImageURI(preview);
		this.previewElement.src = imageURI;
		this.placeholderElement.append(this.previewElement);
		this.containerElement.append(this.placeholderElement);

		Jimp.read(imageURI)
			.then(preview => {
				const isBright = this.isImageBright(preview.bitmap);
				if (isBright) {
					this.enablePlaceholderDarkMode();
				}
			});
	};

	private removePreview = () => {
		this.previewElement.src = '';
		this.previewElement.remove();
		this.placeholderElement.remove();
		this.disablePlaceholderDarkMode();
	};

	private updatePreviewPosition = (position: IDescartesPosition) => {
		if (!this.isStarted) return;
		const moduleSection = this.getModuleAlignmentSection(position);
		this.previewElement.style.setProperty(this.CSS_PROPERTY_TOP, `${position.y - window.scrollY}px`);
		this.previewElement.style.setProperty(this.CSS_PROPERTY_LEFT, `${moduleSection}px`);
	};

	/**
	 * Рассчитывает позицию модуля в одной из трёх горизонтальных секций страницы
	 * (лево, центр или право) на основе текущей позиции мыши.
	 * @param position Позиция мыши.
	 */
	private getModuleAlignmentSection = (position: IDescartesPosition) => {
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		const { x, width } = treeRootGraphics[0].getFrameConfiguration();

		const previewWidth = this.previewElement.getBoundingClientRect().width;
		const sectionWidth = width / 3;

		// Левый край
		if (position.x <= sectionWidth + x) {
			return x;
		}

		// Центр
		if (position.x > sectionWidth + x && position.x <= (sectionWidth * 2) + x) {
			return (width / 2) + x - (previewWidth / 2);
		}

		// Правый край
		return (x + width - previewWidth);
	};

	private enablePlaceholderDarkMode = () => {
		this.placeholderElement.classList.add(this.MODULE_PLACEHOLDER_DARK_MODE_CLASS_NAME);
	};

	private disablePlaceholderDarkMode = () => {
		this.placeholderElement.classList.remove(this.MODULE_PLACEHOLDER_DARK_MODE_CLASS_NAME);
	};

	/**
	 * Возвращает результат проверки изображения превью, светлое ли оно.
	 * @param image Изображение, которое мы проверяем.
	 */
	private isImageBright = (image: Bitmap): boolean => {
		let totalBrightness = 0;
		let pixelCount = 0;
		const { data } = image;

		// Проходим по массиву данных изображения с шагом 4 (по 4 канала на пиксель)
		data.forEach((_, idx) => {
			if (idx % 4 === 0) {
				const red = data[idx]; // Красный канал
				const green = data[idx + 1]; // Зеленый канал
				const blue = data[idx + 2]; // Синий канал
				const alpha = data[idx + 3]; // Альфа-канал

				// Игнорируем полностью прозрачные пиксели
				if (alpha !== 0) {
					const alphaFactor = alpha / this.MAX_COLOR_VALUE;
					const brightness = (this.RED_WEIGHT * red
						+ this.GREEN_WEIGHT * green + this.BLUE_WEIGHT * blue) / this.MAX_COLOR_VALUE;
					totalBrightness += brightness * alphaFactor;
					pixelCount++;
				}
			}
		});

		if (pixelCount === 0) {
			return false;
		}

		const avgBrightness = totalBrightness / pixelCount;
		return avgBrightness > this.BRIGHTNESS_THRESHOLD;
	};
}

export default ModulePlaceholder;
