import ActionTool from '../action-panel/ActionTool';
import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';

class AITool extends ActionTool {
	private readonly HINT_TEXT = 'Искусственный интеллект';

	constructor() {
		super('panel-ai__icon');
		this.setHint(this.HINT_TEXT);
	}
}

export default AITool;
